import React from "react";
import MyButton from "./mybutton";
import i18n from "../../../i18n/i18n";
import CancelIcon from '@material-ui/icons/Cancel';

const NoButton = ({onClick, style, autoFocus, disabled}) => (
    <MyButton icon={<CancelIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={false} disabled={disabled}>
      {i18n('no')}
    </MyButton>
)

export default NoButton;
