import React from "react";
import WelcomeCSS from "./welcome.module.css";
import Background from "./background";

const WelcomeBlockWithBackground = ({children, backgroundColor}) => {
  return <div className={WelcomeCSS.Main}>
    <Background/>
    <div className={WelcomeCSS.Foreground}>
        <div style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: "column",
              background: backgroundColor
            }}>
              <div style={{width: Math.min(window.innerWidth - 40, 1000), marginTop: 40, marginBottom: 40}}>
                {children}
              </div>
        </div>
    </div>
  </div>
}

export default WelcomeBlockWithBackground;
