import React from "react";
import WelcomeCSS from "./welcome.module.css";
import Grid from "@material-ui/core/Grid";
import MyButton from "../ui-components/buttons/text/mybutton";

const PricingItem = ({icon, title, body, actionText, actionIcon, onAction, actionHidden}) => (
            <Grid item xs={window.innerWidth > 1000 ? 4 : 12} >
                <div style={{background: "#FFF", height: "100%",border: "1px solid gray", borderRadius: 5, display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: "flex", background: "#253b4d", padding: 10, justifyContent: "center", alignItems: "baseline", flex: "0 1 auto"}}>
                        <div style={{color: "#E00"}}>{icon}</div>
                        <div className={WelcomeCSS.topfacttitle}>{title}</div>
                    </div>
                    <div style={{padding: 10, flex: 1}}>
                      <div className={WelcomeCSS.pricingbody}>
                          {body}
                      </div>
                    </div>
                    <div style={{flex: "0 1 auto",display: 'flex', justifyContent: "center", padding: 10}}>
                        {!actionHidden && <MyButton icon={actionIcon} onClick={onAction} primary={true}>
                            {actionText}
                        </MyButton>}
                    </div>

                </div>
            </Grid>
        )


export default PricingItem;
