import StoryDetailsBlock from "../resdetails/display/storydetailsblock";
import MaintenanceEventListTable from "./maintenanceeventlisttable";
import React from "react";
import Details from "../ui-components/details";
import i18n from "../i18n/i18n";

const MaintenanceEventList = ({data, model, onSaveEvents, onDeleteEvent, onEventDetails, mainEvent, onClose, innerWidth, showWaitOverlay, width, height}) => {
  const id = data ? data.proxyID || data.id : 0;

  return <div style={{height: height}} className={"storydetailsmain"}>
    <Details onClose={() => onClose()}
             title={i18n("maintenanceEventList.js998639033")}
             showWaitOverlay={showWaitOverlay}
             naked={false}
             width={width}
             innerWidth={width}
    >
    <div style={{display: "flex", flexDirection: "column", marginTop:  40 }}>
      <StoryDetailsBlock width={innerWidth} color={"inherit"}>
        <MaintenanceEventListTable model={model} onSaveEvents={onSaveEvents} onDeleteEvent={onDeleteEvent} onEventDetails={onEventDetails} mainEvent={mainEvent}  resourceID={id}/>
      </StoryDetailsBlock>
    </div>
    </Details>
  </div>
}

export default MaintenanceEventList;
