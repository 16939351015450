import React, {useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import i18n from "../../../../i18n/i18n";

const DecorationDescriptor = ({decorationDescriptor, onChange}) => {
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    return <div>
            <Accordion expanded={open} onChange={() => {
                setOpen(!open)
            }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <BrandingWatermarkIcon style={{marginRight: 5,  color: "green"}}/>
                        <Typography variant="subtitle1" style={{marginRight: 10}}>
                            {i18n("decorationdescriptor.js556883219")}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                         <Tabs
                            value={selectedTab}
                            onChange={(event, newValue) => setSelectedTab(newValue)}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            <Tab value={0}
                                 label={i18n("decorationdescriptor.js92624992")}/>
                            <Tab value={1}
                                 label="JSON"/>
                        </Tabs>
                        <Paper style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}  elevation={5}>
                        {selectedTab === 0 && <div style={{margin: 10}}>
                            not implemented yet
                        </div>}
                        {selectedTab === 1 && <div style={{margin: 10}}>
                            <TextField fullWidth
                                       multiline
                                       value={decorationDescriptor || ""}
                                       style={{flexGrow: 10}}
                                       onChange={(evt) => onChange(evt.target.value)}
                            />
                        </div>}
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </div>
}

export default DecorationDescriptor;
