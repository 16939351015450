import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";

const MediaFullScreenWrapper = ({childs, style, zIndex, hideFullScreenButton}) => {
  const [fullScreen, setFullScreen] = useState(false);

  const getChilds = () => (<div style={{...style, position: "relative"}}>
    {(!hideFullScreenButton || fullScreen) && <div style={{display: "flex", justifyContent: "flex-end"}}>
        <div style={{position: "absolute", top: 5, right: 5, backgroundColor: 'rgba(0,0,0,0.3)', color: "#FFF", zIndex: 10, borderRadius: 5, padding: 5, lineHeight: 1, cursor: 'pointer'}} onClick={()=>setFullScreen(!fullScreen)}>
          {fullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
        </div>
      </div>}
      {childs(fullScreen)}
  </div>);

  return fullScreen ?  <Dialog
                          fullScreen
                          open={true}
                          style={{ zIndex: zIndex || 2000 }}>
                          {getChilds()}
                        </Dialog>
          :
          getChilds();
}

export default MediaFullScreenWrapper;
