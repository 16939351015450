import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import Filtertasklist from "../../filter/filtertasklist";
import {Set} from "immutable";
import types from "../types";

const getNewItem = (filteredIDs) => {
    let item = {
        "type": types.LINKTYPE_FILTER,
        "filteredIDs": filteredIDs
    }

    return item;
}

const ChooseFilterLinkDialog = model => {
    return function A({open, onOK, onCancel, item, clickObject}) { //Die Funktion braucht einen Namen: https://github.com/facebook/react/issues/20531
        const [filteredIDs, setFilteredIDs] = useState(item && item.filteredIDs ? new Set(item.filteredIDs) : (clickObject && clickObject.filteredIDs ? new Set(clickObject.filteredIDs) : new Set()));

        const cancelClicked = () => {
            setFilteredIDs([]);
            onCancel();
        }

        const okClicked = () => {
            onOK(getNewItem(filteredIDs.toArray()));
            setFilteredIDs([]);
        }

        return <Dialog open={open}
                       onClose={onCancel}
        >
            <DialogTitle>Link</DialogTitle>
            <DialogContent style={{overflow: "hidden"}}>
                <div style={{display: "flex", flexDirection: "column", height: "calc(100vH - 200px)", overflow: 'auto'}}>
                    <Filtertasklist filteredIDs={filteredIDs}
                                    tasks={model.getAllBaseData()}
                                    setFilteredIDs={(fids) => setFilteredIDs(fids)}
                                    barGroups={model.getBarGroups()}
                                    model={model}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => cancelClicked()}/>
                <OKButton onClick={() => okClicked()}/>
            </DialogActions>
        </Dialog>
    }
}

export default ChooseFilterLinkDialog;
