import StoryDetailsBlock from "../../display/storydetailsblock";
import React from "react";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import i18n from "../../../i18n/i18n";

const HeadlineInput = ({name, secname, onNameChanged, onSecnameChanged, innerWidth, bgColor}) => {
  return <StoryDetailsBlock name={i18n("headlineinput.js150642075")} width={innerWidth} color={bgColor} help={'index.html#_überschrift'}>
    <Paper elevation={5} style={{padding: 10, overflow: "hidden"}}>
      <TextField id="resNameInputField"
                 label={i18n("headlineinput.js593049979")}
                 value={name || ""}
                 inputProps={{style: {width: "100%", fontWeight: 'bold', fontSize: 20}}}
                 onChange={(evt) => onNameChanged(evt.target.value)}
                 fullWidth
                 style={{fontWeight: 'bold', fontSize: 20}}
      />
      <br/>
      <TextField id="resSecNameInputField"
                 label={i18n("headlineinput.js477282705")}
                 value={secname || ""}
                 onChange={(evt) => onSecnameChanged(evt.target.value)}
                 fullWidth/>
    </Paper>
  </StoryDetailsBlock>
}

export default HeadlineInput;
