import EditButton from "./plugins/buttons/EditButton";
import {Typography} from "@material-ui/core";
import {Separator} from "@draft-js-plugins/static-toolbar";
import MenuBlock from "./menublock";
import HeadlinesButton from "./plugins/buttons/HeadlinesButton";
import {BlockquoteButton, BoldButton, CodeBlockButton, CodeButton, ItalicButton, OrderedListButton, UnderlineButton, UnorderedListButton} from "@draft-js-plugins/buttons";
import AddImageButton from "./plugins/image/AddImageButton";
import AddVideoButton from "./plugins/video/AddVideoButton";
import AddMapButton from "./plugins/map/AddMapButton";
import AddPanoramaButton from "./plugins/panorama/AddPanoramaButton";
import AddQuizButton from "./plugins/quiz/AddQuizButton";
import AddInfoBlockButton from "./plugins/infoblock/AddInfoBlockButton";
import AddLinkButton from "./plugins/buttons/AddLinkButton";
import types from "./types";
import AddEventButton from "./plugins/buttons/AddEventButton";
import AddStoryButton from "./plugins/buttons/AddStoryButton";
import AddFilterButton from "./plugins/buttons/AddFilterButton";
import React, {useState} from "react";
import EditorUtils from "./utils/EditorUtils";
import computedense from "./computedense";
import TextAlignmentButton from "./plugins/buttons/TextAlignmentButton";
import i18n from "../i18n/i18n";
import HtmlTooltip from "../styling/htmltooltip";

const DraftEditorToolbar = ({onOpenDialog,
  editorState,
  onChange,
  dense,
  AlignmentTool,
  Toolbar,
  textAlignmentPlugin,
  dividerPlugin,
  imagePlugin,
  videoPlugin,
  mapPlugin,
  panoramaPlugin,
  ChooseQuizComponent,
  quizPlugin,
  infoBlockPlugin}) => {

  const [expandedMenu, setExpandedMenu] = useState(2);
  const [shouldEditCurrentBlock, setShouldEditCurrentBlock] = useState();

  if(!dense) {
    dense = computedense(window.innerWidth);
  }

  if (shouldEditCurrentBlock) {
    setShouldEditCurrentBlock(false);
    setTimeout(() =>
        editCurrentBlock(), 0);
  }

  const editCurrentBlock = () => {
    const currentBlock = EditorUtils.getCurrentBlock(editorState);
    if(currentBlock) {
      const entityKey = currentBlock.getEntityAt(0);
      if(entityKey) {
        const contentState = editorState.getCurrentContent();
        if(contentState) {
          const entity = contentState.getEntity(entityKey);
          if(entity) {
            onOpenDialog(entity.getType());
          }
        }
      }
    }
  }


  const addBlock = (evt, modifier, item) => {
    evt.preventDefault();
    evt.stopPropagation();
    let newEditorState = EditorUtils.addBlockAndSelect(modifier, editorState,
        item)

    setShouldEditCurrentBlock(true);

    onChange(newEditorState);
  }

  const openEditLink = (evt, linkType) => {
    evt.preventDefault();
    evt.stopPropagation();
    onOpenDialog(linkType);
  }

  const { DividerButton } = dividerPlugin;

  return <>
    <AlignmentTool>
      <EditButton onClick={() => editCurrentBlock()} theme={{}}/>
    </AlignmentTool>
    <div style={{
      position: "absolute",
      widht: "100%",
      height: "100%",
      top: 0,
      left: 0,
      pointerEvents: "none",
      zIndex: 2
    }}>
      <div style={{
        pointerEvents: "all",
        position: "sticky",
        top: 0,
        padding: 10
      }}>
        <Toolbar>
          {(externalProps) => {
            return <>
              {dense === 'ultra' && <div style={{display: "flex", paddingLeft: 5, paddingRight: 5}}>
                <Typography variant={"caption"} style={{fontWeight: expandedMenu===0 ? 'bold' : 'normal'}} onClick={() => setExpandedMenu(0)}>{i18n("drafteditortoolbar.js747935826")}</Typography>
                <Separator {...externalProps} />
                <Typography variant={"caption"} style={{fontWeight: expandedMenu===1 ? 'bold' : 'normal'}} onClick={() => setExpandedMenu(1)}>{i18n("drafteditortoolbar.js616605922")}</Typography>
                <Separator {...externalProps} />
                <Typography variant={"caption"} style={{fontWeight: expandedMenu===2 ? 'bold' : 'normal'}} onClick={() => setExpandedMenu(2)}>{i18n("drafteditortoolbar.js894699961")}</Typography>
                <Separator {...externalProps} />
                <Typography variant={"caption"} style={{fontWeight: expandedMenu===3 ? 'bold' : 'normal'}} onClick={() => setExpandedMenu(3)}>{i18n("drafteditortoolbar.js109669568")}</Typography>
              </div>}
              <MenuBlock name={i18n("drafteditortoolbar.js858681026")} onExpandMenu={() => setExpandedMenu(0)} smallMenu={expandedMenu!==0} dense={dense}>
                <HtmlTooltip arrow title={<Typography>{i18n('fonts')}</Typography>}>
                  <div>
                    <HeadlinesButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('bold')}</Typography>}>
                  <div>
                    <BoldButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('italic')}</Typography>}>
                  <div>
                    <ItalicButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('underline')}</Typography>}>
                  <div>
                    <UnderlineButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('code')}</Typography>}>
                  <div>
                    <CodeButton {...externalProps} />
                  </div>
                </HtmlTooltip>
              </MenuBlock>
              {dense !== "ultra" && <Separator {...externalProps} />}
              <MenuBlock name={i18n("drafteditortoolbar.js823346092")} onExpandMenu={() => setExpandedMenu(1)} smallMenu={expandedMenu!==1} dense={dense}>
                <HtmlTooltip arrow title={<Typography>{i18n('unorderedList')}</Typography>}>
                  <div>
                    <UnorderedListButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('orderedList')}</Typography>}>
                  <div>
                    <OrderedListButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('quote')}</Typography>}>
                  <div>
                    <BlockquoteButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('codeblock')}</Typography>}>
                  <div>
                    <CodeBlockButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('divider')}</Typography>}>
                  <div>
                    <DividerButton {...externalProps} />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('alignment')}</Typography>}>
                  <div>
                    <TextAlignmentButton {...externalProps} plugin={textAlignmentPlugin.TextAlignment}/>
                  </div>
                </HtmlTooltip>
              </MenuBlock>
              {dense !== "ultra" && <Separator {...externalProps} />}
              <MenuBlock name={i18n("drafteditortoolbar.js97337207")} onExpandMenu={() => setExpandedMenu(2)} smallMenu={expandedMenu!==2} dense={dense}>
                <HtmlTooltip arrow title={<Typography>{i18n('addImage')}</Typography>}>
                  <div>
                    <AddImageButton
                        {...externalProps}
                        onClick={(evt, defaultItem) => addBlock(evt,
                            imagePlugin.modifier, defaultItem)}
                    />
                  </div>
                </HtmlTooltip>
                {!window.isSchoolDomain && <>
                  <HtmlTooltip arrow title={<Typography>{i18n('addVideo')}</Typography>}>
                    <div>
                      <AddVideoButton
                          {...externalProps}
                          onClick={(evt, defaultItem) => addBlock(evt,
                              videoPlugin.modifier, defaultItem)}
                      />
                    </div>
                  </HtmlTooltip>
                  <HtmlTooltip arrow title={<Typography>{i18n('addMap')}</Typography>}>
                    <div>
                      <AddMapButton
                          {...externalProps}
                          onClick={(evt, defaultItem) => addBlock(evt,
                              mapPlugin.modifier, defaultItem)}
                      />
                    </div>
                  </HtmlTooltip>
                  <HtmlTooltip arrow title={<Typography>{i18n('addPanorama')}</Typography>}>
                    <div>
                      <AddPanoramaButton
                          {...externalProps}
                          onClick={(evt, defaultItem) => addBlock(evt,
                              panoramaPlugin.modifier, defaultItem)}
                      />
                    </div>
                  </HtmlTooltip>
                </>
                }
                {ChooseQuizComponent && <HtmlTooltip arrow title={<Typography>{i18n('addQuiz')}</Typography>}>
                      <div>
                        <AddQuizButton
                        {...externalProps}
                        onClick={(evt, defaultItem) => addBlock(evt,
                            quizPlugin.modifier, defaultItem)}
                        />
                      </div>
                </HtmlTooltip>}
                <HtmlTooltip arrow title={<Typography>{i18n('addInfoblock')}</Typography>}>
                  <div>
                    <AddInfoBlockButton
                        {...externalProps}
                        onClick={(evt, defaultItem) => addBlock(evt,
                            infoBlockPlugin.modifier, defaultItem)}
                    />
                  </div>
                </HtmlTooltip>
              </MenuBlock>
              {dense !== "ultra" && <Separator {...externalProps} />}
              <MenuBlock name={i18n("drafteditortoolbar.js82711167")} onExpandMenu={() => setExpandedMenu(3)} smallMenu={expandedMenu!==3} dense={dense}>
                <HtmlTooltip arrow title={<Typography>{i18n('addLink')}</Typography>}>
                  <div>
                    <AddLinkButton
                        onClick={(evt) => {
                          openEditLink(evt, types.LINKTYPE_BROWSER)
                        }}
                        {...externalProps}
                    />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('addEvent')}</Typography>}>
                  <div>
                    <AddEventButton
                        onClick={(evt) => {
                          openEditLink(evt, types.LINKTYPE_EVENT)
                        }}
                        {...externalProps}
                    />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('addStory')}</Typography>}>
                  <div>
                    <AddStoryButton
                        onClick={(evt) => {
                          openEditLink(evt, types.LINKTYPE_STORY)
                        }}
                        {...externalProps}
                    />
                  </div>
                </HtmlTooltip>
                <HtmlTooltip arrow title={<Typography>{i18n('addFilter')}</Typography>}>
                  <div>
                    <AddFilterButton
                        onClick={(evt) => {
                          openEditLink(evt, types.LINKTYPE_FILTER)
                        }}
                        {...externalProps}
                    />
                  </div>
                </HtmlTooltip>
              </MenuBlock>
            </>
          }}
        </Toolbar>
      </div>
    </div>
  </>
}

export default DraftEditorToolbar;
