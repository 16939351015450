/**
 * Eine Eintrag in der Berechtigungstabelle
 */
class MemberEntry {
    constructor(email, forename, surname, company, isdummy = 0, userid = -1, validated = false) {
        this.email = email;
        this.forename = forename;
        this.surname = surname;
        this.company = company;
        this.isdummy = isdummy;
        this.userid = userid;
        this.userValidated = validated;
    }

    equals(other) {
        return other.email === this.email;
    }

    hashCode() {
        //return Immutable.hash(this.email); //TODO: funktioniert derzeit noch nicht, siehe https://github.com/facebook/immutable-js/issues/1072

        let hash = 0, len = this.email.length;
        if (this.email.length === 0) {
            return hash;
        }
        for (let i = 0; i < len; i++) {
            let charC = this.email.charCodeAt(i);
            hash = ((hash << 5) - hash) + charC;
            hash = hash & hash; // Convert to 32bit integer
        }

        return hash;
    }
}

export default MemberEntry
