import StoryDetailsBlock from "../../display/storydetailsblock";
import Hint from "../../../ui-components/hint";
import React from "react";
import Config from "../../../config/config";
import i18n from "../../../i18n/i18n";

const DummyUserHint = ({onLogonClicked, innerWidth, bgColor}) => {
  return <StoryDetailsBlock width={innerWidth} color={bgColor}>
    <Hint
        type={"warning"}
        header={i18n("dummyuserhint.js499234330")}
        text={<>{i18n("dummyuserhint.js706662830")()}
          <br/>
          {i18n('dummyUserHint.notPublished')}
          <br/>
          {i18n('dummyUserHint.notAddedToAccount')}
          <br/>
          {i18n('dummyUserHint.logonHere')} <span style={{color: Config.getNeutralColor(), cursor: "pointer"}} onClick={onLogonClicked}>{i18n('dummyUserHint.here')}</span> an.</>}
    />
  </StoryDetailsBlock>
}

export default DummyUserHint;
