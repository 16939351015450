import React from "react";
import Paper from "@material-ui/core/Paper";
import DraftDisplayWrapper from "../../draftdisplaywrapper";

const EditorInfoBlock = ({blockProps, block, elementProps, style}) => {
  return <div {...elementProps} style={{position: "relative", pointerEvents: blockProps.getReadOnly() ? "inherit" : "none", marginLeft: window.innerWidth >= 800 ? (blockProps.item.marginLeft || 0) : 0, marginRight: window.innerWidth >= 800 ? (blockProps.item.marginRight || 0) : 0}}>
      <Paper style={{overflow: "hidden", width: 'calc(100% - 5px)', display: 'flex', justifyContent: "center", background: '#'+(blockProps.item.color && blockProps.item.color.length === 6 ? blockProps.item.color : 'fefff5')}} elevation={5} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div style={{margin: 20,  width: "inherit"}}>
          <DraftDisplayWrapper key={Math.random()} content={blockProps.item.content} model={blockProps.userProps.model} onBrowserLink={blockProps.userProps.onBrowserLink} onEventLink={blockProps.userProps.onEventLink} onStoryLink={blockProps.userProps.onStoryLink} onFilterLink={blockProps.userProps.onFilterLink} onMapLink={blockProps.userProps.onMapLink}/>
        </div>
      </Paper>
  </div>
}

export default EditorInfoBlock;
