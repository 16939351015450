// Logo

import React from "react";

class Logo extends React.Component {
    render() {
        const headerFill = this.props.headerColor || "#000";
        const headerStroke = this.props.headerStrokeColor || "#000";
        const hFill = this.props.hColor || "#E00";
        const hStroke = this.props.hStrokeColor || "#000";
        const opacity = this.props.opacity || 1;

        return (
            <div>
                <svg viewBox="0 0 500 100" width={(this.props.scale ? this.props.scale : 1) * 500} height={(this.props.scale ? this.props.scale : 1)*100} version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="m125.82 48.6q0 7.0294-6.277 11.352-6.277 4.2823-17.181 4.2823-6.0356 0-10.945-1.1312-4.909-1.1312-7.8865-2.5047v-11.958h1.3278q1.0864 0.76758 2.4947 1.6967 1.4485 0.88877 4.064 1.9391 2.2533 0.92917 5.1102 1.6159 2.8569 0.64638 6.1161 0.64638 4.2652 0 6.3173-0.92917 2.0521-0.96957 2.0521-2.8279 0-1.6563-1.2071-2.3835-1.2071-0.76758-4.5871-1.4544-1.6095-0.36359-4.3859-0.76758-2.7361-0.44439-4.9894-1.0504-6.1563-1.6159-9.1339-5.0094-2.9776-3.4339-2.9776-8.5241 0-6.5446 6.1563-10.867 6.1966-4.3631 16.9-4.3631 5.0699 0 9.6972 1.0504 4.6676 1.01 7.283 2.1815v11.473h-1.2474q-3.219-2.2219-7.3635-3.5955-4.1042-1.414-8.3292-1.414-3.5007 0-5.9149 0.96957-2.374 0.96957-2.374 2.7471 0 1.6159 1.0864 2.4643 1.0864 0.84837 5.1102 1.6967 2.2131 0.44438 4.748 0.88877 2.5752 0.40399 5.1504 1.0504 5.7137 1.4948 8.4499 4.7267 2.7361 3.1915 2.7361 7.9989z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m164.37 62.578q-2.3338 0.60599-4.9492 0.92918-2.6154 0.36359-6.3978 0.36359-8.4499 0-12.594-3.4339-4.1042-3.4339-4.1042-11.756v-21.209h-5.9551v-9.8573h5.9551v-12.968h14.485v12.968h13.56v9.8573h-13.56v16.079q0 2.3835 0.0402 4.1611 0.0402 1.7775 0.6438 3.1915 0.56332 1.414 1.9716 2.2623 1.4486 0.80798 4.1847 0.80798 1.1266 0 2.9373-0.48478 1.8509-0.48478 2.5752-0.88877h1.2071z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m219.18 40.319q0 11.19-6.5185 17.654-6.4782 6.4234-18.228 6.4234-11.749 0-18.268-6.4234-6.4782-6.4638-6.4782-17.654 0-11.271 6.5185-17.695 6.5587-6.4234 18.228-6.4234 11.83 0 18.268 6.4638 6.4782 6.4638 6.4782 17.654zm-17.825 11.029q1.4083-1.7371 2.0924-4.1611 0.72426-2.4643 0.72426-6.787 0-3.9995-0.72426-6.7062-0.72428-2.7067-2.0119-4.3227-1.2876-1.6563-3.0983-2.3431-1.8107-0.68678-3.903-0.68678t-3.7421 0.56558q-1.6095 0.56558-3.0983 2.2623-1.3278 1.5756-2.1326 4.3227-0.76452 2.7471-0.76452 6.9082 0 3.7167 0.68403 6.4638 0.68405 2.7067 2.0119 4.3631 1.2876 1.5756 3.058 2.3027 1.8107 0.72718 4.1042 0.72718 1.9716 0 3.7421-0.64638 1.8107-0.68678 3.058-2.2623z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m273.82 30.825v32.157h-14.566v-22.664q0-3.3127-0.16094-5.6154-0.16078-2.3431-0.88522-3.7975-0.72428-1.4544-2.2131-2.1007-1.4485-0.68678-4.1042-0.68678-2.1326 0-4.1445 0.88877-2.0119 0.84837-3.6214 1.8179v32.157h-14.485v-45.368h14.485v5.0094q3.7421-2.9491 7.1623-4.6055 3.4202-1.6563 7.5646-1.6563 4.4664 0 7.8865 2.0199 3.4202 1.9795 5.3516 5.8578 4.3456-3.6763 8.4499-5.777 4.1042-2.1007 8.0475-2.1007 7.3232 0 11.106 4.4035 3.8226 4.4035 3.8226 12.685v29.531h-14.566v-22.664q0-3.3531-0.16094-5.6558-0.12075-2.3027-0.84499-3.7571-0.68404-1.4544-2.1728-2.1007-1.4888-0.68678-4.1847-0.68678-1.8107 0-3.5409 0.64638-1.7302 0.60598-4.2249 2.0603z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m344.23 51.146v-9.4533q-2.9373 0.24239-6.3575 0.68678-3.4202 0.40399-5.1906 0.96957-2.1728 0.68678-3.3397 2.0199-1.1266 1.2928-1.1266 3.4339 0 1.414 0.24142 2.3027 0.24143 0.88877 1.2071 1.6967 0.92546 0.80798 2.2131 1.212 1.2876 0.36359 4.0237 0.36359 2.1728 0 4.3859-0.88877 2.2533-0.88877 3.9432-2.3431zm0 7.0294q-1.1669 0.88877-2.8971 2.1411-1.7302 1.2524-3.2592 1.9795-2.1326 0.96956-4.4261 1.414-2.2935 0.48479-5.0297 0.48479-6.438 0-10.784-3.9995-4.3456-3.9995-4.3456-10.221 0-4.969 2.2131-8.1201t6.277-4.969q4.0238-1.8179 9.9789-2.5855 5.9551-0.76758 12.353-1.1312v-0.24239q0-3.7571-3.058-5.171-3.058-1.4544-9.0132-1.4544-3.5811 0-7.6451 1.2928-4.064 1.2524-5.8344 1.9391h-1.3278v-10.948q2.2935-0.60598 7.4439-1.414 5.1906-0.84837 10.381-0.84837 12.353 0 17.825 3.8379 5.5125 3.7975 5.5125 11.958v30.865h-14.365z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m417.3 62.982h-14.566v-22.502q0-2.7471-0.28168-5.4538-0.28165-2.7471-0.9657-4.0399-0.80475-1.4948-2.374-2.1815-1.529-0.68678-4.3054-0.68678-1.9716 0-4.0237 0.64638-2.0119 0.64638-4.3859 2.0603v32.157h-14.485v-45.368h14.485v5.0094q3.8628-3.0299 7.4037-4.6459 3.5811-1.6159 7.9268-1.6159 7.3232 0 11.427 4.2823 4.1444 4.2823 4.1444 12.806z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m445.15 62.982h-14.485v-45.368h14.485zm0.40239-51.832h-15.29v-11.029h15.29z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m486.19 51.146v-9.4533q-2.9374 0.24239-6.3575 0.68678-3.4202 0.40399-5.1906 0.96957-2.1728 0.68678-3.3397 2.0199-1.1266 1.2928-1.1266 3.4339 0 1.414 0.24141 2.3027 0.24145 0.88877 1.2071 1.6967 0.92546 0.80798 2.2131 1.212 1.2876 0.36359 4.0237 0.36359 2.1728 0 4.3859-0.88877 2.2533-0.88877 3.9433-2.3431zm0 7.0294q-1.1669 0.88877-2.8971 2.1411-1.7302 1.2524-3.2592 1.9795-2.1326 0.96956-4.4261 1.414-2.2935 0.48479-5.0297 0.48479-6.438 0-10.784-3.9995-4.3456-3.9995-4.3456-10.221 0-4.969 2.2131-8.1201t6.277-4.969q4.0237-1.8179 9.9789-2.5855 5.9551-0.76758 12.353-1.1312v-0.24239q0-3.7571-3.058-5.171-3.058-1.4544-9.0132-1.4544-3.5812 0-7.6451 1.2928-4.064 1.2524-5.8344 1.9391h-1.3278v-10.948q2.2935-0.60598 7.4439-1.414 5.1906-0.84837 10.381-0.84837 12.353 0 17.825 3.8379 5.5125 3.7975 5.5125 11.958v30.865h-14.365z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m45.591 97.543-14.517-0.015641-0.25213-57.165c-0.01-1.8314-0.0939-3.6494-0.28166-5.4538-0.18777-1.8314-0.50967-3.178-0.9657-4.0399-0.5365-0.9965-1.3278-1.7237-2.374-2.1815-1.0194-0.45785-2.4545-0.68678-4.3054-0.68678-1.3144 0-2.6557 0.21546-4.0237 0.64638-1.3412 0.43092-2.8032 1.1177-4.3859 2.0603v32.157h-14.485v-62.86h14.485v22.502c2.5752-2.0199 5.0431-3.5686 7.4037-4.6459 2.3874-1.0773 5.0297-1.6159 7.9268-1.6159 4.8821 0 8.6913 1.4274 11.427 4.2823 2.763 2.8548 4.1445 7.1236 4.1445 12.806l0.19513 62.702 21.032 0.10121-0.01018 1.411z" fill={hFill} stroke={hStroke} fillOpacity={opacity}/>
                    <path d="m73.231 62.861h-14.485v-45.368h14.485zm0.40237-51.832h-15.29v-11.029h15.29z" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m66.273 51.378-12.32 27.988 12.275 17.32" strokeWidth="1" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m44.012 95.929 22.781 0.05022" strokeWidth="1" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <path d="m66.273 51.378 12.32 27.988-12.275 17.32" strokeWidth="1" fill={headerFill} stroke={headerStroke} fillOpacity={opacity}/>
                    <g>
                        <g id="g912">
                            <g transform="translate(.62579 .089398)">
                                <g id="g897">
                                    <circle cx="65.743" cy="79.005" r="2.7814" fill="#fff" strokeWidth="1px" stroke={headerStroke} fillOpacity={opacity}/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <path d="m66.206 96.151 0.038537-17.129" fill="#fff" stroke="#fff" strokeWidth="1px" fillOpacity={opacity}/>
                </svg>
            </div>
        );
    }
}

export default Logo;
