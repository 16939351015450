import React from "react";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import i18n from "../i18n/i18n";

const PriceLegendSchool = () => (
    <div style={{marginLeft: 20}}>

        <Table style={{width: "100%", border: "1px solid black"}}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{i18n('priceLegendSchool.pricesPerYearPerUser')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow style={{background: "#EEE"}}>
                    <TableCell>{i18n('priceLegendSchool.teachers')}</TableCell>
                    <TableCell>50 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendSchool.from5Teachers')}n</TableCell>
                    <TableCell>45 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendSchool.from10Teachers')}n</TableCell>
                    <TableCell>42,50 €</TableCell>
                </TableRow>
                <TableRow style={{background: "#EEE"}}>
                    <TableCell>{i18n('priceLegendSchool.students')}</TableCell>
                    <TableCell>3 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendSchool.from100Students')}n</TableCell>
                    <TableCell>2,70 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendSchool.from300Students')}n</TableCell>
                    <TableCell>2,40 €</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
)

export default PriceLegendSchool;