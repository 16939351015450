import React from "react";
import createToolbarButton from "../../createToolbarButton";
import MapIcon from "@material-ui/icons/AddLocation";
import generateUUID from "../../../markdown/generateUUID";

const defaultItem =  {
  "type": "GoogleDynamicMap",
  "center": {
    "lat": 48.244301715223735,
    "lng": 11.440022529303821
  },
  "zoom": 5,
  "objects": [],
  "uuid": generateUUID(),
  "title": ""
}

const Button = createToolbarButton({children: <MapIcon/>});

const AddMapButton = ({onClick, theme}) => (
    <Button onClick={(evt)=>onClick(evt, defaultItem)} theme={theme}/>
)

export default AddMapButton;
