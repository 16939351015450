import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import {Set} from "immutable";
import LinkIcon from "@material-ui/icons/Link";
import Search from "../../search/search";
import types from "../types";

const getNewItem = (storyid) => {
    let item = {
        "type": types.LINKTYPE_STORY,
        "storyid": storyid
    }

    return item;
}

const ChooseStoryLinkDialog = model => {
    return function A({open, onOK, onCancel, item})  { //Die Funktion braucht einen Namen: https://github.com/facebook/react/issues/20531
        const [url, setUrl] = useState(item ? item.url : "");

        const cancelClicked = () => {
            setUrl("");
            onCancel();
        }

        const okClicked = () => {
            onOK(getNewItem(url));
            setUrl("");
        }

        return <Dialog open={open}
                       onClose={onCancel}
        >
            <DialogTitle>hiStory verlinken</DialogTitle>
            <DialogContent style={{overflow: "hidden"}}>
                <div style={{display: "flex", flexDirection: "column", height: "calc(100vH - 200px)"}}>
                    <Search onClose={() => console.log("onClose")}
                            onToggleResourceVisibility={(storyid) => onOK(getNewItem(storyid))}
                            onHighlight={(id) => console.log(id)}
                            onShowMap={(lat, lng) => console.log(lat + "/" + lng)}
                            onResShowDetails={(id) => console.log(id)}
                            onTaskShowDetails={(task) => this.props.onTaskClick(task)}
                            onFilterClicked={()=>console.log("filterClicked")}
                            model={model}
                            pendingResourceIDs={new Set()}
                            onSearch={(expr) => console.log("search")}
                            showWaitOverlay={false}
                            naked={true}
                            checkboxIcon={<LinkIcon/>}
                            width={'auto'}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={() => cancelClicked()}/>
                <OKButton onClick={() => okClicked()}/>
            </DialogActions>
        </Dialog>

    }
}

export default ChooseStoryLinkDialog;
