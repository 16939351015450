import React from 'react';
import MaskedInput from '../mymaskedfield';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { LCal} from 'react-canvas-timeline';
import InputAdornment from '@material-ui/core/InputAdornment';



let positions = 4;

function getTextMaskCustom(props, precision) {
    const {inputRef, ...other} = props;

    //Tage (11) und genauer, Monate (10)
    let mask = precision === 10 ?  "11.M" : precision <=9 ? "M" :  "11.11.M";
    let sizeMinus = precision === 10 ?  3 : precision <=9 ? 6 :  0;

    for (let n = 1; n < positions; n++) {
        mask = mask + "1";
    }

    return (
        <MaskedInput
            {...other}
            mask={mask}
            name="date"
            size={Math.max(12, 7 + positions)-sizeMinus}
            formatCharacters={{
                'M': {
                    validate(char) {
                        return /-|\d/.test(char)
                    }
                }
            }}/>
    );
}

class FormattedInputs extends React.Component {
    constructor(props) {
        super(props);
        positions = props.yearPositions || 4;
        this.textMaskCustomFunc = (props) => getTextMaskCustom(props, this.props.precision);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.precision !== this.props.precision) {
            //Die Funktion muss auf diese Weise erneuert werden. Wird sie nicht erneuert, dann kommt es zu komischen Effekten bei der Mask.
            this.textMaskCustomFunc = (props) => getTextMaskCustom(props, this.props.precision);
        }
    }

    getStringValue(val) {
        if (val instanceof LCal) {
            if (this.props.precision < 10 ) {
                return ""+val.getYear();
            } else if (this.props.precision === 10) {
                return ("0" + val.getMonth()).slice(-2) + "." + val.getYear();
            } else {
                return ("0" + val.getDay()).slice(-2) + "." + ("0" + val.getMonth()).slice(-2) + "." + val.getYear();
            }
        }
        return val;
    }

    /**
     * Liefert ein LCal zu einem Datumsstring, oder den Datumsstring, falls kein LCal erzeugt werden konnte
     */
    getLCal(dateStr) {
        dateStr = dateStr && dateStr.replace(/_/g, ' ').trim();
        let arr = dateStr.split(".");

        if (arr.length === 3) {
            return this.getLCalForDayPrecision(arr, dateStr);
        } else if (arr.length === 2) {
            return this.getLCalForMonthPrecision(arr, dateStr);
        } else if (arr.length === 1) {
            return this.getLCalForYearPrecision(arr, dateStr);
        }

        return dateStr;
    }

    getLCalForDayPrecision(arr, dateStr) {
        let day = parseInt(arr[0]);
        if (!isNaN(day) && day > 0 && day < 32) {
            let month = parseInt(arr[1]);
            if (!isNaN(month) && month > 0 && month < 13) {
                let year = parseInt(arr[2]);
                if (!isNaN(year)) {
                    let lcal = new LCal().initYMDHM(year, month, day, 0, 0, "Europe/Berlin");
                    //Die eingegebene Zeichenkette und die aus dem LCal erstellte müssen identisch sein, sonst könnte z.B. 29.02.2015 verwandelt werden in 01.03.2016
                    let datestring = ("0" + lcal.getDay()).slice(-2) + "." + ("0" + lcal.getMonth()).slice(-2) + "." + lcal.getYear();
                    if (datestring === dateStr) {
                        return lcal;
                    }
                }
            }
        }
        return null;
    }

    getLCalForMonthPrecision(arr, dateStr) {
            let month = parseInt(arr[0]);
            if (!isNaN(month) && month > 0 && month < 13) {
                let year = parseInt(arr[1]);
                if (!isNaN(year)) {
                    let lcal = new LCal().initYMDHM(year, month, 1, 0, 0, "Europe/Berlin");
                    //Die eingegebene Zeichenkette und die aus dem LCal erstellte müssen identisch sein, sonst könnte z.B. 29.02.2015 verwandelt werden in 01.03.2016
                    let datestring =  ("0" + lcal.getMonth()).slice(-2) + "." + lcal.getYear();
                    if (datestring === dateStr) {
                        return lcal;
                    }
                }
            }
        return null;
    }

    getLCalForYearPrecision(arr, dateStr) {
        let year = parseInt(arr[0]);
        if (!isNaN(year)) {
            let lcal = new LCal().initYMDHM(year, 1, 1, 0, 0, "Europe/Berlin");
            //Die eingegebene Zeichenkette und die aus dem LCal erstellte müssen identisch sein, sonst könnte z.B. 29.02.2015 verwandelt werden in 01.03.2016
            let datestring = ""+lcal.getYear();
            if (datestring === dateStr) {
                return lcal;
            }
        }
        return null;
    }

    handleChange(event) {
        if (this.props.onChange) {
            let lcal = this.getLCal(event.target.value);
            this.props.onChange(lcal);
        }
    }

    render() {
        //Bis zum Jahr +/- 10000 wird der Tag mit angezeigt
        return (
            <FormControl style={{margin: 1, padding: 0}}>
                <InputLabel htmlFor="formatted-text-mask-input">{this.props.label}</InputLabel>
                <Input
                    value={this.getStringValue(this.props.value)}
                    onChange={(evt) => this.handleChange(evt)}
                    id="formatted-text-mask-input"
                    inputComponent={this.textMaskCustomFunc}
                    onKeyPress={(ev) => {
                        if (this.props.onInputComplete && ev.key === 'Enter') {
                            this.props.onInputComplete();
                        }
                    }}
                    disableUnderline={true}
                    autoFocus={this.props.autoFocus}
                    startAdornment={
                        <InputAdornment position="start">
                            <div></div>
                        </InputAdornment>
                    }
                    style={{fontWeight: this.props.fontSize === 'l' && 'bold', fontSize: this.props.fontSize === 'l' && 18}}

                />
            </FormControl>
        );
    }
}

export default FormattedInputs;
