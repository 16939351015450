import React, {useEffect} from 'react';

const TextAlignmentPicker = plugin => function I(props) {

  const onWindowClick = () =>
      // Call `onOverrideContent` again with `undefined`
      // so the toolbar can show its regular content again.
      props.onOverrideContent(undefined);

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onWindowClick);
    });
    return () => {
      window.removeEventListener('click', onWindowClick);
    }
  }, []);

  const TextAlignmentPlugin = plugin;
    return <div>
          <TextAlignmentPlugin {...props} />
        </div>

}

export default TextAlignmentPicker;
