/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import {TaskModel} from 'react-canvas-timeline'
import BookingItem from '../data/bookingitem';
import {Set, OrderedSet} from 'immutable';

/**
 * Die Datenquelle
 */
class HistoModel extends TaskModel {
    constructor() {
        super();
        this.expandBars = false;
        this.basedata = [];
        this.filteredIDs = new Set();
    }

    createBookingItem(resID, start, end) {
        start && start.setTimeZone("Europe/Berlin");
        end && end.setTimeZone("Europe/Berlin");

        let bi = new BookingItem(0, start, end, resID);
        bi.getDisplayData().setColor("#DDDDDD");

        return bi;
    }

    doFilter() {
        this.data.length = 0;
        for (let n = 0; n < this.basedata.length; n++) {
            if(!this.filteredIDs.contains(this.basedata[n].getID())) {
                this.data.push(this.basedata[n]);
            }
        }
        this._setDisplayDataDirty(true);
    }

    _fireDataChanged(type) {
        this.doFilter();
        super._fireDataChanged(type);
    }

    setFilteredIDs(filteredIDs) {
        this.filteredIDs = filteredIDs;
        this._fireDataChanged("FILTER");
    }

    getFilteredIDs() {
        return this.filteredIDs;
    }

    isFiltered() {
        return this.filteredIDs && this.filteredIDs.size > 0;
    }

    clearFilteredIDs() {
        this.filteredIDs = new Set();
        this._fireDataChanged("FILTER");
    }

    put(d, isAligning) {
        if (d.isDeleted()) {
            this.remove(d, isAligning);
            return;
        }
        let isReplaced = false;
        //Gibt es das Element bereits?
        if (this.id2Item.get(d.getID())) {
            //Falls ja, bei welchem Index?
            let i = -1;
            for (let n = 0; n < this.basedata.length; n++) {
                if (this.basedata[n].getID() === d.getID()) {
                    i = n;
                    break;
                }
            }
            if (i >= 0) {
                this.basedata[i] = d;
                isReplaced = true;
            }
        }
        if (!isReplaced) {
            this.basedata.push(d);
        }
        this.id2Item.set(d.getID(), d);

        if (!isAligning) {
            this.sort();
            this._fireDataChanged();
        }
    }

    add(d, isAligning) {
        this.basedata.push(d);
        this.id2Item.set(d.getID(), d);
        if (!isAligning) {
            this._fireDataChanged();
        }
    }

    removeByID(id, isAligning) {
        let item = this.id2Item.get(id);
        let i = this.basedata.indexOf(item);
        if (i !== -1) {
            this.basedata.splice(i, 1);
            this.id2Item.delete(id);
            if (!isAligning) {
                this._fireDataChanged();
            }
        }
    }

    setAll(dList) {
        this.id2Item.clear();
        this.basedata = dList;
        for (let d of dList) {
            this.id2Item.set(d.getID(), d);
        }
        this.sort();
        this._fireDataChanged();
    }

    getAllBaseData() {
        return this.basedata;
    }


    getBarGroups() {
        let barGroups = new OrderedSet();
        for (let n = 0; n < this.basedata.length; n++) {
            let barGroup = this.basedata[n].getDisplayData().getBarGroup();
            if(barGroup && barGroup.length > 0 && !barGroups.contains(barGroup)) {
                barGroups = barGroups.add(barGroup);
            }
        }
        return barGroups;
    }

    clear() {
        this.data.length = 0;
        this.basedata.length = 0;
        this.id2Item.clear();
        this._fireDataChanged();
    }

    sort() {
        this.basedata.sort((i1, i2) => {
            let retVal = this.getDisplayedStart(i1).getJulianMinutes() - this.getDisplayedStart(i2).getJulianMinutes();
            if (retVal === 0) {
                retVal = i1.getID() - i2.getID();
            }
            return retVal;
        });
    }

    removeResource(res) {
        super.removeResource(res);
        this.basedata = this.basedata.filter((t) => t.getResID() !== res.id);
    }

    /**
     * Liefert ein Array von IDs, die im Model sind und nicht weggefiltert sind
     * @param filterdIDs
     * @returns {*}
     */
    invertFilter(filterdIDs) {
        return this.getAllBaseData().filter(t => !filterdIDs.contains(t.id)).map(t => t.proxyID || t.id);
    }

    cloneDeep() {
            let newModel = new HistoModel();
            newModel.iconProvider = this.iconProvider;
            for(let t of this.basedata) {
                let tClone = t.clone();
                //TODO: innerEvents werden anscheinend nicht gecloned
                tClone.innerEvents = t.innerEvents;
                tClone.imgSrc = t.imgSrc;
                tClone.imgLicense = t.imgLicense;
                tClone.wikiimg = t.wikiimg;
                tClone.wikiimglicense = t.wikiimglicense;
                newModel.put(tClone, true);
            }
            newModel.expandBars = this.expandBars;
            newModel.filteredIDs = this.filteredIDs;
            newModel.barSize = this.barSize;

            for(let r of this.getResourceModel().getAll()) {
                newModel.getResourceModel().put(r.clone(), true);
            }

            newModel.getResourceModel().sort();
            newModel.getResourceModel()._fireDataChanged();

            newModel.sort();
            newModel._fireDataChanged();

            return newModel;
    }
}

export default HistoModel
