import Config from "../config/config";

const getThumbImgSrc = (obj) => {
  let imgSrc = obj.imageurl;
  if (obj.imageurl && obj.imageurl !== "") {
    imgSrc = Config.getBaseURL() + "/images/resourceimages/thumb/" + obj.imageurl;
  } else if (obj.wikiimg) {
    imgSrc = Config.getWikiImageURL() + "?name=" + obj.wikiimg.replace(new RegExp("'", 'g'), "%27");
  }
  if (!imgSrc && obj.imgSrc) {
    imgSrc = Config.getBaseURL() + "/images/resourceimages/thumb/" + obj.imgSrc;
  }
  return imgSrc;
}

const getImgSrc = (obj) => {
  let imgSrc = obj.imageurl;
  if (obj.imageurl && obj.imageurl !== "") {
    imgSrc = Config.getBaseURL() + "images/resourceimages/" + obj.imageurl;
  } else if (obj.wikiimg) {
    imgSrc = Config.getWikiImageURL() + "?name=" + obj.wikiimg.replace(new RegExp("'", 'g'), "%27");
  }
  if (!imgSrc && obj.imgSrc) {
    imgSrc = Config.getBaseURL() + "images/resourceimages/" + obj.imgSrc;
  }
  return imgSrc;
}

const getIconProvider = (icons) => (obj, onLoad) => {
  //Das Objekt kann entweder eine Ressource sein, oder es handelt sich um ein JSON-Objekt, das eine imageurl enthält
  if (obj !== null) {
    let imgSrc = getThumbImgSrc(obj);

    if (imgSrc && imgSrc !== "") {
      if (icons[imgSrc]) {
        return icons[imgSrc];
      } else {
        let img = new Image();

        //Falls das Icon schon durch eine komplette URL angegeben wurde, dann nimm diese, ansonsten füge den Pfad dazu
        img.src = imgSrc;
        if (onLoad) {
          img.onload = onLoad;
        }
        icons[imgSrc] = img;
        return img;
      }
    }
  }
  return null;
}

export {getThumbImgSrc, getImgSrc, getIconProvider};
