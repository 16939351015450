import React, {useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Paper from '@material-ui/core/Paper';
import WikipediaIcon from "../../images/icons/wikipedia.js";
import i18n from "../../i18n/i18n";

const WikipediaReference = ({wikipage, onHelp, onChange}) => {
    const [displayWikiRefEditor, setDisplayWikiRefEditor] = useState(false);

    return (
        <>
            <Accordion expanded={displayWikiRefEditor} onChange={() => {
                setDisplayWikiRefEditor(!displayWikiRefEditor)
            }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <WikipediaIcon viewBox="0 0 25 20" style={{marginRight: 5,  color: "green"}}/>
                        <Typography variant="subtitle1" style={{marginRight: 10}}>
                            {i18n("wikipediareference.js272862577")}
                        </Typography>
                        <Typography variant="subtitle1" style={{color: "lightgray"}}>
                            {wikipage}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'row'}}>
                        <Typography variant="caption" style={{color: "#4C68A7"}}>
                            {i18n("wikipediareference.js904830442")}
                        </Typography>
                        <Button color={"secondary"} onClick={() => onHelp && onHelp()}>
                            <HelpOutlineIcon/>
                        </Button>
                    </div>
                    <br/>
                    <Paper style={{display: 'flex', justifyContent: 'center', flexGrow: 10, padding: 20}}  elevation={5}>
                        <TextField fullWidth
                                   label={i18n("wikipediareference.js851003152")}
                                   value={wikipage || ""}
                                   style={{flexGrow: 10}}
                                   onChange={(evt) => onChange(evt.target.value)}/>
                    </Paper>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default WikipediaReference;
