import React from "react";
import {IconButton} from "@material-ui/core";

const MyIconButton = ({icon, onClick, style, primary = true, disabled, autoFocus, href}) => {
  let myStyle = {...style, ...{
      color: disabled ? "gray" : (primary ? "white" : (style && style.color) || "black")
    }};
  return <IconButton style={myStyle}
          autoFocus={autoFocus}
          disabled={disabled}
          onClick={
            onClick
          }
          href={href}
  >
    {icon}
  </IconButton>
}

export default MyIconButton;
