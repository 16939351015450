import React from "react";
import createToolbarButton from "../../createToolbarButton";
import EditIcon from "@material-ui/icons/Edit";

const Button = createToolbarButton({children: <EditIcon/>});

const EditButton = ({onClick, theme}) => (
  <Button onClick={onClick} theme={theme}/>
)

export default EditButton;
