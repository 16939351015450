import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';


class Details extends React.Component {
    componentDidMount() {
        if (this.props.scrollContainerRef) {
            this.props.scrollContainerRef(this.refs.scrollContainer);
        }
    }

    render() {
        const waitStyle = {
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            background: "rgba(100,100,100,0.5)",
            zIndex: 1000
        };

        return (
            <div style={{
                width: this.props.width==="auto" ? null : Math.min(this.props.width ? this.props.width : 600, window.innerWidth),
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                background: this.props.background
            }}
                 onScroll= {this.props.onScroll}
                 onMouseMove={this.props.onMouseMove}
                 >

                {!this.props.naked && <AppBar position="static" color={"secondary"}>
                    <Toolbar style={{paddingLeft: 20, display: 'flex', justifyContent: 'space-between'}}>

                        <Typography variant="h6" style={{color: 'gray'}}>
                            {this.props.title ? this.props.title : ""}
                        </Typography>
                        <div style={{display: "flex"}}>
                            {this.props.addButtons}
                            {this.props.onClose ? <IconButton color="inherit" onClick={(evt) => {
                                    this.props.onClose()
                                }}>
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                        <HighlightOff/>
                                    </div>
                                </IconButton>
                                :
                                <div></div>
                            }
                        </div>
                    </Toolbar>
                </AppBar>}

                <div ref="scrollContainer" style={{
                    flex: 1,
                    overflowX: "hidden",
                    position: "relative"
                }}>
                    {this.props.showWaitOverlay && <div style={waitStyle}><CircularProgress size={80}/></div>}

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.props.loadMore ? this.props.loadMore : () => {
                        }}
                        hasMore={this.props.hasMore ? true : false}
                        loader={<div key="infinitescroll_loader" style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                            <CircularProgress/></div>}
                        useWindow={false}
                    >
                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <div style={{display: "flex", justifyContent: "center", width: this.props.innerWidth || "100%"}}>
                                {this.props.children ? this.props.children : <div></div>}
                            </div>
                        </div>
                    </InfiniteScroll>

                </div>

                {this.props.fab ?
                    <div style={{position: "absolute", bottom: 60, right: 10}}>{this.props.fab}</div> : null}

                {this.props.statusBar ? this.props.statusBar : null}
            </div>
        )
    }


}

export default Details;
