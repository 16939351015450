import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Hint from "./hint";
import MyButton from "./buttons/text/mybutton";
import CancelButton from "./buttons/text/cancelbutton";
import i18n from "../i18n/i18n";

const HintDialog = ({warnDialogType, dialogWarningText, canPressOKInWarningDialog, saveSelectedResourceNoWarn, onClose}) => {
  return <Dialog
      open={!!dialogWarningText}
  >
    <DialogContent>
      {dialogWarningText && <Hint
          type={warnDialogType}
          header={warnDialogType === "info" ? i18n("info") : i18n("attention")}
          text={dialogWarningText}
      />}
    </DialogContent>
    <DialogActions>
      {canPressOKInWarningDialog ?
          <React.Fragment>
            <CancelButton onClick={onClose} />
            <MyButton onClick={() => saveSelectedResourceNoWarn()}>
              {i18n("understoodAndSave")}
            </MyButton>
          </React.Fragment>
          :
          <MyButton onClick={onClose} >
            {i18n("hintdialog.js158836303")}
          </MyButton>
      }
    </DialogActions>
  </Dialog>
}

export default HintDialog;
