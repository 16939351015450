import HtmlTooltip from "../../../styling/htmltooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React from "react";

const PreviewButton = ({title, style, tooltip, Icon, onClick, color, autoFocus, disabled}) => {
  return <div className={"previewButtonWrapper"}>
    <div onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}>
      <HtmlTooltip title={<Typography color="inherit">{tooltip}</Typography>}>
        <Button className={"previewButton"} variant="contained" size="small" onClick={onClick} autoFocus={autoFocus} style={style} disabled={disabled}>
          <Icon style={{fontSize: 35, color: color || "inherit"}}/>
        </Button>
      </HtmlTooltip>
      {title}
    </div>
  </div>
}

export default PreviewButton;
