import React from "react";
import createToolbarButton from "../../createToolbarButton";
import YouTubeIcon from '@material-ui/icons/YouTube'
import i18n from "../../../i18n/i18n";

let defaultItem = {
  "type": "YouTubeVideo",
  "id": "c4jkErZcSCo",
  "start": 0,
  "title": i18n("AddVideoButton.js665672521")
}

const Button = createToolbarButton({children: <YouTubeIcon/>});

const AddVideoButton = ({onClick, theme}) => (
    <Button onClick={(evt)=>onClick(evt, defaultItem)} theme={theme}/>
)

export default AddVideoButton;
