import React from "react";
import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import IconButton from '@material-ui/core/IconButton';

const StoryDetailsBlock = ({children, name, topShadow, color, width, help, noTopBottomMargin}) => (<>
  <div style={{display: 'flex', alignItems: 'center', flexDirection: "column", background: color, boxShadow: topShadow ? "inset 0px 18px 10px -20px rgba(0,0,0,0.37)" : "inherit"}}>
    <div style={{width: width-40, marginTop: noTopBottomMargin ? 0 : 30, marginBottom: noTopBottomMargin ? 0: 30}}>
      {name && <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}><Typography variant="h2" style={{fontSize: 28, marginBottom: 20}}>{name}</Typography>{help && <IconButton onClick={()=>{
        let lang = "de";
        if(localStorage.getItem('histomania_language')) {
          lang = localStorage.getItem('histomania_language');
        }
        window.open('./help/'+lang+'/'+(help||'index.html'),'_blank');
      }}><HelpOutlineIcon/></IconButton>}</div>}
      {children}
    </div>
  </div>
  </>
)

export default StoryDetailsBlock;
