import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Quiz from './quiz/lazyquiz';
import MediaFullScreenWrapper from "./mediaFullScreenWrapper";
import GoogleMap from "./lazygooglemap";
import PreviewButton from "../ui-components/buttons/text/previewbutton";
import LocationOn from "@material-ui/icons/LocationOn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import StreetviewIcon from "@material-ui/icons/Streetview";
import QuizIcon from "@material-ui/icons/Streetview";
import {renderers} from "../markdown/markdownhelper";
import YouTube from "./youtube";
import StreetView from "./streetview";
import i18n from "../i18n/i18n";

const getMap = (mapDesc, width, height, displayDummies, onClick) => {
  return !displayDummies && <div style={{overflow: "hidden", border: "1px solid gray", width: 'calc(100% - 5px)', lineHeight: 0}} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <GoogleMap id="Map"
                                       initialCenter={mapDesc.center}
                                       center={mapDesc.center}
                                       zoom={mapDesc.zoom}
                                       height={height}
                                       width={"100%"}
                                       onClick={(clickData) => onClick && onClick(clickData)}
        >
          {
            mapDesc.objects
          }
        </GoogleMap>
      </div>
}

const getYouTubeVideo = (videoDesc, width, height, displayDummies) => {
  return !displayDummies && <div style={{border: "1px solid gray", width: "calc(100% - 5px)", lineHeight: 0}}>
    <YouTube youtubeid={videoDesc.id} height={height} start={videoDesc.start}/>
  </div>
}

const getStreetView = (item, width, height, displayDummies) => {
  return !displayDummies && <div style={{width: "calc(100% - 5px)", lineHeight: 0}}>
    <StreetView
        streetviewId={item.id}
        height={height}
        style={{width: '100%'}}
    />
  </div>
}

const getQuiz = (item, width, height, displayDummies, mapDescriptor, onToggleResourceVisibility, onHighlight, onFilterClicked, onShowMap, pendingResourceIDs, model) => {
  return !displayDummies && <div style={{border: "1px solid gray", width: "calc(100% - 5px)", lineHeight: 0, display: 'flex', justifyContent: "center"}}>
    <Quiz quiz={item}
          renderers={renderers((resid) => model.getResourceModel().getItemByID(resid), onToggleResourceVisibility, onHighlight, onShowMap, pendingResourceIDs, model, mapDescriptor)}
          onToggleResourceVisibility={onToggleResourceVisibility}
          onHighlight={onHighlight}
          onFilterClicked={onFilterClicked}
          onShowMap={onShowMap}
          pendingResourceIDs={pendingResourceIDs}
          model={model}
          mapDescriptor={mapDescriptor}/>
  </div>
}

const getMapPreview = (item, onClick) => {
  return <PreviewButton title={item.title} tooltip={i18n('mediaFile.mapTooltip')} Icon={LocationOn} onClick={onClick}/>
}

const getYouTubePreview = (item, onClick) => {
  return <PreviewButton title={item.title} tooltip={i18n('mediaFile.videoTooltip')} Icon={YouTubeIcon} onClick={onClick} color={"#E00"}/>
}

const getStreetViewPreview = (item, onClick) => {
  return <PreviewButton title={item.title} tooltip={i18n('mediaFile.streetViewTooltip')} Icon={StreetviewIcon} onClick={onClick}/>
}

const getQuizPreview = (item, onClick) => {
  return <PreviewButton title={item.title} tooltip={i18n('mediaFile.quizTooltip')} Icon={QuizIcon} onClick={onClick}/>
}

const MediaItem = ({item, preview, displayDummies, onClick, title, width, height, children, mapDescriptor, onToggleResourceVisibility, onHighlight, onFilterClicked, onShowMap, pendingResourceIDs, model}) => {
  const [lPreview, setLPreview] = useState(false);

  let previewButton;
  if (preview) {
    if (item.type === "GoogleDynamicMap") {
      previewButton = getMapPreview(item, (e) => {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});
    } else if (item.type === "YouTubeVideo") {
      previewButton = getYouTubePreview(item, (e) => {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});
    } else if (item.type === "StreetView") {
      previewButton = getStreetViewPreview(item, (e) => {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});
    } else if (item.type === "Quiz") {
      previewButton = getQuizPreview(item, (e) => {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});
    }
  }

  return <>
    {previewButton}
    {(!preview || lPreview) && <div style={{
      width: width,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      zIndex: item.type === "GoogleDynamicMap" ? 0 : 1
    }}>
      <div style={{width: '100%'}}>
        {title && <Typography>{title}</Typography>}
        <div>
          {item.type === "GoogleDynamicMap" && getMap(item, width, height, displayDummies, onClick)}
          {item.type === "YouTubeVideo" && <MediaFullScreenWrapper childs={(fullScreen)=>getYouTubeVideo(item, width, fullScreen ? window.innerHeight : height, displayDummies)}/>}
          {item.type === "StreetView" && <MediaFullScreenWrapper childs={(fullScreen)=>getStreetView(item, width, fullScreen ? window.innerHeight : height, displayDummies)} />}
          {item.type === "Quiz" && <MediaFullScreenWrapper childs={(fullScreen)=>getQuiz(item, width, fullScreen ? window.innerHeight : height, displayDummies, mapDescriptor, onToggleResourceVisibility, onHighlight, onFilterClicked, onShowMap, pendingResourceIDs, model)} />}
        </div>
        {children}
        {item.title && <Typography variant={"caption"} style={{fontSize: 10}}>{item.title}</Typography>}
      </div>
    </div>
    }
  </>
}

export {getMap, getMapPreview, MediaItem};
