import React from "react";
import createToolbarButton from "../../createToolbarButton";
import PostAddIcon from "@material-ui/icons/PostAdd";


const Button = createToolbarButton({children: <PostAddIcon/>});

const AddStoryButton = ({onClick, theme}) => (
  <Button onClick={onClick} theme={theme}/>
)

export default AddStoryButton;
