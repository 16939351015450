import React from "react";
import Image from "./Image";
import EditorBlock from "../EditorBlock";
import BaseConfig from "../../../config/config";

const EditorImage = ({blockProps, block, elementProps}) => (
  <EditorBlock blockProps={blockProps} block={block} elementProps={elementProps}
               child={      <Image src={(blockProps.item.src.startsWith("http") ? "" : BaseConfig.getResBaseURL()) + blockProps.item.src} alt={blockProps.item.alt} style={{
                 position: 'relative',
                 width: '100%'
               }}/>}
  />
)

export default EditorImage;
