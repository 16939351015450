import LockIcon from "@material-ui/icons/Lock";
import PricingButton from "./buttons/text/pricingbutton";
import React from "react";


const LockWarning = ({onPricingClicked, text}) => {
    return <span style={{
        fontSize: 20,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFAAAA",
        border: "1px solid black",
        borderRadius: 5,
        padding: 20
    }}>
                        <LockIcon style={{fontSize: 35}}/>
                      <div style={{paddingLeft: 20, paddingRight: 20}}>{text}</div>
                        <PricingButton style={{minWidth: 130}} onClick={() => onPricingClicked(true)}/>
                    </span>
}
export default LockWarning;

