import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import MyButton from "./buttons/text/mybutton";
import i18n from "../i18n/i18n";

const CancelEditDialog = ({open, yesCallback, noCallback}) => (
  <Dialog
      open={open}
  >
    <DialogTitle
        id="alert-dialog-title">{i18n("closeWithSave")}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {i18n("closeWithoutSaveQuestion")()}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={yesCallback} color="primary" autoFocus>
        {i18n("yes")}
      </Button>
      <MyButton onClick={noCallback}>
        {i18n("no")}
      </MyButton>
    </DialogActions>
  </Dialog>
)

export default CancelEditDialog;
