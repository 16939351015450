import React from "react";
import createToolbarButton from "../../createToolbarButton";
import FilterIcon from "../../../images/icons/filter";

const Button = createToolbarButton({children: <FilterIcon/>});

const AddFilterButton = ({onClick, theme}) => (
  <Button onClick={onClick} theme={theme}/>
)

export default AddFilterButton;
