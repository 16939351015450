import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import HeightIcon from "@material-ui/icons/Height";
import {Divider} from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import DurationPanel from "../ui-components/lib/durationpanel";
import List from "@material-ui/core/List";
import theme from "../theme";
import ExitToApp from "@material-ui/icons/Close";
import i18n from "../i18n/i18n";

const MeasureHeader = ({measureStart, measureEnd, measureDurationLock, currentMeasureInterval, onChange, languageCode}) => (
    <div style={{display: "flex", justifyContent: "space-between", width: "100%", height: 80}}>
      <List style={{display: "flex", justifyContent: "left"}}>
        <ListItem button dense style={{color: "black"}} onClick={() => {
          onChange(measureStart, measureEnd, measureDurationLock, true);
        }}>
          {window.innerWidth >= 1200 ? <ListItemText style={{whiteSpace: 'nowrap'}} inset primary={i18n("enlargeToSelection", languageCode).toUpperCase()}/> : <HeightIcon style={{transform: 'rotate(90deg)'}}/>}
        </ListItem>
        <Divider orientation={'vertical'}/>
        <ListItem button dense style={{color: "black"}} onClick={() => {
          onChange(measureStart, measureEnd, !measureDurationLock, false);
        }}>
          {window.innerWidth >=  1200 ? <ListItemText style={{whiteSpace: 'nowrap'}}  inset primary={i18n("fixDuration", languageCode).toUpperCase()}/> : <Lock/>}
        </ListItem>
        <Divider orientation={'vertical'}/>
        <ListItem>
          {currentMeasureInterval && <DurationPanel interval={currentMeasureInterval} listItemStyle={{border: '1px solid gray'}}/>}
        </ListItem>
      </List>
      <List style={{display: "flex", justifyContent: "right"}}>
        <ListItem button dense style={{color: "black"}} onClick={() => {
          onChange(null, null, false, false);
        }}>
          {window.innerWidth >=  1200 ? <ListItemText style={{whiteSpace: 'nowrap', color: theme.palette.primary.main}} inset primary={<span style={{display: 'flex', alignItems: "center"}}><ExitToApp style={{color: 'red'}}/>{i18n("endMeasurement", languageCode).toUpperCase()}</span>} /> : <ExitToApp style={{color: 'red'}}/>}
        </ListItem>
      </List>
    </div>
)

export default MeasureHeader;
