import React from "react";
import createToolbarButton from "../../createToolbarButton";
import LinkIcon from '@material-ui/icons/Link';


const Button = createToolbarButton({children: <LinkIcon/>});

const AddLinkButton = ({onClick, theme}) => (
  <Button onClick={onClick} theme={theme}/>
)

export default AddLinkButton;
