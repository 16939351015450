import React from "react";
import Quiz from "../../../media/quiz/lazyquiz";
import Paper from "@material-ui/core/Paper";

const EditorQuiz = ({blockProps, block, elementProps, style}) => {
  //TODO: key von Quiz nur ändern, wenn sich das item geändert hat
  return <div {...elementProps} style={{position: "relative", pointerEvents: blockProps.getReadOnly() ? "inherit" : "none"}}>
    <Paper style={{overflow: "hidden", border: "1px solid gray", width: 'calc(100% - 5px)', lineHeight: 0, display: 'flex', justifyContent: "center"}} elevation={5} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <Quiz key={Math.random()} quiz={blockProps.item} model={blockProps.userProps.model} onBrowserLink={blockProps.userProps.onBrowserLink} onEventLink={blockProps.userProps.onEventLink} onStoryLink={blockProps.userProps.onStoryLink} onFilterLink={blockProps.userProps.onFilterLink} onMapLink={blockProps.userProps.onMapLink}/>
      </Paper>
  </div>
}

export default EditorQuiz;
