import React from "react";

const WelcomeBlock = ({children, backgroundColor}) => {
  return <div style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: "column",
        background: backgroundColor
      }}>
        <div style={{width: Math.min(window.innerWidth - 40, 1000), marginTop: 40, marginBottom: 40}}>
          {children}
        </div>
  </div>
}

export default WelcomeBlock;
