import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

//const path = "M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z";
//const path = "m560.95 1412.7h74.09l247.37-479.88 203.5 479.88h71.33l422.78-972.73c23.7-54.57 69.9-87.34 119-94.76v-57.933h-341.86v57.953c45.36 10.09 93.51 27.09 114.92 74.961l-310.44 716.06c-56.12-119.35-113.66-249.77-168.58-379.92 56.685-120.43 102.98-232.29 172.52-342.22 40.656-64.68 106.63-66.2 140.63-69.38v-57.45h-363.4v57.45c45.34.045 139.69 4.078 97.62 92.49l-105.39 221.59-111.63-246.49c-17.379-38.38 16.38-63.95 71.49-66.997l-.405-57.993h-408.98v57.45c48.728 1.601 117.43 1.34 142.79 42.655 78.09 143.45 130.3 292.46 201.49 438.72l-184.22 344.49-309.64-711.78c-34.892-80.21 47.945-106.05 101.76-112.75l-.605-58.794h-436.06v57.45c60.21 19.908 137.88 109.48 159.56 154.81 162.89 340.7 244.91 569.12 400.39 913.18z";
const path = "m 8.0062487,15.998568 h 1.0533586 l 3.5169297,-6.8224436 2.893218,6.8224436 h 1.014119 L 22.494659,2.169287 C 22.831608,1.3934657 23.488447,0.92757404 24.186516,0.82208522 V -0.001546 H 19.326194 V 0.82236878 C 19.97109,0.96581895 20.655652,1.207507 20.960044,1.8880881 L 16.54643,12.0683 C 15.748556,10.371506 14.930494,8.5173267 14.14968,6.6669858 14.955587,4.9548341 15.613776,3.3645215 16.602446,1.8016482 17.180465,0.88209835 18.118436,0.86048714 18.601823,0.81527475 V -0.0014903 h -5.166561 v 0.81676505 c 0.644611,5.0636e-4 1.986012,0.0579775 1.387891,1.31493175 L 13.324793,5.2805467 11.737718,1.7762039 C 11.490635,1.2305564 11.970597,0.86702418 12.754111,0.82370556 l -0.0058,-0.82448189993 H 6.9337678 V 0.81598871 C 7.6265476,0.83877463 8.6033037,0.8350276 8.9638541,1.4224141 10.074082,3.4618378 10.816366,5.5803086 11.828495,7.6596837 L 9.2093867,12.557291 4.8071458,2.4379333 C 4.3110764,1.2975876 5.4887938,0.93022229 6.2538973,0.83496687 l -0.0086,-0.8358748627 H 0.04570561 V 0.81585706 C 0.9017284,1.098889 2.005985,2.3723351 2.3142157,3.0167876 4.6300699,7.8605141 5.7961713,11.107957 8.0066751,15.999454 z"
const Wikipedia = (props) => (
    <SvgIcon {...props} viewBox='0 -5 24 24'>
        <path d={path}/>
    </SvgIcon>
);


export default Wikipedia;
export {path};
