const types = {
  MAPTYPE: 'softmanufakturMapType',
  PANORAMATYPE: 'softmanufakturPanoramaType',
  VIDEOTYPE: 'softmanufakturVideoType',
  QUIZTYPE: 'softmanufakturQuizType',
  IMAGETYPE: 'softmanufakturImageType',
  MARKDOWN: 'softmanufakturMarkdownType',
  INFOBLOCK: 'softmanufakturInfoBlockType',
  LINKTYPE_BROWSER: 'LINKTYPE_BROWSER',
  LINKTYPE_EVENT: 'LINKTYPE_EVENT',
  LINKTYPE_STORY: 'LINKTYPE_STORY',
  LINKTYPE_MAP: 'LINKTYPE_MAP',
  LINKTYPE_FILTER: 'LINKTYPE_FILTER',
  ATOMIC: 'atomic',
}

export default types;
