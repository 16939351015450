import React from 'react';
import {LCalFormatter} from 'react-canvas-timeline';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from '@material-ui/core/Checkbox';
import LCalDateField from '../ui-components/lib/datetimepicker/lcaldatefield';
import i18n from "../i18n/i18n";

const toggleTask = (taskID, props) => {
    if (props.filteredIDs.contains(taskID)) {
        props.setFilteredIDs(props.filteredIDs.delete(taskID));
    } else {
        props.setFilteredIDs(props.filteredIDs.add(taskID));
    }
}

const toggleGroup = (group, isOn, props) => {
    let filteredIDs = props.filteredIDs;
    for (let t of props.tasks) {
        if (t.getDisplayData().getBarGroup() === group || "" === group) {
            if (isOn) {
                filteredIDs = filteredIDs.delete(t.id);
            } else {
                filteredIDs = filteredIDs.add(t.id);
            }
        }
    }
    props.setFilteredIDs(filteredIDs);
}

/**
 * Gibt es bei den gefilterten mindestens einen von dieser Gruppe?
 */
const hasOneFilteredGroup = (group, props) => {
    //Die filteredIDs durchlaufen, für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern. getItemByID
    for (let id of props.filteredIDs) {
        const t = props.model.getItemByID(id);
        if (t && ((t && t.getDisplayData().getBarGroup() === group) || ("" === group))) {
            return true;
        }
    }
    return false;
}

/**
 * Gibt es bei den ungefilterten mindestens einen von dieser Gruppe?
 */
const hasOneNotFilteredGroup = (group, props) => {
    const all = props.model.getAll();
    //data durchlaufen und für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern.
    for (let t of all) {
        if ((t.getDisplayData().getBarGroup() === group) || ("" === group)) {
            return true;
        }
    }
    return false;
}

export const containsBuzzword = (t, buzzword) => {
    if(!buzzword) {
        return true;
    }
    const lowerBuzzword = buzzword.toLowerCase();
    return (t && ((t.name && t.name.toLowerCase().includes(lowerBuzzword)) || (t.secname && t.secname.toLowerCase().includes(lowerBuzzword)) || "" === buzzword));
}

/**
 * Gibt es bei den gefilterten mindestens einen mit diesem Buzzword?
 */
const hasOneFilteredBuzzword = (buzzword, props) => {
    buzzword = buzzword.toLowerCase();
    //Die filteredIDs durchlaufen, für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern. getItemByID
    for (let id of props.filteredIDs) {
        const t = props.model.getItemByID(id);
        if (containsBuzzword(t, buzzword)) {
            return true;
        }
    }
    return false;
}

/**
 * Gibt es bei den ungefilterten mindestens einen mit diesem Buzzword?
 */
const hasOneNotFilteredBuzzword = (buzzword, props) => {
    buzzword = buzzword.toLowerCase();
    const all = props.model.getAll();
    //data durchlaufen und für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern.
    for (let t of all) {
        if ((t.name && t.name.toLowerCase().includes(buzzword)) || (t.secname && t.secname.toLowerCase().includes(buzzword)) || "" === buzzword) {
            return true;
        }
    }
    return false;
}

const toggleBuzzword = (buzzword, isOn, props) => {
    buzzword = buzzword.toLowerCase();
    let filteredIDs = props.filteredIDs;
    for (let t of props.tasks) {
        if ((t.name && t.name.toLowerCase().includes(buzzword)) || (t.secname && t.secname.toLowerCase().includes(buzzword)) || "" === buzzword) {
            if (isOn) {
                filteredIDs = filteredIDs.delete(t.id);
            } else {
                filteredIDs = filteredIDs.add(t.id);
            }
        }
    }
    props.setFilteredIDs(filteredIDs);
}

const toggleTimeSpan = (start, end, isOn, props) => {
    let filteredIDs = props.filteredIDs;
    for (let t of props.tasks) {
        if ((!end || (!t.start || t.start.before(end))) && (!start || (!t.end || t.end.after(start)))) {
            if (isOn) {
                filteredIDs = filteredIDs.delete(t.id);
            } else {
                filteredIDs = filteredIDs.add(t.id);
            }
        }
    }
    props.setFilteredIDs(filteredIDs);
}

const togglePointInTime = (isOn, props) => {
    let filteredIDs = props.filteredIDs;
    for (let t of props.tasks) {
        if (t.isPointInTime()) {
            if (isOn) {
                filteredIDs = filteredIDs.delete(t.id);
            } else {
                filteredIDs = filteredIDs.add(t.id);
            }
        }
    }
    props.setFilteredIDs(filteredIDs);
}

/**
 * Gibt es bei den gefilterten mindestens einen, der ein Zeitpunkt ist?
 */
const hasOneFilteredPointInTime = (props) => {
    //Die filteredIDs durchlaufen, für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern. getItemByID
    for (let id of props.filteredIDs) {
        const t = props.model.getItemByID(id);
        if (t && t.isPointInTime()) {
            return true;
        }
    }
    return false;
}

/**
 * Gibt es bei den ungefilterten mindestens einen, der ein Zeitpunkt ist?
 */
const hasOneNotFilteredPointInTime = (props) => {
    const all = props.model.getAll();
    //data durchlaufen und für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern.
    for (let t of all) {
        if (t && t.isPointInTime()) {
            return true;
        }
    }
    return false;
}



/**
 * Gibt es bei den gefilterten mindestens einen, der im angegebenen Zeitraum ist?
 */
const hasOneFilteredTimeSpan = (start, end, props) => {
    //Die filteredIDs durchlaufen, für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern. getItemByID
    for (let id of props.filteredIDs) {
        const t = props.model.getItemByID(id);
        if ((!end || (!t.start || t.start.before(end))) && (!start || (!t.end || t.end.after(start)))) {
            return true;
        }
    }
    return false;
}

/**
 * Gibt es bei den ungefilterten mindestens einen mit diesem Zeitraum?
 */
const hasOneNotFilteredTimeSpan = (start, end, props) => {
    const all = props.model.getAll();
    //data durchlaufen und für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern.
    for (let t of all) {
        if ((!end || (!t.start || t.start.before(end))) && (!start || (!t.end || t.end.after(start)))) {
            return true;
        }
    }
    return false;
}


const toggleTimeSpanEvent = (isOn, props) => {
    let filteredIDs = props.filteredIDs;
    for (let t of props.tasks) {
        if (!t.isPointInTime()) {
            if (isOn) {
                filteredIDs = filteredIDs.delete(t.id);
            } else {
                filteredIDs = filteredIDs.add(t.id);
            }
        }
    }
    props.setFilteredIDs(filteredIDs);
}

/**
 * Gibt es bei den gefilterten mindestens einen, der ein Zeitpunktraum ist?
 */
const hasOneFilteredTimeSpanEvent = (props) => {
    //Die filteredIDs durchlaufen, für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern. getItemByID
    for (let id of props.filteredIDs) {
        const t = props.model.getItemByID(id);
        if (t && !t.isPointInTime()) {
            return true;
        }
    }
    return false;
}

/**
 * Gibt es bei den ungefilterten mindestens einen, der ein Zeitraum ist?
 */
const hasOneNotFilteredTimeSpanEvent = (props) => {
    const all = props.model.getAll();
    //data durchlaufen und für jedes Element die Gruppe bestimmen.
    //Stimmt die Gruppe überein, dann abbrechen und true zurückliefern.
    for (let t of all) {
        if (t && !t.isPointInTime()) {
            return true;
        }
    }
    return false;
}

const Filtertasklist = (props) => {
    const [buzzword, setBuzzword] = React.useState(props.searchPhrase || "");
    const [filterStart, setFilterStart] = React.useState(props.initialFilterStartTime);
    const [filterEnd, setFilterEnd] = React.useState(props.initialFilterEndTime);

    const a = (barGroup, index) => {
        return <div key={barGroup + " " + index} style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div syle={{display: "flex", alignItems: "center"}}>{barGroup}</div>
            <div syle={{display: "flex"}}>
                <Button onClick={() => toggleGroup(barGroup, true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredGroup(barGroup, props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => toggleGroup(barGroup, false, props)} variant="contained"
                        color="secondary" disabled={!hasOneNotFilteredGroup(barGroup, props)}
                        style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>
    }

    return <React.Fragment>
        <Typography variant="h5">{i18n("filtertasklist.js930196063")}</Typography>
        <br/>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>{i18n("filtertasklist.js18265885")}</div>
            <div syle={{display: "flex"}}>
                <Button onClick={() => toggleGroup("", true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredGroup("", props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => toggleGroup("", false, props)} variant="contained" color="secondary"
                        disabled={!hasOneNotFilteredGroup("", props)} style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>

        <Typography variant="h5">{i18n("filtertasklist.js560877919")}</Typography>
        <br/>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <TextField label="Suchbegriff eingeben" variant="outlined" onChange={(evt)=>setBuzzword(evt.target.value)} value={buzzword}/>
            <div syle={{display: "flex"}}>
                <Button onClick={() => toggleBuzzword(buzzword, true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredBuzzword(buzzword, props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => toggleBuzzword(buzzword, false, props)} variant="contained" color="secondary"
                        disabled={!hasOneNotFilteredBuzzword(buzzword, props)} style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>
        <br/>

        <Typography variant="h5">{i18n("filtertasklist.js132146692")}</Typography>
        <br/>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <LCalDateField label="von" value={filterStart} onChange={(d) => setFilterStart(d)} onInputComplete={() => {}} yearPositions={12}/>
            <LCalDateField label="bis" value={filterEnd} onChange={(d) => setFilterEnd(d)} onInputComplete={() => {}} yearPositions={12}/>
            <div syle={{display: "flex"}}>
                <Button onClick={() => toggleTimeSpan(filterStart, filterEnd, true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredTimeSpan(filterStart, filterEnd, props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => toggleTimeSpan(filterStart, filterEnd, false, props)} variant="contained" color="secondary"
                        disabled={!hasOneNotFilteredTimeSpan(filterStart, filterEnd, props)} style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>
        <br/>

        <Typography variant="h5">{i18n("filtertasklist.js8240783")}</Typography>
        <br/>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>{i18n("filtertasklist.js192609356")}</div>
            <div syle={{display: "flex"}}>
                <Button onClick={() => togglePointInTime(true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredPointInTime(props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => togglePointInTime(false, props)} variant="contained" color="secondary"
                        disabled={!hasOneNotFilteredPointInTime(props)} style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div>{i18n("filtertasklist.js774361819")}</div>
            <div syle={{display: "flex"}}>
                <Button onClick={() => toggleTimeSpanEvent(true, props)} variant="contained" color="primary"
                        disabled={!hasOneFilteredTimeSpanEvent(props)} style={{margin: 5}}><Visibility/></Button>
                <Button onClick={() => toggleTimeSpanEvent(false, props)} variant="contained" color="secondary"
                        disabled={!hasOneNotFilteredTimeSpanEvent(props)} style={{margin: 5}}><VisibilityOff/></Button>
            </div>
        </div>
        <br/>

        {props.barGroups && props.barGroups.size > 0 && <div>
            <Typography variant="h5">{i18n("filtertasklist.js700792519")}</Typography>
            <br/>
            {
                props.barGroups.map((barGroup, index) => a(barGroup, index))
            }
        </div>
        }
        <br/>
        <Typography variant="h5">{i18n("filtertasklist.js482827431")}</Typography>
        <br/>
        {props.tasks.map((task, index) => {
            return <div key={task.id + " " + index}
                        style={{
                            background: index % 2 ? "#FFF" : "#EEE",
                            padding: 5,
                            cursor: "pointer",
                            display: "flex"
                        }}
                        >

                <Checkbox checked={!props.filteredIDs.contains(task.id)} onChange={() => {toggleTask(task.id, props)}}
                        value="checkedA"/>
                <div onClick={() => props.onTaskShowDetails && props.onTaskShowDetails(task, props)}>
                    <Typography>
                        {task.name && task.name !== "" ? task.name : task.secname}
                    </Typography>
                    <Typography variant="caption">
                        {LCalFormatter.formatStartEnd(task)}
                    </Typography>
                </div>
            </div>
        })
        }
    </React.Fragment>
}

export default Filtertasklist;
