import React from 'react';
import HistoSuspense from "../histosuspense";
const ImageUploadCrop = React.lazy(() => import('./imageuploadcrop'));

export class LazyImageUploadCrop extends React.Component {
    render() {
        return <HistoSuspense>
            <ImageUploadCrop {...this.props}/>
        </HistoSuspense>
    }
}

export default LazyImageUploadCrop;
