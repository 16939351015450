import React, {memo} from "react";
import HistoSuspense from "../../histosuspense";
const Quiz = React.lazy(() => import('./Quiz'));

export class LazyQuiz extends React.Component {
    render() {
        return <HistoSuspense>
            <Quiz {...this.props}/>
        </HistoSuspense>
    }
}

export default memo(LazyQuiz);
