import React, {
  useEffect,
  useRef,
} from 'react';


const getDisplayName = (WrappedComponent) => {
  const component = WrappedComponent.WrappedComponent || WrappedComponent;
  return component.displayName || component.name || 'Component';
};

const CreateDecorator = ({ store }) =>
  (
    WrappedComponent
  ) => {
    const BlockAlignmentDecorator = React.forwardRef(({ blockProps, block, style, ...elementProps }, ref) => {
      const wrapper = useRef();
      useEffect(() => {
        if (blockProps.isFocused && blockProps.isCollapsedSelection) {
          const boundingRect = wrapper.current && wrapper.current.getBoundingClientRect();
          store.updateItem('setAlignment', blockProps.setAlignment);
          store.updateItem('alignment', blockProps.alignment);
          store.updateItem('boundingRect', boundingRect);
          store.updateItem('visibleBlock', block.getKey());
          // Only set visibleBlock to null in case it's the current one. This is important
          // in case the focus directly switches from one block to the other. Then the
          // Alignment tool should not be hidden, but just moved.
        } else if (store.getItem('visibleBlock') === block.getKey()) {
          store.updateItem('visibleBlock', null);
        }
        return () => {
          store.updateItem('visibleBlock', null);
        };
      }, [blockProps.isFocused, blockProps.isCollapsedSelection, store]);

      const alignment = blockProps.alignment;
      let newStyle = style;
      if(window.innerWidth >= 800) {
        if (alignment === 'left') {
          newStyle = {...style, float: 'left'};
        } else if (alignment === 'right') {
          newStyle = {...style, float: 'right'};
        } else if (alignment === 'center') {
          newStyle = {
            ...style,
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          };
        }
      } else {
        newStyle = {
          ...style,
          marginLeft: 0,
          marginRight: 0,
          display: 'block',
          width: '100%'
        };
      }
      return (
        <WrappedComponent
          {...elementProps}
          block={block}
          blockProps={blockProps}
          style={newStyle}
          ref={(node) => {
            wrapper.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              // eslint-disable-next-line no-param-reassign
              ref.current = node;
            }
          }}
        />
      );
    });

    BlockAlignmentDecorator.displayName = `Alignment(${getDisplayName(
      WrappedComponent
    )})`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BlockAlignmentDecorator.WrappedComponent =
      WrappedComponent.WrappedComponent || WrappedComponent;

    return BlockAlignmentDecorator;
  };

export default CreateDecorator;
