import {Typography} from "@material-ui/core";
import React from "react";

const MenuBlock = ({name, dense, smallMenu, onExpandMenu, children}) => {
  return (dense !== "ultra" || !smallMenu) && <div style={{display: 'inline-block'}}>
        {dense !== "normal" && smallMenu ?
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', paddingRight: 5, paddingLeft: 5}} onClick={()=>onExpandMenu(true)}>
              <div style={{display: 'flex', height: 18, color: "lightgray"}}>...</div>
              {dense !== "ultra" && <Typography variant={'caption'}>{name}</Typography>}
            </div>
            :
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{display: 'flex'}}>{children}</div>
              {dense !== "ultra" && <Typography style={{color: 'lightgray'}} variant={'caption'}>{name}</Typography>}
            </div>
        }
    </div>
}


export default MenuBlock;
