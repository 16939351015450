import React from "react";
import Tasklist from "../../ui-components/tasklist";
import "../styles/storydetails.css";

const TaskListDIV = ({itemsInTimeline, eventListExpanded, model, onTaskShowDetails, onHighlight, onFilterClicked, onClick, pendingResourceIDs, onToggleResourceVisibility, onShowMap, background}) => (
    <>
      <div style={{position: 'relative', marginBottom: 10, marginLeft: -10, marginRight: -20}}>
        <div style={{maxHeight: eventListExpanded? 'none' : 350, overflow: 'hidden'}}>
          <Tasklist tasks={itemsInTimeline}
                    model={model}
                    onTaskShowDetails={onTaskShowDetails}
                    onHighlight={onHighlight}
                    onFilterClicked={onFilterClicked}
                    pendingResourceIDs={pendingResourceIDs}
                    onToggleResourceVisibility={onToggleResourceVisibility}
                    onShowMap={onShowMap}
                    showDetails={true}
          />
        </div>
        {!eventListExpanded && <div className="gradientback" style={{backgroundImage: "linear-gradient(to bottom, rgba(236, 218, 125,0) 0% , "+background+" 100%)"}}/>}
      </div>
      <div style={{textDecoration: "underline", marginBottom: 20, cursor: "pointer", display: "flex", justifyContent: "flex-end", width: '100%'}} onClick={onClick}>{eventListExpanded ? "weniger...": "mehr..."}</div>
    </>
)

export default TaskListDIV;
