import React from "react";
import YouTubePlugin from "./YouTubePlugin";
import EditorBlock from "../EditorBlock";

const EditorVideo = ({blockProps, block, elementProps, style}) => {
  return <EditorBlock blockProps={blockProps} block={block} elementProps={elementProps}
               child={<YouTubePlugin youtubeid={blockProps.item.id} start={blockProps.item.start} style={{
                 position: 'relative',
                 width: '100%',
                 height: style.width.replace('%', '') * 0.007 * Math.min(blockProps.maxWidth, window.innerWidth) + 'px'
               }}/>}
  />
}

export default EditorVideo;
