import React from "react";
import createToolbarButton from "../../createToolbarButton";
import PanoramaIcon from "@material-ui/icons/PanoramaHorizontal";
import i18n from "../../../i18n/i18n";

const defaultItem = {
  title: i18n('addPanoramaButton.title'),
  id: "!4v1646315328631!6m8!1m7!1sCAoSLEFGMVFpcE9Ea1RhN19NU0ozUmtnWEw1OGNwbXBSTGVFR3lkS1MwODNuZFk5!2m2!1d52.51641488689967!2d13.37815314503396!3f250.55966734137533!4f13.05865605095481!5f0.7820865974627469"
};

const Button = createToolbarButton({children: <PanoramaIcon/>});

const AddPanoramaButton = ({onClick, theme}) => (
    <Button onClick={(evt)=>onClick(evt, defaultItem)} theme={theme}/>
)

export default AddPanoramaButton;
