import React from "react";
import MyButton from "./mybutton";
import i18n from "../../../i18n/i18n";
import ArrowBack from '@material-ui/icons/ArrowBack';

const BackButton = ({onClick, autoFocus, disabled}) => {
    let myStyle = {
            color: "red",
            background: "rgba(0,0,0,0)",
            boxShadow: "inherit"
    };
   return <MyButton icon={<ArrowBack/>} style={myStyle} onClick={onClick} autoFocus={autoFocus} primary={false}
              disabled={disabled}>
        {i18n('back')}
    </MyButton>
}

export default BackButton;
