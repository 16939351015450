import React from "react";
import GoogleMap from "../../../media/lazygooglemap";
import EditorBlock from "../EditorBlock";

const EditorMap = ({blockProps, block, elementProps, style}) => (
  <EditorBlock blockProps={blockProps} block={block} elementProps={elementProps}
               child={<GoogleMap id="Map"
                                 initialCenter={blockProps.item.center}
                                 center={blockProps.item.center}
                                 zoom={blockProps.item.zoom}
                                 height={style.width.replace('%', '') * 0.007 * Math.min(blockProps.maxWidth, window.innerWidth) + 'px'}
                                 width={"100%"}
                                 onClick={(clickData) => console.log("onClick")}
               >
                 {
                   blockProps.item.objects
                 }
               </GoogleMap>}
  />
)

export default EditorMap;
