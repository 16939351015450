import React from 'react';

import blockModifier from './blockModifier';
import types from '../types';

const createBlockComponent = (BlockComponent) => React.forwardRef(({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  blockStyleFn, // eslint-disable-line no-unused-vars
  preventScroll, // eslint-disable-line no-unused-vars
  style,
  ...elementProps
}, ref) => {
      return <div
          ref={ref}
          {...elementProps}
          style={{width: 200, ...style, padding: 10}}
      >
        <BlockComponent blockProps={blockProps} block={block} elementProps={elementProps} style={style}/>
      </div>
    }
);

export default function createBlockPlugin(config) {
  const component = config.decorator
      ? config.decorator(createBlockComponent(config.BlockComponent))
      : createBlockComponent(config.BlockComponent);

  const modifier = blockModifier(config.type);

  return {
    blockRendererFn: (block, { getReadOnly, getEditorState, setEditorState }) => {
      if (block.getType() === types.ATOMIC) {
        // TODO subject to change for draft-js next release
        const contentState = getEditorState().getCurrentContent();
        const entityKey = block.getEntityAt(0);
        if (!entityKey) return null;
        const entity = contentState.getEntity(entityKey);
        const type = entity.getType();
        const { item } = entity.getData();
        if (type === config.type) {
          return {
            component,
            props: {
              item,
              maxWidth: config.maxWidth,
              userProps: config.userProps,
              getReadOnly: getReadOnly,
            },
          };
        }
      }
      return null;
    },
    modifier,
    types,
  };
}
