import React, {memo, useState} from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import i18n from "../i18n/i18n";

const StreetView = ({streetviewId, height=300, style}) => {
  const [rerender, setRerender] = useState(false);

  if(window.isSchoolDomain) {
    return <div style={{padding: 20, lineHeight: 1.4}}>{i18n("streetview.js903524730")}</div>
  }

  const histomania_load_googlemaps = localStorage.getItem("histomania_load_googlemaps");
  const myStyle = {
        width: "100%",
        height: {height},
        ...style
  }
  return histomania_load_googlemaps ? <iframe title={"Panorama"}
                                           style={myStyle}
                                           height= {height}
                                           src={"https://www.google.com/maps/embed?fullscreenControl=false&pb=" + streetviewId}
                                           loading="lazy"
      />
    :
  <div style={{padding: 20}}>
        <FormControlLabel
            control={
              <Checkbox checked={false} onChange={() => {
                localStorage.setItem("histomania_load_googlemaps", true);
                setRerender(!rerender);
              }}
                        value="checkedA"/>
            }
            label={i18n("streetview.js459438593")}
        />
  </div>
}

export default memo(StreetView);
