const waitStyle = (width, height) => {
  return {
    position: "absolute",
    top: height / 2 - 60,
    left: width / 2 - 80,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
};

export default waitStyle;
