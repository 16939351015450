import React from 'react';
import SimpleResourceItem from './simpleresourceitem';

const SimpleResourcesTable = ({model, pendingResourceIDs, resources, onToggleResourceVisibility, onSearch, onResShowDetails, onResShowNeighbourhood, checkboxIcon}) => {
        return (
            <div>
                {
                    resources.map((res, index) => {
                        return <SimpleResourceItem key={index}
                                             res={res}
                                             onToggleResourceVisibility={(resID) => onToggleResourceVisibility(resID)}
                                             checked={model.getResourceModel().getItemByID(res.id) ? true : false}
                                             pending={pendingResourceIDs && pendingResourceIDs.has(res.id)}
                                             pendingResourceIDs={pendingResourceIDs}
                                             model={model}
                                             onSearch={(expression) => onSearch(expression)}
                                             onResShowDetails={(id) => onResShowDetails(id)}
                                             onResShowNeighbourhood={(lon, lat) => onResShowNeighbourhood(lon, lat)}
                                             bgColor={index%2===0? "#EEE" : "white"}
                                             checkboxIcon={checkboxIcon}
                        />
                    })
                }
            </div>
        )
}

export default SimpleResourcesTable;
