import React, {useState} from "react";
import {getMap, getMapPreview} from "./mediaitem";

const OldMediaItem = ({mapDescriptor, preview, displayDummies, onClick, width, height, children}) => {
  const [lPreview, setLPreview] = useState(false);

  let previewButton;
  if (preview) {
    previewButton = getMapPreview(mapDescriptor, (e)=> {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});
  }

  //Alte Version, bei der es nur Kartendaten gibt
  return <>
    {previewButton}
    {(!preview || lPreview) && <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      zIndex: 0,
      width: '100%'
    }}>
      <div style={{width: '100%'}}>
        {getMap(mapDescriptor, width, height, displayDummies,
            onClick)}
      </div>
      {children}
    </div>}
  </>
}

export default OldMediaItem;
