const timelineConfig = {
  timelineHeaderColor : "#ECECEC",
  resourceOverlayInlineColor: "rgba(40,40,40,0.8)",
  resMainFont : "18px Roboto, sans-serif",
  resMainFontColor: "#FFF",
  resSubFontColor: "#AAA",
  timelineMainFontColor: "#555",
  timelineSubFontColor: "#AAA",
  currentDateOnMousePositionColor: "rgba(60,60,60,0.7)",
  currentDateOnMousePositionBorderColor: "#FFF",
  timelineHeaderMainTickColor: "#CCC",
  INLINE_RES_HEIGHT: 40,
  hideResourceHeaderIfOnlyOneRes: true
}


export default timelineConfig;
