const smoothScroll = (el, duration, context) => {
    let start = context.scrollTop;
    let end = el;

    let clock = Date.now();

    let easeInOutCubic = (t) => {
        return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }

    let position = (start, end, elapsed, duration) => {
        if (elapsed > duration) {
            return end;
        }
        return start + (end - start) * easeInOutCubic(elapsed / duration);
    }

    let requestAnimationFrame = window.requestAnimationFrame ||
        window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame ||
        function (fn) {
            window.setTimeout(fn, 15);
        };

    var step = function () {
        var elapsed = Date.now() - clock;
        context.scrollTop = position(start, end, elapsed, duration);

        if (elapsed <= duration) {
            requestAnimationFrame(step);
        }
    }
    step();
}

export default smoothScroll;
