import StoryDetailsBlock from "../../display/storydetailsblock";
import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import i18n from "../../../i18n/i18n";
import {IconButton} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as Germany} from "../../../images/icons/flags/germany.svg";
import {ReactComponent as UK} from "../../../images/icons/flags/uk.svg";
import {ReactComponent as Ukraine} from "../../../images/icons/flags/ukraine.svg";
import { ReactComponent as Italy } from "../../..//images/icons/flags/italy.svg";
import { ReactComponent as France } from "../../..//images/icons/flags/france.svg";
import LanguageMenu from "../../../menu/languagemenu";

const LanguageInput = ({language, onLanguageChanged, innerWidth, bgColor}) => {
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  return <StoryDetailsBlock name={i18n("languageinput.js593099979")} width={innerWidth} color={bgColor} help={'index.html#_sprache'}>
    {LanguageMenu(languageMenuAnchorEl,
        languageMenuOpen,
        () => setLanguageMenuOpen(false),
        language,
        onLanguageChanged
    )}
    <Paper elevation={5} style={{padding: 10, overflow: "hidden"}}>
      <IconButton onClick={(evt) => {
        setLanguageMenuAnchorEl(evt.currentTarget);
        setLanguageMenuOpen(true);
      }}>
        {(!language || language === '' || language === 'de') && <><SvgIcon viewBox="0 0 1000 500">
          <Germany/>
        </SvgIcon>&nbsp;{i18n("menu.js869701443")}</>}
        {language === 'en' &&  <><SvgIcon viewBox="0 0 1000 500">
          <UK/>
        </SvgIcon>&nbsp;{i18n("menu.js503793771")}</>}

         {language === 'it' && <><SvgIcon viewBox="0 0 1000 500">
          <Italy/>
        </SvgIcon>&nbsp;{i18n("menu.italy")}</>}

        {language === 'fr' && <><SvgIcon viewBox="0 0 1000 500">
          <France/>
        </SvgIcon>&nbsp;{i18n("menu.france")}</>}

        {language === 'ua' && <><SvgIcon viewBox="0 0 1000 500">
          <Ukraine/>
        </SvgIcon>&nbsp;{i18n("menu.js161223496")}</>}
      </IconButton>
    </Paper>
  </StoryDetailsBlock>
}

export default LanguageInput;
