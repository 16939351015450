import React from 'react';
import Relations from './relations';
import RelationList from './relationlist';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from "@material-ui/core/Paper";

class RelationsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "relations"
        }
    }

    render() {
        if((this.props.data.wdreferences && Object.keys(this.props.data.wdreferences).length > 0) || (this.props.data.refbylinktypes && this.props.data.refbylinktypes.length > 0)) {
            return <div style={{marginBottom: 10,  marginTop: 10, display: "flex", width: "100%" }}>
                    <div style={{width: "100%"}}>
                        <div position="static" style={{height: 60, width: "100%"}}>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={(event, newValue) => this.setState({selectedTab: newValue})}
                                indicatorColor="secondary"
                                textColor="secondary"
                            >
                                <Tab key={1}
                                     value={"relations"}
                                     label="Graph"
                                />
                                <Tab key={2}
                                     value={"list"}
                                     label="Liste"
                                />
                            </Tabs>
                        </div>
                        <Paper elevation={5} style={{width: "100%"}}>
                            {this.state.selectedTab === "list" &&
                            <RelationList {...this.props}/>
                            }
                            {this.state.selectedTab === "relations" &&
                            <Relations {...this.props}/>
                            }
                        </Paper>
                    </div>
            </div>
        } else {
            return null;
        }
    }
}

export default RelationsMain;
