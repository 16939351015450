import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import Paper from "@material-ui/core/Paper";
import GoogleMap from "../../media/lazygooglemap";
import MyButton from "../../ui-components/buttons/text/mybutton";
import {Typography} from "@material-ui/core";
import ImageUploadCrop from "../../ui-components/lazyimageuploadcrop";
import HistoSuspense from "../../histosuspense";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TransferHandler from "../../transfer/transferhandler";
import Config from "../../config/config";
import LocationOn from "@material-ui/icons/LocationOn";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import LayersIcon from "@material-ui/icons/Layers";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import i18n from "../../i18n/i18n";

const getNewItem = (title, mapCenter, mapZoom, mapItemObjectList, itemUuid) => {
    let item = {
        "type": "GoogleDynamicMap",
        "center": mapCenter,
        "zoom": mapZoom,
        "objects": mapItemObjectList,
        "title": title
    }
    return item;
}

const ChooseMapDialog = ({open, onOK, onCancel, item}) => {
    const [title, setTitle] = useState(item ? item.title : "");
    const [editMode, setEditMode] = useState("");
    const [objectListInternal, setObjectListInternal] = useState(item ? item.objects : []);
    const [imageBase64, setImageBase64] = useState("");
    const [origImageFilename, setOrigImageFilename] = useState("");
    const [imageLicense, setImageLicense] = useState("");
    const [sourcereference, setSourcereference] = useState("");
    const [markerName, setMarkerName] = useState("");
    const [googleMapsURLForMarker, setGoogleMapsURLForMarker] = useState("");
    const [map, setMap] = useState(null);
    const [mapVisible, setMapVisible] = useState(true);
    const [showControlPoints, setShowControlPoints] = useState(true);
    const [showGrid, setShowGrid] = useState(true);
    const [mapCenter, setMapCenter] = useState(item ? item.center : {
        "lat": 51.165,
        "lng": 10.455278,
    });
    const [zoomInternal, setZoomInternal] = useState(item ? item.zoom : 5);

    const itemUuid = item ? item.uuid : null;

    const reset = () => {
        setTitle("");
        setEditMode("");
        setObjectListInternal([]);
        setImageBase64("");
        setOrigImageFilename("");
        setImageLicense("");
        setSourcereference("");
        setMarkerName("");
        setGoogleMapsURLForMarker("");
        setMap(null);
        setMapVisible(true);
        setShowControlPoints(true);
        setShowGrid(true);
        setMapCenter({
            "lat": 51.165,
            "lng": 10.455278,
        });
    }

    const cancelClicked = () => {
        reset();
        onCancel();
    }

    const newOverlay = (origImageFilename, imageBase64) => {
        let bounds = map.getBounds();
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();

        //Wenn das letzte Element ein zu editierendes Overlay ist
        let objectList = objectListInternal.slice();
        let lastElem = objectList.pop();
        if (!lastElem || lastElem.type !== "overlay" || !lastElem.isEditing) {
            if(lastElem) {
                objectList.push(lastElem);
            }
            let marginH = (ne.lat() - sw.lat()) / 3;
            let marginW = (ne.lat() - sw.lat()) / 3;
            let north = ne.lat() - marginH;
            let south = sw.lat() + marginH;
            let west = sw.lng() + marginW;
            let east = ne.lng() - marginW;
            lastElem = ({
                "isEditing": true,
                "isBase64EncodedURL" : true,
                "type": "overlay",
                "north": north,
                "east": east,
                "south": south,
                "west": west,
                "license": ""
            });
        }
        lastElem.url = imageBase64;
        objectList.push(lastElem);

        setObjectListInternal(objectList);
        setOrigImageFilename(origImageFilename);
        setImageBase64(imageBase64);
        setImageLicense(origImageFilename !== "" ? imageLicense : "");
    }

    const okPressed = () => {
        end(() => {
            onOK(getNewItem(title, mapCenter, zoomInternal, objectListInternal, itemUuid));
            reset();
        });
    }

    const mapClicked = (lat, lng) => {
        let objectList = objectListInternal.slice();
        switch (editMode) {
            case "marker":
                newMarker(lat, lng);
                break;
            case "arrow":
                //Wenn das letzte Element ein zu editierender Pfeil ist
                let lastElem = objectList.pop();
                if (!lastElem || lastElem.type !== "arrow" || !lastElem.isEditing) {
                    if(lastElem) {
                        objectList.push(lastElem);
                    }
                    lastElem = {
                        "isEditing": true,
                        "type": "arrow",
                        "path": []
                    }
                }
                lastElem.path = lastElem.path.slice();
                lastElem.path.push({
                    lat: lat,
                    lng: lng
                });

                objectList.push(lastElem);
                setObjectListInternal(objectList);
                break;
            default:
                break;
        }
    }

    const newMarker = (lat, lng) => {
        let objectList = objectListInternal.slice();
        objectList.push(
            {
                type: "marker",
                title: markerName.trim().length > 0 ? markerName : i18n('chooseMapDialog.selectedPosition'),
                lat: lat,
                lng: lng
            }
        );
        setEditMode("");
        setObjectListInternal(objectList);
        setMarkerName("");
        setGoogleMapsURLForMarker("");
    }

    const newMarkerFromURL = () => {
        //Bestimmen von lat und lng -> steht hinter dem @
        let atIndex = googleMapsURLForMarker.indexOf("@");
        if(atIndex > 0) {
            let firstComma = googleMapsURLForMarker.indexOf(",", atIndex);
            if(firstComma>0) {
                let secondComma = googleMapsURLForMarker.indexOf(",", firstComma+1);
                if(secondComma > 0) {
                    let lat = googleMapsURLForMarker.substring(atIndex+1, firstComma);
                    let lng = googleMapsURLForMarker.substring(firstComma+1, secondComma);

                    newMarker(lat +"", lng +"");
                }
            }
        }

    }

    const saveImage = (imageBase64, imageName, callback) => {
        //Save the image
        let onSuccess = function (json) {
            callback && callback(json.url);
        };

        let onError = function (code, json) {
            if (code === 999) {
                //sessionIsInvalid();
            }
            callback && callback();
        }

        TransferHandler.request(Config.getSaveBase46ImageURL(), onSuccess, onError, {imageBase64, imageName});
    }

    const end = (callback) => {
        let objectList = objectListInternal.slice();
        if(objectList.length > 0) {
            let lastElem = objectList[objectList.length - 1];
            if (lastElem) {
                delete (lastElem.isEditing);

                //letztes Element ist Overlay? -> Speichern des Bildes und der Lizenz und ersetzen der URL
                if(lastElem.type === "overlay" && lastElem.isBase64EncodedURL) {
                    lastElem.license = sourcereference;

                    saveImage(imageBase64, origImageFilename, (url) => {
                        if(url) {
                            //Wenn eine URL zurückgeliefert wird, dann ist alles okay
                            lastElem.url = url;
                            lastElem.isBase64EncodedURL = false;
                        } else {
                            //Keine URL? Das Bild konnte nicht gespeichert werden. Das letzte Element muss entfernt werden
                            objectList.pop();
                        }
                        setObjectListInternal(objectList);
                        setEditMode("", callback && callback());
                    });
                    return;
                }
            }

            setObjectListInternal(objectList);
            setEditMode("", callback && callback());
        } else {
            setObjectListInternal(objectList);
            setEditMode("", callback && callback());
        }
    }

    const getComponentForItem = (item, i) => {
        if (item) {
            let icon;
            switch(item.type) {
                case "marker": icon = <span><LocationOn/> {i18n('chooseMapDialog.marker')}</span>; break;
                case "arrow": icon = <span><CallMissedOutgoingIcon/> {i18n('chooseMapDialog.arrow')}</span>; break;
                case "overlay": icon = <span><LayersIcon/> {i18n('chooseMapDialog.overlay')}</span>; break;
                default:
            }

            return <TableRow key={i}>
                <TableCell>{icon}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell style={{maxWidth: 50}}><Button onClick={() => deleteItem(i)}><DeleteIcon/></Button></TableCell>
            </TableRow>
        } else {
            return <div/>
        }
    }

    const deleteItem = (index) => {
        let objectList = objectListInternal.slice();
        objectList.splice(index, 1);
        setObjectListInternal(objectList);
    }

    const cancelEditItem = () => {
        if(objectListInternal.length > 0) {
            let objectList = objectListInternal.slice();
            let lastElem = objectList[objectList.length - 1];
            if(lastElem.isEditing) {
                objectList.pop();
            }
            setObjectListInternal(objectList);
            setEditMode("");
        } else {
            setEditMode("");
        }
    }

    return <Dialog
        maxWidth={false}
        open={open}
        onClose={cancelClicked}
    >
        <DialogContent>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label={i18n("chooseMapDialog.js901660970")}
                           value={title}
                           onChange={(evt) => setTitle(evt.target.value)}
                           inputProps={{style: {width: "100%", fontWeight: 'bold', fontSize: 20}}}
                />
                <br/>
                <div>
                    <Paper style={{
                        overflow: "hidden",
                        marginBottom: 20,
                        border: "1px solid gray",
                        position: "relative"
                    }}
                           elevation={5} className={"mediaworkaround"}>
                        {mapVisible ? <GoogleMap id="Map"
                                                            initialCenter={mapCenter}
                                                            center={mapCenter}
                                                            zoom={zoomInternal}
                                                            height={600}
                                                            width={800}
                                                            onClick={(mapProps, map, clickevent) => clickevent && clickevent.latLng && mapClicked(clickevent.latLng.lat(), clickevent.latLng.lng())}
                                                            onDragend={(mapProps, map, coord) => setMapCenter({lat: map.center.lat(), lng: map.center.lng()})}
                                                            onZoom={(level) => setZoomInternal(level)}
                                                            onReady={(p, map) => {
                                                                setMap(map);
                                                            }}
                                                            visible={true}
                                                            showGrid = {showGrid}
                                                            showControlPoints = {showControlPoints}
                                                            onShowControlPoints = {(b)=>setShowControlPoints(b)}
                                                            onShowGrid = {(b)=>setShowGrid(b)}
                                                            onMapDescChanged = {def=>setObjectListInternal(def)}
                                                            onShowGridControl = {true}
                                                            onShowControlPointsControl = {true}
                            >
                                {objectListInternal}
                            </GoogleMap>
                            : <div style={{width: 800, height:600}}></div>}
                    </Paper>
                </div>

                <div style={{display: "flex"}}>
                    <MyButton onClick={() => setEditMode("marker")} disabled={editMode !== ""} style={{marginRight: 10}}>
                         {i18n('chooseMapDialog.newmarker')}
                    </MyButton>
                    <MyButton onClick={() => setEditMode("arrow")} disabled={editMode !== ""} style={{marginRight: 10}}>
                         {i18n('chooseMapDialog.newarrow')}
                    </MyButton>
                    <MyButton onClick={() => setEditMode("overlay")} disabled={editMode !== ""} style={{marginRight: 10}}>
                         {i18n('chooseMapDialog.newoverlay')}
                    </MyButton>
                </div>

                {editMode === 'marker' &&
                <Paper style={{padding: 10, marginTop: 20, background: "#fffce8", display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <Typography>
                            {i18n("chooseMapDialog.js720327007")}
                            <br/>
                            {i18n("chooseMapDialog.js760709110")}
                        </Typography>
                        <TextField id="markerName"
                                   label={i18n("chooseMapDialog.js589922616")}
                                   value={markerName}
                                   onChange={(evt) => setMarkerName(evt.target.value)}
                                   fullWidth={true}
                        />
                        <TextField id="googleMapsURLForMarker"
                                   label={i18n("chooseMapDialog.js87695793")}
                                   value={googleMapsURLForMarker}
                                   onChange={(evt) =>setGoogleMapsURLForMarker(evt.target.value)}
                                   fullWidth={true}
                        />
                    </div>
                    <div>
                        <CancelButton  onClick={()=>cancelEditItem()}/>
                        <OKButton onClick={()=>newMarkerFromURL()}/>
                    </div>
                </Paper>}
                {editMode === 'arrow' &&
                <Paper style={{padding: 10, marginTop: 20, background: "#fffce8"}}>{i18n("chooseMapDialog.click")} &nbsp;<CancelButton onClick={()=>cancelEditItem()}/>&nbsp;{i18n("chooseMapDialog.or")}&nbsp;<MyButton onClick={() => end()}>{i18n("chooseMapDialog.takeover")}</MyButton></Paper>}
                {editMode === 'overlay' && <Paper style={{padding: 10, marginTop: 20, background: "#fffce8"}}>
                    <b>{i18n('chooseMapDialog.overlayStep1')}</b> {i18n('chooseMapDialog.uploadImage')}
                    <br/>
                    <br/>
                    <ImageUploadCrop generatedFilename={""}
                                     origImageFilename={""}
                                     imageBase64={imageBase64}
                                     onFileChange={(origImageFilename, imageBase64) => newOverlay(origImageFilename, imageBase64)}/>
                    <br/>
                    <br/>
                    <b>{i18n("chooseMapDialog.js417538297")}</b> {i18n("chooseMapDialog.js388797867")}
                    <br/>
                    <div style={{marginLeft: 25, marginRight: 25}}>
                        <HistoSuspense>
                            <TextField label={i18n("chooseMapDialog.js10384243")}
                                       value={sourcereference}
                                       onChange={(evt) => setSourcereference(evt.target.value)}
                                       fullWidth={true}
                            />
                        </HistoSuspense>
                    </div>
                    <br/>
                    <br/>
                    <b>{i18n("chooseMapDialog.js561133791")}</b> {i18n("chooseMapDialog.js842057153")}<br/>{i18n('chooseMapDialog.pointEndInstruction')}
                    <br/>
                    <br/>
                    {i18n("chooseMapDialog.js982829124")}&nbsp;<CancelButton onClick={()=>cancelEditItem()}/> oder <MyButton
                    onClick={() => end()}>{i18n("chooseMapDialog.js61491380")}</MyButton>
                    <br/>
                </Paper>}

                <Table style={{width: "100%"}}>
                    <TableBody>
                        {
                            objectListInternal.map((item, i) => {
                                return getComponentForItem(item, i);
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={cancelClicked}/>
            <OKButton onClick={() => okPressed()} color="primary" autoFocus/>
        </DialogActions>
    </Dialog>
}

export default ChooseMapDialog;
