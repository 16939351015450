import StoryDetailsBlock from "../../display/storydetailsblock";
import Hint from "../../../ui-components/hint";
import React from "react";
import i18n from "../../../i18n/i18n";

const NewStoryHint = ({innerWidth, bgColor}) => {
  return <StoryDetailsBlock width={innerWidth} color={bgColor}>
      <Hint
          type={"info"}
          header={i18n("newstoryhint.js799008560")}
          text={i18n("newstoryhint.js223326167")()}
      />
    </StoryDetailsBlock>
}

export default NewStoryHint;
