import React from "react";
import createToolbarButton from "../../createToolbarButton";
import TextAlignmentPicker from "./TextAlignmentPicker";
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

const Button = createToolbarButton({children: <FormatAlignJustifyIcon/>});

const TextAlignmentButton = ({onClick, theme, onOverrideContent, plugin}) => (
  <Button onClick={()=>onOverrideContent(TextAlignmentPicker(plugin))} theme={theme}/>
)

export default TextAlignmentButton;
