import {Task} from 'react-canvas-timeline';

/**
 * Ein Ereignis
 */
class BookingItem extends Task {
    constructor(id, start, end, resID, name = "", secname = "", changeuser = "", changeUserImg, changetime, imgSrc, wikiimg, wikiimglicense, innerEvents, description, mapDescriptor, imagelicense, sourcereference, wikipage, dataset) {
        super(id, start, end, resID, name, secname, innerEvents);

        this.changeuser = changeuser;
        this.changeUserImg = changeUserImg;
        this.changetime = changetime;
        this.imgSrc = imgSrc;
        this.wikiimg = wikiimg;
        this.wikiimglicense = wikiimglicense;
        this.description = description;
        this.mapDescriptor = mapDescriptor;
        this.imagelicense = imagelicense;
        this.sourcereference = sourcereference;
        this.wikipage = wikipage;
        this.dataset = dataset;
    }

    clone() {
        let bookingItem = new BookingItem(this.id, this.start ? this.start.clone() : null, this.end ? this.end.clone() : null, this.resID, this.name, this.secname, this.changeuser, this.changeUserImg, this.changetime, this.imgSrc, this.wikiimg, this.wikiimglicense, this.innerEvents, this.description, this.mapDescriptor, this.imagelicense, this.sourcereference, this.wikipage, this.dataset);
        bookingItem.setDisplayData(this.getDisplayData().clone());
        bookingItem.deleted = this.deleted;
        bookingItem.connections = this.connections;
        return bookingItem;
    }

    getChangeUserName() {
        return this.changeuser;
    }

    getChangeUserImg() {
        return this.changeUserImg;
    }

    getChangeTime() {
        return this.changetime;
    }

    getImgSrc() {
        return this.imgSrc;
    }

    setImgSrc(s) {
        this.imgSrc = s;
    }

    setDescription(desc) {
        this.description = desc;
    }

    getDescription() {
        return this.description;
    }

    setMapDescriptor(desc) {
        this.mapDescriptor = desc;
    }

    setImageLicense(d) {
        this.imagelicense = d;
    }

    setSourceReference(d) {
        this.sourcereference = d;
    }

    setWikiPage(d) {
        this.wikipage = d;
    }

    getMapDescriptor() {
        return this.mapDescriptor;
    }

    getImageLicense() {
        return this.imagelicense;
    }

    getSourceReference() {
        return this.sourcereference;
    }

    getWikiPage() {
        return this.wikipage;
    }

    setDataset(d) {
        this.dataset = d;
    }

    getDataset() {
        return this.dataset;
    }
}

export default BookingItem;
