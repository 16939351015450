import {histomania_sessionid} from "../histomaniamain";
import i18n from "../i18n/i18n";

//Führt einen JSON-Request, gibt die sessionid mit und führt die successFunction aus, der das json-Ergebnis mitgegeben wird
class TransferHandler {

    static request(url, successFunction, errorFunc, transferObj) {
        if (!transferObj) {
            transferObj = {};
        }

        //Falls eine Session-ID vorhanden ist, dann wird dem Request eine hinzugefügt, ansonsten nicht (z.B. bei Anmeldung)
        if (!transferObj.sessionid) {
            if (histomania_sessionid) {
                transferObj.sessionid = histomania_sessionid;
            }
        }

        let headers = new Headers({
            "Content-Type": "application/json"
        });

        let request = new Request(url, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(transferObj)
        });


        fetch(request).then(function (response) {
            return response.json();
        }).then(function (json) {
            //Kommt ein Fehler zurück, dann zunächst das Standard-Fehlerhandling ausführen falls keine Fehlerfunktion mitgegeben wurde
            if (json.retval === "NOK") {
                if (errorFunc) {
                    errorFunc(parseInt(json.code), json);
                }
            } else if (successFunction) {
                successFunction(json);
            }
        }).catch(function (ex) {
            console.log("Es ist ein Fehler beim Aufruf von " + url + " aufgetreten:");
            console.log(ex);
            if (errorFunc) {
                errorFunc(500); //Es kam etwas zurück, das kein JSON ist
            }
        });
    }

    static postForm(url, successFunction, errorFunc, form) {
        let request = new Request(url, {
            method: 'post',
            body: new FormData(form)
        });

        fetch(request).then(function (response) {
            return response.json();
        }).then(function (json) {
            //Kommt ein Fehler zurück, dann zunächst das Standard-Fehlerhandling ausführen falls keine Fehlerfunktion mitgegeben wurde
            if (json.retval === "NOK") {
                if (errorFunc) {
                    errorFunc(parseInt(json.code), json);
                }
            } else if (successFunction) {
                successFunction(json);
            }
        }).catch(function (ex) {
            console.log("Es ist ein Fehler beim Aufruf von " + url + " aufgetreten:");
            console.log(ex);
            if (errorFunc) {
                errorFunc(500); //Es kam etwas zurück, das kein JSON ist
            }
        });
    }

    static getDialogInfo(errorCode) {
        switch (errorCode) {
            case 0:
                return {dialogTitle: "OK", dialogContent: "OK"}; //Sollte nie angefragt werden
            case 500:
                return {
                    dialogTitle: i18n("error"),
                    dialogContent: i18n("internalServerError")()
                };
            case 901:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("userNotAcknowledged")()
                };
            case 902:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("userOrPwdNotInSystem")()
                };
            case 903:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("oldPasswordUnknown")()
                };

            case 910:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("pwdGuideline")()
                };
            case 911:
                return {
                    dialogTitle: i18n("error"),
                    dialogContent: i18n("noDataRefresh")(),
                }
            case 920:
                return {
                    dialogTitle: i18n("error"),
                    dialogContent:i18n("emailAlreadyAvailable")()
                };
            case 921:
                return {
                    dialogTitle: i18n("error"),
                    dialogContent: i18n("userNotCreated")()
                };
            case 990:
                return {
                    dialogTitle: i18n("error"),
                    dialogContent: i18n("reCaptchaError")()
                };
            case 998:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("notPlausible")()
                };
            case 999:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("sessionInvalid")(),
                };
            default:
                return {
                    dialogTitle:  i18n("error"),
                    dialogContent: i18n("unknownError")(errorCode)
                };
        }
    }

}

export default TransferHandler;
