import React from 'react';
import Typography from '@material-ui/core/Typography';

const BookingRightCard = ({member, onClick, style}) => {
    const newstyle={...{marginTop: 0, marginBottom: 5, cursor: "pointer"}, ...style}

    return <div style={newstyle}
                onClick={onClick}>
        <div style={{display: "flex", alignItems: "center", padding: 5}}>
            <Typography variant="body1">
                <b>{member.forename + " " + member.surname}</b>
            </Typography>
            &nbsp;
            <Typography variant="body1">
                {member.email}
            </Typography>
        </div>
    </div>
}

export default BookingRightCard;
