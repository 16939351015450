import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core"; // MUI V4 Imports
import i18n from "../i18n/i18n";

const AddressForm = ({
                         isEditable,
                         isSchool,
                         company,
                         onCompanyChange,
                         taxnumber,
                         onTaxnumberChange,
                         prename,
                         onPrenameChange,
                         surname,
                         onSurnameChange,
                         street,
                         onStreetChange,
                         streetnumber,
                         onStreetnumberChange,
                         zipCode,
                         onZipcodeChange,
                         town,
                         onTownChange,
                         country,
                         onCountryChange,
                         email,
                         onEmailChange,
                         onValidation
                     }) => {

    const [errors, setErrors] = useState({
        company: "",
        taxnumber: "",
        prename: "",
        surname: "",
        street: "",
        streetnumber: "",
        zipCode: "",
        town: "",
        country: "",
        email: ""
    });

    // Plausibilitätsprüfungen
    const validateCompany = (value) => isSchool ? (value.trim().length > 0 ? "" : i18n('addressForm.schoolEmptyError')) : "";
    const validatePrename = (value) => value.trim().length > 0 ? "" : i18n('addressForm.prenameEmptyError');
    const validateSurname = (value) => value.trim().length > 0 ? "" : i18n('addressForm.surnameEmptyError');
    const validateStreet = (value) => value.trim().length > 0 ? "" : i18n('addressForm.streetEmptyError');
    const validateStreetnumber = (value) => value.trim().length > 0 ? "" : i18n('addressForm.streetNumberEmptyError');
    const validateZipCode = (value) => value.match(/^\d{5}$/) ? "" : i18n('addressForm.zipCodeInvalidError');
    const validateTown = (value) => value.trim().length > 0 ? "" : i18n('addressForm.townEmptyError');
    const validateCountry = (value) => value.trim().length > 0 ? "" : i18n('addressForm.countryEmptyError');
    const validateEmail = (value) => value.match(/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/) ? "" : i18n('addressForm.emailInvalidError');

    // Funktion zur Überprüfung aller Felder
    const validateAllFields = () => {
        const validationResults = {
            company: validateCompany(company),
            prename: validatePrename(prename),
            surname: validateSurname(surname),
            street: validateStreet(street),
            streetnumber: validateStreetnumber(streetnumber),
            zipCode: validateZipCode(zipCode),
            town: validateTown(town),
            country: validateCountry(country),
            email: validateEmail(email)
        };
        setErrors(validationResults);
        const isValid = Object.values(validationResults).every(error => error === "");
        onValidation && onValidation(isValid);
    };

    // Validierung bei jedem Update des Formulars
    useEffect(() => {
        isEditable && validateAllFields();
    }, [company, taxnumber, prename, surname, street, streetnumber, zipCode, town, country, email]);

    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {isEditable ? (
                        <FormControl fullWidth error={!!errors.country}>
                            <InputLabel>{i18n('addressForm.countryLabel')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => onCountryChange(e.target.value)}
                            >
                                <MenuItem value="DEU">{i18n('addressForm.germany')}</MenuItem>
                                <MenuItem value="AUT">{i18n('addressForm.austria')}</MenuItem>
                                <MenuItem value="CHE">{i18n('addressForm.switzerland')}</MenuItem>
                            </Select>
                            {errors.country && <p style={{ color: "red" }}>{errors.country}</p>}
                        </FormControl>
                    ) : country}
                </Grid>
                {country !== "DEU" && <Grid item xs={12}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.taxNumberLabel')}
                            value={taxnumber}
                            onChange={(e) => onTaxnumberChange(e.target.value)}
                            helperText={i18n("addressForm.taxNumberHelper")}
                        />
                    ) : company}
                </Grid>}
                <Grid item xs={12}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={isSchool ? i18n('addressForm.schoolOrOrgLabel') : i18n('addressForm.companyLabel')}
                            value={company}
                            onChange={(e) => onCompanyChange(e.target.value)}
                            error={!!errors.company}
                            helperText={errors.company}
                        />
                    ) : company}
                </Grid>
                <Grid item xs={6}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.prenameLabel')}
                            value={prename}
                            onChange={(e) => onPrenameChange(e.target.value)}
                            error={!!errors.prename}
                            helperText={errors.prename}
                        />
                    ) : prename}
                </Grid>
                <Grid item xs={6}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.surnameLabel')}
                            value={surname}
                            onChange={(e) => onSurnameChange(e.target.value)}
                            error={!!errors.surname}
                            helperText={errors.surname}
                        />
                    ) : surname}
                </Grid>
                <Grid item xs={9}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.streetLabel')}
                            value={street}
                            onChange={(e) => onStreetChange(e.target.value)}
                            error={!!errors.street}
                            helperText={errors.street}
                        />
                    ) : street}
                </Grid>
                <Grid item xs={3}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.streetNumberLabel')}
                            value={streetnumber}
                            onChange={(e) => onStreetnumberChange(e.target.value)}
                            error={!!errors.streetnumber}
                            helperText={errors.streetnumber}
                        />
                    ) : streetnumber}
                </Grid>
                <Grid item xs={3}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.zipCodeLabel')}
                            value={zipCode}
                            onChange={(e) => onZipcodeChange(e.target.value)}
                            error={!!errors.zipCode}
                            helperText={errors.zipCode}
                        />
                    ) : zipCode}
                </Grid>
                <Grid item xs={9}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.townLabel')}
                            value={town}
                            onChange={(e) => onTownChange(e.target.value)}
                            error={!!errors.town}
                            helperText={errors.town}
                        />
                    ) : town}
                </Grid>
                <Grid item xs={12}>
                    {isEditable ? (
                        <TextField
                            fullWidth
                            label={i18n('addressForm.emailLabel')}
                            value={email}
                            onChange={(e) => onEmailChange(e.target.value)}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    ) : email}
                </Grid>
            </Grid>
        </form>
    );
};

export default AddressForm;
