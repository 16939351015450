import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import QuizEditor from "../../media/quizeditor/lazyquizeditor";
import validate from "../../media/quizeditor/validate";
import QuizErrorDialog from "../../markdown/quizErrorDialog";
import EditorUtils from "../utils/EditorUtils";

const processResult = (item) => {
    //Nachbearbeitung des Ergebnisses
    //"nrOfQuestions" setzen
    //answerSelectionType bestimmen
    let itemCopy = JSON.parse(JSON.stringify(item));

    itemCopy.quizSynopsis = item.quizSynopsis;
    itemCopy.type = "Quiz";
    itemCopy.title = item.title;
    itemCopy.nrOfQuestions = itemCopy.questions ? ""+itemCopy.questions.length : "0";

    if(itemCopy.questions) {
        for (let n=0; n<itemCopy.questions.length; n++) {
            let question = itemCopy.questions[n];
            let origQuestion = item.questions[n];

            question.question = origQuestion.question;
            question.explanation = origQuestion.explanation;

            let correctAnswers = [];
            if(question.answers) {
                for (let n=0; n<question.answers.length; n++) {
                    let answer = question.answers[n];
                    if(answer.isCorrect) {
                        correctAnswers.push(n+1);
                    }
                    question.answers[n] = answer.description;
                }
            }
            question.answerSelectionType = correctAnswers.length>1 ? 'multiple' : 'single';
            question.correctAnswer = correctAnswers.length === 1 ? ""+correctAnswers[0] : correctAnswers ;
        }
    }

    return itemCopy;
}

const preprocessItem = (item) => {
    if(item) {
        //Vorbereiten der correctAnswers
        let itemCopy = JSON.parse(JSON.stringify(item));

        if (itemCopy.questions) {
            for (let question of itemCopy.questions) {
                question.questionKey = question.questionKey || Math.random();
                question.questionEditorState = EditorUtils.toEditorState(question.question);
                question.explanationEditorState = EditorUtils.toEditorState(question.explanation);

                let correctAnswers = [];
                if (question.correctAnswer) {
                    if (Array.isArray(question.correctAnswer)) {
                        correctAnswers = question.correctAnswer;
                    } else {
                        correctAnswers.push(question.correctAnswer);
                    }
                }
                if (question.answers) {
                    for (let n = 0; n < question.answers.length; n++) {
                        let answer = question.answers[n];
                        question.answers[n] = {
                            answer,
                            isCorrect: correctAnswers.includes(""+(n + 1)) || correctAnswers.includes(n + 1)
                        }
                    }
                }
            }
        }

        return itemCopy;
    }
    return {};
}

const ChooseQuizDialog = ({open, onOK, onCancel, item, model}) => {
    const [errors, setErrors] = useState(null);

    let collectData;

    const cancelClicked = () => {
        onCancel();
    }

    const okClicked = () => {
        const item = collectData();
        const result = processResult(item);
        const err = validate(result);
        if (!err) {
            onOK(result);
        } else {
            setErrors(err);
        }
    }

    return <Dialog open={open}
                   onClose={onCancel}
                   fullWidth={true}
                   maxWidth={"md"}
                   PaperProps={{style: {background: 'lightgray'}}}
    >
        <DialogTitle>Quiz</DialogTitle>
        <DialogContent style={{overflow: "scroll"}}>
            <QuizEditor dataCollectorCallBack={(c)=>collectData=c}
                        onOK={(values) => {
                                const result = processResult(values);
                                const err = validate(result);
                                if (!err) {
                                    onOK(result);
                                } else {
                                    setErrors(err);
                                }
                            }
                        }
                        item={preprocessItem(item)}
                        model={model}
            />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
        <QuizErrorDialog errors={errors} onOK={()=>setErrors(null)} open={!!errors}/>
    </Dialog>

}

export default ChooseQuizDialog;
