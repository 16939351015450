import React from 'react';
import Config from '../config/config.js';
import StoryCheckbox from '../styling/storycheckbox';
import Typography from '@material-ui/core/Typography';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RoomIcon from '@material-ui/icons/Room';
import HtmlTooltip from "../styling/htmltooltip";
import {Set} from 'immutable';
import './markdown.scss';
import Filter from "../images/icons/filter";
import ZoomableImage from "../ui-components/zoomableimage";
import Media from "../media/media";
import parseMapDescriptor from "./parseMapDescriptor";
import YouTube from "../media/youtube";
import StreetView from "../media/streetview";
import Paper from "@material-ui/core/Paper";
import i18n from "../i18n/i18n";

const transformLinkUri = (link) => {
  return link;
}

const jumpToMarkdownDoc = () => {
  window.open(Config.getMarkdownDocURL(), '_blank');
}

const getText = (a) => {
  return a.children && a.children.length > 0 && a.children[0];
}

const isString = (obj) => {
  return (Object.prototype.toString.call(obj) === '[object String]');
}

const getNewChild = (child, index) => {
  let str = null;
  if(isString(child)) {
    str = child;
  } else if (Array.isArray(child) && child.length === 1 && isString(child[0])) {
    str = child[0];
  } else {
    if(!child.key) {
      child.key = index;
    }
    return child;
  }
  const arr = str.split("\n");
  const retVal = arr.map((t, i) => <React.Fragment key={i}>{t}{i < arr.length - 1 && <br/>}</React.Fragment>);
  return <span key={index}>{retVal}</span>
}

/**
 * Wenn es nur ein child im Paragraph gibt, und es sich dabei um ein Image handelt, dann wird dieses gewrapped
 * @param elt
 * @returns {JSX.Element|{key}|*}
 */
const wrapImage = (childs) => {
  if(childs && childs.length===1 && childs[0].key && childs[0].key.startsWith("img-")) {
    return <ZoomableImage src={childs[0].props.src} alt={childs[0].props.alt} title={childs[0].props.title} key={childs[0].key}/>;
  } else {
    return childs;
  }
}

const renderers = (getItemByID, onToggleResourceVisibility, onHighlight,
    onShowMap, pendingResourceIDs, model, mapDescriptor, noLinks = false, width) => {
  return {
    a: (a) => {
      if (noLinks) {
        const txt = getText(a);
        if(txt) {
          return txt;
        } else {
          return <span></span>
        }
      }
      let storyID = parseInt(a.href);
      if (isNaN(storyID)) {
        if (a.href.startsWith("mediaid=")) {
          let mediaid = a.href.substring(8).split(",");
          //Gibt es diese ID im mapDescriptor?
          //Falls ja, dann gib ein neues Media-Objekt zurück, wo der Teil des Mapdescriptors verwendet wird
          if(mapDescriptor) {
            try {
              let mapDesc = parseMapDescriptor(mapDescriptor);
              if(mapDesc.items) {
                //mapDesc.items.forEach(item => console.log(item.id));
                let item = mapDesc.items.find(item => ""+item.uuid === ""+mediaid);
                if(item) {
                  let newMapDesc = {
                    items: [item]
                  }
                  return <Media mapDescriptor={JSON.stringify(newMapDesc)} width={"100%"} onToggleResourceVisibility={onToggleResourceVisibility} onHighlight={onHighlight} onShowMap={onShowMap} pendingResourceIDs={pendingResourceIDs} model={model}/>
                } else {
                  return <span style={{border: "1px dashed gray"}}>{i18n("markdownhelper.js160663836")}</span>
                }
              }
            } catch(ex) {
              console.log(ex);
            }
          }
        } else if (a.href.startsWith("youtubeid=")) {
          let youtubeid = a.href.substring(10).split(",");
          return <YouTube youtubeid={youtubeid}/>
        } else if (a.href.startsWith("streetviewid=")) {
          let streetviewid = a.href.substring(13).split(",");
          return     <StreetView
              streetviewId={streetviewid}
              height={300}
              style={{
                width: "100%",
                marginTop: 10,
                marginBottom: 10,
                maxWidth: 600,
                border: "1px solid gray"
              }}
          />
        } else if (a.href.startsWith("filter=")) {
          const idArr = Set(a.href.substring(7).split(",")).filter(
              idStr => idStr != null && idStr.trim().length !== 0).map(
              idStr => idStr * 1);
          const filteredIDs = model.getAllBaseData().filter(
              t => !idArr.contains(t.proxyID || t.id)).map(t => t.id); //Proxy-ID nicht bei map berücksichtigen, da die Filterung im Model nur mit der ID arbeiten

          return <HtmlTooltip arrow title={<>
            <Typography color="inherit">{i18n("markdownhelper.js744897963")}</Typography>
            <hr/>
            {i18n("markdownhelper.js904359583")}
          </>}><span onClick={(e) => {
            model.setFilteredIDs(Set(filteredIDs))
          }} className={"scheduleSpan"}>{getText(a)}<Filter
              className={"markdownIcon"}/></span></HtmlTooltip>
        } else if (a.href.startsWith("s")) {
          storyID = parseInt(a.href.substring(1));
          if (!isNaN(storyID)) {
            return <HtmlTooltip arrow title={<>
              <Typography color="inherit">{i18n("markdownhelper.js133555219")}</Typography>
              <hr/>
              {i18n("markdownhelper.js499018470")}
            </>}><span onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onHighlight(storyID)
            }} className={"scheduleSpan"}>{getText(a)}<ScheduleIcon
                className={"markdownIcon"}/></span></HtmlTooltip>
          }
        } else if (a.href.startsWith("m")) {
          let latLng = a.href.substring(1).split(",");
          if (latLng.length === 2) {
            return <HtmlTooltip arrow title={<>
              <Typography color="inherit">{i18n("markdownhelper.js297030165")}</Typography>
              <hr/>
              {i18n("markdownhelper.js743956038")}
            </>}><span onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onShowMap(latLng[0], latLng[1])
            }} className={"mapSpan"}><RoomIcon
                className={"markdownIcon"}/> {getText(a)}</span></HtmlTooltip>
          }
        }

        return <HtmlTooltip arrow title={<>
          <Typography color="inherit">{i18n("markdownhelper.js702490738")}</Typography>
          <hr/>
          {i18n("markdownhelper.js813604085")}
        </>}><a className={"aHrefNewTab"} href={a.href} target="_blank"
                rel="noopener noreferrer">{getText(a)}</a></HtmlTooltip>
      } else {
        const isPending = pendingResourceIDs && pendingResourceIDs.includes(
            storyID);
        const story = getItemByID(storyID);
        return <StoryCheckbox disabled={isPending}
                              checked={!!story || isPending} onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onToggleResourceVisibility(storyID)
        }} className={"scheduleSpan"} iconClassName={"markdownIcon"}>{getText(
            a)}</StoryCheckbox>
      }
    },
    ol: (l) => {
      if (l.ordered) {
        return  <ol className={"markdownList"}>{l.children}</ol>
      } else {
        return <ul className={"markdownList"}>{l.children}</ul>
      }
    },
    ul: (l) => {
      if (l.ordered) {
        return <ol className={"markdownList"}>{l.children}</ol>
      } else {
        return <ul className={"markdownList"}>{l.children}</ul>
      }
    },
    li: (li) => {
      return <li key={li.index} className={"markdownListItem"}>{li.children}</li>
    },
    h1: (h) => {
      return <Typography variant={'h1'}>{h.children}</Typography>
    },
    h2: (h) => {
      return <Typography variant={'h2'}>{h.children}</Typography>
    },
    h3: (h) => {
      return <Typography variant={'h3'} style={{
        fontSize: 18,
        lineHeight: 2,
        marginTop: 10
      }}>{h.children}</Typography>
    },
    h4: (h) => {
      return <Typography variant={'h4'} style={{
        fontSize: 14,
        lineHeight: 2,
        marginTop: 10
      }}>{h.children}</Typography>
    },
    h5: (h) => {
      return <Typography variant={'h5'} style={{
        fontSize: 12,
        lineHeight: 2,
        marginTop: 10
      }}>{h.children}</Typography>
    },
    h6: (h) => {
      return <Typography variant={'h6'} style={{
        fontSize: 10,
        lineHeight: 2,
        marginTop: 10
      }}>{h.children}</Typography>
    },
    blockquote: (t) => {
      return <div className={"blockquote"}>{t.children}</div>
    },
    table: (t) => {
      let style = width && width > 1000 ? {
        float: 'right',
        width: '40%',
        marginLeft: 30
      } : {};
      return <Paper elevation={5} style={{margin: 5, ...style}}><table className={"markdownTable"}>{t.children}</table></Paper>
    },
    thead: (th) => {
      return <thead>{th.children}</thead>
    },
    tbody: (tb) => {
      return <tbody>{tb.children}</tbody>
    },
    th: (tc) => {
      if(!tc.children || (tc.children.length === 1 && tc.children[0].trim().length === 0)) {
        return <></>;
      }
      if (tc.isHeader) {
        return <th style={{
          border: "1px solid black",
          padding: 5,
          background: "#444",
          color: "white"
        }}>{tc.children}</th>
      } else {
        return <td
            style={{border: "1px solid black", padding: 5}}>{tc.children}</td>
      }
    },
    td: (tc) => {
      if (tc.isHeader) {
        return <th style={{
          border: "1px solid black",
          padding: 5,
          background: "gray",
          color: "white"
        }}>{tc.children}</th>
      } else {
        return <td
            style={{border: "1px solid black", padding: 5}}>{tc.children}</td>
      }
    },
    p: (p) => {
      let childs = p.children.map((child, index) => getNewChild(child, index));
      return <span style={{marginBottom: 20, lineHeight: 1.4, display: 'block', width: '100%'}}>{wrapImage(childs)}</span>;
    },
    hr: (b) => {
      return <hr style={{margin: 10}}/>;
    },
    text: (t) => {
      const arr = t.value.split("\n");
      const str = arr.map((t, i) => <>{t}{i < arr.length - 1 && <br/>}</>);
      return <>{str}</>
    },
    img: (i, x) => {
      return <span style={{display: "inline-flex", flexDirection: "row"}}>
        <span style={{display: "flex", flexDirection: "column"}}>
          {i && <img src={i.src} alt={i.alt && i.alt.length >0 ? i.alt : "Bild"}/>}
          {i.title && i.title.length > 0 ? <Typography variant={"caption"}><b>Bild: </b>{i.title}</Typography> : null}
        </span>
      </span>
    },
    code: (c) => {
      return <span  style={{display: "inline-flex", flexDirection: "row", lineHeight: "20px"}}>{c.children}</span>
    }
  }
}

export {transformLinkUri, jumpToMarkdownDoc, renderers}
