import React, {memo} from "react";
import MarkdownDisplayField from "../../../markdown/markdowndisplayfield";

const Markdown = ({markdown, style, userProps}) => (
    <div style={style}>
      <MarkdownDisplayField description={markdown} {...userProps}/>
    </div>
)

export default memo(Markdown);
