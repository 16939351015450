import React from "react";
import createToolbarButton from "../../createToolbarButton";
import InfoIcon from "@material-ui/icons/Info";

const defaultItem = () => ({
  content: ""
});

const Button = createToolbarButton({children: <InfoIcon/>});

const AddInfoBlockButton = ({onClick, theme}) => (
    <Button onClick={(evt)=>onClick(evt, defaultItem())} theme={theme}/>
)

export default AddInfoBlockButton;
