import React from "react";
import MyButton from "./mybutton";
import i18n from "../../../i18n/i18n";
import CancelIcon from '@material-ui/icons/Cancel';

const CancelButton = ({onClick, style, autoFocus, disabled, hideText}) => (
    <MyButton icon={<CancelIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={false} disabled={disabled}>
      {!hideText && i18n('cancel')}
    </MyButton>
)

export default CancelButton;
