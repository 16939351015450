import React from "react";
import Paper from "@material-ui/core/Paper";
import BlockCaption from "./blockCaption";
import styles from "../styles/EditorBlock.module.css";

const EditorBlock = ({blockProps, elementProps, child}) => {
  return <div {...elementProps} style={{position: "relative", pointerEvents: blockProps.getReadOnly() ? "inherit" : "none"}}>
    <Paper className={styles.editBlockPaper} elevation={5} onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
      {child}
    </Paper>
    <BlockCaption blockProps={blockProps}/>
  </div>
}

export default EditorBlock;
