import React from 'react';
import HistoSuspense from "../../histosuspense";
const QuizEditor = React.lazy(() => import('./QuizEditor'));

export class LazyQuiz extends React.Component {
    render() {
        return <HistoSuspense>
            <QuizEditor {...this.props}/>
        </HistoSuspense>
    }
}

export default LazyQuiz;
