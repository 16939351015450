import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import types from "../types";

const getNewItem = (url) => {
    let item = {
        "type": types.LINKTYPE_BROWSER,
        "url": url
    }

    return item;
}

const ChooseLinkDialog = ({open, onOK, onCancel, item, clickObject}) => {
    const [url, setUrl] = useState(item && item.url ? item.url : (clickObject ? clickObject.url : ""));

    const cancelClicked = () => {
        setUrl("");
        onCancel();
    }

    const okClicked = () => {
        onOK(getNewItem(url));
        setUrl("");
    }

    return <Dialog open={open}
                   onClose={onCancel}
    >
        <DialogTitle>Link</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label="URL"
                           value={url}
                           onChange={(evt) => setUrl(evt.target.value)}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
    </Dialog>

}

export default ChooseLinkDialog;
