import React from 'react';
import { EditorState } from 'draft-js';
import { createStore } from '@draft-js-plugins/utils';
import createDecorator from './createDecorator';
import AlignmentTool from './AlignmentTool';
import { defaultTheme } from './theme.module.css';

const createSetAlignment = (
  contentBlock,
  {
    getEditorState,
    setEditorState,
  }
) => (data) => {
  const entityKey = contentBlock.getEntityAt(0);
  if (entityKey) {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    contentState.mergeEntityData(entityKey, { ...data });
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection())
    );
  }
};

const InlineBlockMenuIndex = (
  config = {}
) => {
  const store = createStore({
    isVisible: false,
  });

  const { theme = defaultTheme } = config;

  const DecoratedAlignmentTool = ({children}) => {
    return <AlignmentTool store={store} theme={theme} children={children}/>
  };

  return {
    initialize: ({ getReadOnly, getEditorState, setEditorState }) => {
      store.updateItem('getReadOnly', getReadOnly);
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
    },
    decorator: createDecorator({ store }),
    blockRendererFn: (contentBlock, { getEditorState, setEditorState }) => {
      const entityKey = contentBlock.getEntityAt(0);
      const contentState = getEditorState().getCurrentContent();
      const alignmentData = entityKey
        ? contentState.getEntity(entityKey).getData()
        : {};
      return {
        props: {
          alignment: alignmentData.alignment || 'default',
          setAlignment: createSetAlignment(contentBlock, {
            getEditorState,
            setEditorState,
          }),
        },
      };
    },
    AlignmentTool: DecoratedAlignmentTool,
  };
};

export default InlineBlockMenuIndex;
