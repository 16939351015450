import React from 'react';
import HistoSuspense from "../histosuspense";

const Print = React.lazy(() => import('./print.js'));


class PrintLoader extends React.Component {
  render() {
    return <HistoSuspense>
      <Print {...this.props}/>
    </HistoSuspense>
  }
}

export default PrintLoader;
