import React from "react";
import MyButton from "./mybutton";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import i18n from "../../../i18n/i18n";

const LoginButton = ({onClick, style, autoFocus, disabled}) => (
    <MyButton icon={<AccountCircleIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={true} disabled={disabled}>
      {i18n("loginbutton.js370395105")}
    </MyButton>
)

export default LoginButton;
