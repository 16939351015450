export default function createToolbarButton({
  children,
}) {
  return ({theme, onClick}) => {
    const preventBubblingUp = (event) => {
      event.preventDefault();
    };

    const className = theme.button;

    return (
      <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
        <button
          className={className}
          onClick={onClick}
          type="button"
          children={children}
        />
      </div>
    );
  };
}
