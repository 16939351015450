import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import i18n from "../../i18n/i18n";

const extractVideoID = (videoURL) => {
    let url = videoURL;
    if(url) {
        const i = url.lastIndexOf("v=")+1;
        if(i>=1 && i<url.length) {
            url = url.substring(i+1);
            const i2 = url.indexOf("&");
            if(i2 >=0) {
                url = url.substring(0, i2);
            }
        }
    }
    return url;
}

const getNewItem = (title, url, start, itemUuid) => {
    //Die URL muss evtl. gekürzt werden (nur das nach dem letzen Slash zählt)
    //nur v= und nicht auch noch list= und kram
    //Z.B.: https://www.youtube.com/watch?v=ZWBkazUuZoo&list=PLZBYJA7OMVDtkqkZejq0TrVCXHuC-CyZv
    let extractedUrl = extractVideoID(url);

    let item = {
        "type": "YouTubeVideo",
        "id": extractedUrl,
        "start": start,
        "title": title
    }

    return item;
}



const ChooseVideoDialog = ({open, onOK, onCancel, item}) => {
    const [title, setTitle] = useState(item ? item.title : "");
    const [videoURLInternal, setVideoURLInternal] = useState(item ? item.id : "");
    const [videoStartInternal, setVideoStartInternal] = useState(item ? item.start : 0);

    const itemUuid = item ? item.uuid : null;

    const cancelClicked = () => {
        setVideoURLInternal("");
        setVideoStartInternal("0");
        setTitle("");
        onCancel();
    }

    const okClicked = () => {
        onOK(getNewItem(title, videoURLInternal, videoStartInternal, itemUuid));
        setVideoURLInternal("");
        setTitle("");
        setVideoStartInternal(0);
    }

    return <Dialog open={open}
                   onClose={onCancel}
    >
        <DialogTitle>Video</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label={i18n("chooseVideoDialog.js778434054")}
                           value={title}
                           onChange={(evt) => setTitle(evt.target.value)}
                />
                <br/>
                <TextField label={i18n("chooseVideoDialog.js671606307")}
                           value={videoURLInternal}
                           onChange={(evt) => setVideoURLInternal(evt.target.value)}
                />
                <br/>
                <TextField label={i18n("chooseVideoDialog.js799772333")}
                           value={videoStartInternal}
                           onChange={(evt) => setVideoStartInternal(evt.target.value)}
                />
                <br/>
                <a href="https://youtube.com" target="_blank" rel="nofollow noopener noreferrer">YouTube öffnen</a>
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
    </Dialog>

}

export {extractVideoID};
export default ChooseVideoDialog;
