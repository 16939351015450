import React, {memo} from "react";
import MediaFullScreenWrapper from "../../../media/mediaFullScreenWrapper";
import StreetView from "../../../media/streetview";

const Panorama = ({streetviewId, style}) => {
  return <MediaFullScreenWrapper style={{overflow: 'hidden'}} childs={fullScreen => {

    const myStyle = fullScreen ? {...style, height: '100vH'} : style;
    return <StreetView
              streetviewId={streetviewId}
              style={myStyle}
    />
  }}/>
}

export default memo(Panorama);
