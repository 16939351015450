import React, {memo} from "react";
import MediaFullScreenWrapper from "../../../media/mediaFullScreenWrapper";
import YouTube from "../../../media/youtube";

const YouTubePlugin = ({youtubeid, start, style}) => {
  return <MediaFullScreenWrapper style={{overflow: 'hidden'}} childs={fullScreen => {


    return <YouTube title={"Video-"+youtubeid}
             youtubeid={youtubeid}
             start={start}
                    style={style}
                    height={fullScreen ? '100vH' : 300}
    />
  }}/>
}

export default memo(YouTubePlugin);
