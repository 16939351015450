import React from 'react';
import {Map, Set} from 'immutable';
import HistoSuspense from "../../histosuspense";
import MediaFullScreenWrapper from "../../media/mediaFullScreenWrapper";

const Graph = React.lazy(() => import('react-graph-vis'));

/**
 * siehe auch https://visjs.github.io/vis-network/docs/network/
 */
class Relations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedBackRefs: new Set(),
            linktypeID2backRefJSON: new Map()
        }
        this.reftypeName2ColNum = {};
        this.ids = [];
        this.sourceTargets = [];
        this.nextColNum = 1;
        this.network = null;
    }

    selectColor(reftypename, colors) {
        let colNum = this.reftypeName2ColNum[reftypename];
        if (!colNum) {
            colNum = this.nextColNum;
            this.nextColNum++;
            this.reftypeName2ColNum[reftypename] = colNum;
        }
        if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
        return "hsl(" + (colNum * (360 / colors) % 360) + ",100%,50%)";
    }

    render() {
        const data = {
            nodes: [{id: this.props.data.id, label: this.props.data.name}],
            edges: [],
        };

        if (this.props.data.wdreferences) {
            this.reftypeName2ColNum = {};
            this.ids = [];
            this.sourceTargets = [];
            this.nextColNum = 1;
            for (const reftype of Object.keys(this.props.data.wdreferences)) {
                let i = reftype.indexOf("_");
                let reftypename = reftype.substring(i + 1);
                let values = this.props.data.wdreferences[reftype];
                let color = this.selectColor(reftypename, 10);
                if (!this.ids.includes(reftypename)) {
                    this.ids.push(reftypename);
                    data.nodes.push({id: reftypename, label: reftypename, color: {background: color, border: "black"}, shape: "box"})
                    data.edges.push({from: this.props.data.id, to: reftypename});
                }
                for (const val of values) {
                    if (!this.ids.includes(val.id)) {
                        data.nodes.push({
                            id: val.id, label: val.name, color: {
                                background: color, border: "black", hover: {
                                    border: 'red',
                                    background: color
                                }
                            }, shape: "square", size: 10, group: val.name
                        })
                        this.ids.push(val.id);
                    }
                    const sourceTarget = reftypename + "_" + val.id;
                    if (!this.sourceTargets.includes(sourceTarget)) {
                        data.edges.push({from: reftypename, to: val.id});
                        this.sourceTargets.push(sourceTarget)
                    }
                }

            }
        }


        const events = {
            click: (event) => {
                let {nodes} = event;
                if (nodes && nodes.length >= 1) {
                    this.props.onToggleResourceVisibility(1 * nodes[0]);
                }
            }
        };

        //let wrapperStyle = isFullScr ? {position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "white"} : { background: "white"};

        return <MediaFullScreenWrapper style={{overflow: 'hidden'}} childs={fullScreen => {
            const options = {
                layout: {
                    improvedLayout: true
                },
                edges: {
                    color: "#000000"
                },
                nodes: {
                    scaling: {
                        min: 20,
                        max: 25,

                    },
                    shadow: {
                        enabled: true,
                        color: 'rgba(0,0,0,0.5)',
                        size: 10,
                        x: 5,
                        y: 5
                    },
                    shape: {}
                },
                interaction: {hover: true},
                width: (fullScreen ? window.innerWidth : (this.props.width || 800)) + "px",
                height: (fullScreen ? window.innerHeight : (this.props.width || 800)) + "px"
            };
            return <div style={{background: "white"}}>
                <HistoSuspense>
                    <Graph
                        graph={data}
                        options={options}
                        events={events}
                        getNetwork={network => {
                            network.on("hoverNode", function (params) {
                                network.canvas.body.container.style.cursor = 'pointer';
                            });
                            network.on("blurNode", function (params) {
                                network.canvas.body.container.style.cursor = 'default';
                            });
                        }}
                    />
                </HistoSuspense>
            </div>
        }
        }
        />
    }
}

export default Relations;
