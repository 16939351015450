import React from "react";
import ReactDOM from "react-dom";
import HistomaniaMain from "./histomaniamain";
import './styling/index.scss';

class Index extends React.Component {
    render() {
        return (
            <HistomaniaMain/>
        )
    }
}

ReactDOM.render(<Index/>, document.getElementById('root'));
