
const replaceInternalLinks = (s, model) => {
    if(s) {
        //Bestimmung der vorhandenen Ereignisse in der Tafel: Map primary_wikisite -> ID
        let wikisite2ID = new Map();
        for (let item of model.getAll()) {
            if (item.wikipage) {
                let wp = item.wikipage.split('(').join('%28').split(')').join('%29');
                wikisite2ID.set(wp, item.id);
            }
        }

        s = s.replace(/\[([^\]]*)\]\(([^)]*)\)/g, function (m, l1, l2) { // internal link or image
            var p = l2.split(/\//);

            //var link = p.shift();
            let link = p[p.length-1];

            if (link.match(/^Image:(.*)/)) {
                // no support for images - since it looks up the source from the wiki db :-(
                return m;
            } else {

                let linkItem = link.replace(new RegExp(' ', 'g'), "_");
                let itemID = wikisite2ID.get(linkItem);

                if(itemID) {
                    //let retVal=  '['+l+'](s'+itemID+')';
                    let retVal=  '[](s'+itemID+')'+m;
                    return retVal;
                }
                return m;
            }
        })

    }
    return s;
}

export {replaceInternalLinks}


