import { AtomicBlockUtils, RichUtils } from 'draft-js';

const blockModifier = (mapType) => (
  editorState,
  { item }
) => {
  if (RichUtils.getCurrentBlockType(editorState) === 'atomic') {
    return editorState;
  }
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
      mapType,
    'MUTABLE',
    { item }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, '-');
}

export default blockModifier;
