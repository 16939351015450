import { EditorState } from 'draft-js';

const EditBlock = (editorState, blockKey, entityKey, item) => {
  let contentState = editorState.getCurrentContent();
  contentState.mergeEntityData(entityKey, {item});

  const selectionState = editorState.getSelection();
  return EditorState.forceSelection(editorState, selectionState);
};

export default EditBlock;
