import React from "react";
import createToolbarButton from "../../createToolbarButton";
import ScheduleIcon from "@material-ui/icons/Schedule";

const Button = createToolbarButton({children: <ScheduleIcon/>});

const AddEventButton = ({onClick, theme}) => (
  <Button onClick={onClick} theme={theme}/>
)

export default AddEventButton;
