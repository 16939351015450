import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditIcon from "@material-ui/icons/Edit";
import TableChartIcon from "@material-ui/icons/TableChart";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "../../../i18n/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));



export default function MainFab({onEdit, onEventList, onNewStory, onNewTask, isAdmin}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const actions = [
    { icon: <EditIcon/>, name: i18n("mainFab.js309680386"), onClick: onEdit, disabled: !isAdmin },
    { icon: <TableChartIcon/>, name: i18n("mainFab.js593675655") , onClick: onEventList, disabled: !isAdmin},
    { icon: <AddCircleIcon/>, name: i18n("mainFab.js968262525") , onClick: onNewTask, disabled: !isAdmin},
    { icon: <DescriptionOutlinedIcon />, name: i18n("mainFab.js943257132") , onClick: onNewStory}
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (

          <SpeedDial
              ariaLabel="SpeedDial example"
              className={classes.speedDial}
              hidden={false}
              icon={<SpeedDialIcon icon={<EditIcon />} openIcon={<CloseIcon/>}/>}
              onClose={handleClose}
              onClick={handleOpen}
              open={open}
              direction={'up'}
          >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={()=> {handleClose(); action.onClick()}}
                    disabled={action.disabled}
                />
            ))}
          </SpeedDial>
  );
}
