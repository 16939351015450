import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import OKButton from "../ui-components/buttons/text/okbutton";
import i18n from "../i18n/i18n";


const QuizErrorDialog = ({open, onOK, errors}) => {
        return <Dialog open={open}
                   onClose={onOK}
    >
        <DialogTitle>Bitte verbessere folgendes:</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>

                {errors && errors.noQuizTitle === 'X' && <div>{i18n("quizErrorDialog.js818208140")}</div>}
                {errors && errors.noQuestion && <div>{i18n("quizErrorDialog.js316989684")}</div>}
                {errors && errors.questions && errors.questions.map(
                    (question, index)=> (
                        <React.Fragment key={index}>
                            {question.emptyQuestionString === 'X' && <div>{i18n("quizErrorDialog.js217047946")}</div>}
                            {question.tooMuchAnswers && <div>{i18n("quizErrorDialog.js592184179")}</div>}
                            {question.correctAnswerRequired && <div>{i18n("quizErrorDialog.js91808612")}</div>}
                            {question.zeroAnswers && <div>{i18n("quizErrorDialog.js770785722")}</div>}
                            {question.answers && question.answers.map((answer, answerIndex) => (
                                <>
                                    {answer === "X" && <div>{i18n("quizErrorDialog.js121149031")}</div>}
                                </>
                            ))}
                        </React.Fragment>
                    ))
                }
            </div>
        </DialogContent>
        <DialogActions>
            <OKButton onClick={onOK}/>
        </DialogActions>
    </Dialog>

}

export default QuizErrorDialog;
