import React from 'react';
import 'whatwg-fetch';
import Editor from "@draft-js-plugins/editor";

class EditorWrapper extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  render() {
    return <Editor
            key={this.props.readOnly}
            editorState={this.props.editorState}
            onChange={this.props.onChange}
            plugins={this.props.plugins}
            readOnly={this.props.readOnly}
            ref={this.props.innerRef}
        />

  }
}

export default EditorWrapper;
