import Table from "@material-ui/core/Table";
import { TableHead } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import i18n from "../i18n/i18n";

const PriceLegendPlus = () => (
    <div style={{ marginLeft: 20 }}>
        <Table style={{ width: "100%", border: "1px solid black" }}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{i18n('priceLegendPlus.pricesPerYearPerUser')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{i18n('priceLegendPlus.oneToFourUsers')}</TableCell>
                    <TableCell>49,90 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendPlus.fiveToTwentyUsers')}</TableCell>
                    <TableCell>44,90 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendPlus.twentyOneToNinetyNineUsers')}</TableCell>
                    <TableCell>38,15 €</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n('priceLegendPlus.hundredOrMoreUsers')}</TableCell>
                    <TableCell>29,90 €</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
);

export default PriceLegendPlus;
