/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from "../../../i18n/i18n";

const LCalPrecisionField = ({precision, onPrecisionChange}) => (
    <FormControl style={{margin: 1, padding: 0}}>
        <InputLabel htmlFor="precision">{i18n("precision")}</InputLabel>
        <Select
            value={precision}
            onChange={(evt) => onPrecisionChange(evt.target.value)}
            inputProps={{
                name: 'precision',
                id: 'precision',
            }}
        >
          <MenuItem value={0}>{i18n("1000000000years")}</MenuItem>
          <MenuItem value={1}>{i18n("100000000years")}</MenuItem>
          <MenuItem value={2}>{i18n("10000000years")}</MenuItem>
          <MenuItem value={3}>{i18n("1000000years")}</MenuItem>
          <MenuItem value={4}>{i18n("100000years")}</MenuItem>
          <MenuItem value={5}>{i18n("10000years")}</MenuItem>
          <MenuItem value={6}>{i18n("millenium")}</MenuItem>
          <MenuItem value={7}>{i18n("century")}</MenuItem>
          <MenuItem value={8}>{i18n("decade")}</MenuItem>
          <MenuItem value={9}>{i18n("year")}</MenuItem>
          <MenuItem value={10}>{i18n("month")}</MenuItem>
          <MenuItem value={11}>{i18n("day")}</MenuItem>
          <MenuItem value={12}>{i18n("hour")}</MenuItem>
          <MenuItem value={13}>{i18n("minute")}</MenuItem>
          <MenuItem value={14}>{i18n("exact")}</MenuItem>
        </Select>
    </FormControl>
);

export default LCalPrecisionField;
