import React, {useMemo, useRef, useState} from "react";
import {composeDecorators} from '@draft-js-plugins/editor';
import createDividerPlugin from '@draft-js-plugins/divider';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createFocusPlugin from '@draft-js-plugins/focus';
import EditorMap from "./plugins/map/EditorMap";
import EditorPanorama from "./plugins/panorama/EditorPanorama";
import EditorVideo from "./plugins/video/EditorVideo";
import EditorQuiz from "./plugins/quiz/EditorQuiz";
import createBlockPlugin from './plugins/createBlockPlugin.js';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import createAlignmentPlugin from './plugins/inlineblockmenu/index';
import createLinkPlugin from './plugins/link/index';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import editorStyles from './styles/EditorStyles.module.css';
import alignmentStyles from './styles/AlignmentStyles.module.css';
import focusStyles from './styles/FocusStyles.module.css';
import types from "./types";
import EditorUtils from "./utils/EditorUtils";
import EditorImage from "./plugins/image/EditorImage";
import editBlock from "./plugins/editBlock";
import EditorMarkdown from "./plugins/markdown/EditorMarkdown";
import '@draft-js-plugins/focus/lib/plugin.css';
import EditorInfoBlock from "./plugins/infoblock/EditorInfoBlock";
import DraftEditorDialog from "./drafteditordialog";
import DraftEditorToolbar from "./drafteditortoolbar";
import EditorWrapper from "./editorwrapper";

//https://www.draft-js-plugins.com/

let tmpScrollTop = null;

const Drafteditor = ({
  editorState,
  onChange,
  readOnly,
  style,
  model,
  onBrowserLink,
  onEventLink,
  onStoryLink,
  onFilterLink,
  onMapLink,
  dense,
  legacyMarkdownProps,
  ChooseImageComponent,
  ChooseVideoComponent,
  ChooseMapComponent,
  ChoosePanoramaComponent,
  ChooseQuizComponent,
  ChooseInfoBlockComponent,
  ChooseLinkComponent,
  ChooseEventLinkComponent,
  ChooseStoryLinkComponent,
  ChooseFilterLinkComponent,
  ChooseMapLinkComponent,
  ChooseMarkdownComponent,
  maxWidth = 1000
}) => {
  const editorRef = useRef();
  const [openDialog, setOpenDialog] = useState();

  const addLink = (item) => {
    const newState = EditorUtils.createLinkAtSelection(editorState, item, openDialog);
    onChange(newState);
    onCloseDialog();
  }

  const linkClicked = (item) => {
    let onClick;
    if(item.type) {
      switch (item.type) {
        case types.LINKTYPE_BROWSER:
          onClick = onBrowserLink;
          break;
        case types.LINKTYPE_EVENT:
          onClick = onEventLink;
          break;
        case types.LINKTYPE_STORY:
          onClick = onStoryLink;
          break;
        case types.LINKTYPE_FILTER:
          onClick = onFilterLink;
          break;
        case types.LINKTYPE_MAP:
          onClick = onMapLink;
          break;
        default: ;
      }

      onClick && onClick(item, model);
    }
  }

  const putItem = (item) => {
    const currentBlock = EditorUtils.getCurrentBlock(editorState);
    if (currentBlock) {
      editBlock(editorState,
          currentBlock.getKey(),
          currentBlock.getEntityAt(0),
          item);
      onCloseDialog();
      editorRef.current.focus();
    }
  }

  const getScrollParent = (node) => {
    const regex = /(auto|scroll)/;
    const parents = (_node, ps) => {
      if (_node.parentNode === null) { return ps; }
      return parents(_node.parentNode, ps.concat([_node]));
    };

    const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = _node => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
    const scroll = _node => regex.test(overflow(_node));

    /* eslint-disable consistent-return */
    const scrollParent = (_node) => {
      if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
        return;
      }

      const ps = parents(_node.parentNode, []);

      for (let i = 0; i < ps.length; i += 1) {
        if (scroll(ps[i])) {
          return ps[i];
        }
      }

      return document.scrollingElement || document.documentElement;
    };

    return scrollParent(node);
    /* eslint-enable consistent-return */
  };

  const setScrollTop = (scrollParent, val) => {
    scrollParent.scrollTop = val;
  }

  const onCloseDialog = () => {
    setOpenDialog(null);
    const scrollParent = getScrollParent(editorRef.current.getEditorRef().editorContainer);
    if(scrollParent) {
      setTimeout(() =>
          setScrollTop(scrollParent, tmpScrollTop), 0);
    }
  }

  const onOpenDialog = (dlg) => {
    const scrollParent = getScrollParent(editorRef.current.getEditorRef().editorContainer);
    let scrollY = 0;
    if(scrollParent) {
      scrollY = scrollParent.scrollTop;
    } else {
      scrollY = window.scrollY;
    }

    tmpScrollTop = scrollY;
    setOpenDialog(dlg);

    if(scrollParent) {
      setTimeout(() =>
          setScrollTop(scrollParent, tmpScrollTop), 0);
    }
  }

  const {Toolbar, AlignmentTool, plugins, textAlignmentPlugin, dividerPlugin, mapPlugin, panoramaPlugin, videoPlugin, quizPlugin, imagePlugin, infoBlockPlugin} = useMemo(
      () => {
        const staticToolbarPlugin = createToolbarPlugin();
        const focusPlugin = createFocusPlugin({theme: {
            focused: readOnly ? focusStyles.readOnlyFocused : focusStyles.focused,
            unfocused: readOnly ? focusStyles.readOnlyUnfocused : focusStyles.unfocused
        }});
        const dividerPlugin = createDividerPlugin();
        const resizeablePlugin = createResizeablePlugin();
        const textAlignmentPlugin = createTextAlignmentPlugin({
          theme: {alignmentStyles},
        });
        const alignmentPlugin = createAlignmentPlugin();
        const {AlignmentTool} = alignmentPlugin;

        const decorator = composeDecorators(
            focusPlugin.decorator,
            resizeablePlugin.decorator,
            alignmentPlugin.decorator //Achtung: Das alignmentPlugin.decorator muss nach dem resizablePlugin.decorator kommen, sonst funktioniert es nicht
        );
        const mapPlugin = createBlockPlugin({
          decorator,
          BlockComponent: EditorMap,
          type: types.MAPTYPE,
          maxWidth
        });
        const panoramaPlugin = createBlockPlugin({
          decorator,
          BlockComponent: EditorPanorama,
          type: types.PANORAMATYPE,
          maxWidth
        });
        const videoPlugin = createBlockPlugin({
          decorator,
          BlockComponent: EditorVideo,
          type: types.VIDEOTYPE,
          maxWidth
        });
        const quizPlugin = createBlockPlugin({
          decorator,
          userProps: {model, onBrowserLink, onEventLink, onStoryLink, onFilterLink, onMapLink},
          BlockComponent: EditorQuiz,
          type: types.QUIZTYPE,
          maxWidth
        });
        const imagePlugin = createBlockPlugin({
          decorator,
          BlockComponent: EditorImage,
          type: types.IMAGETYPE,
          maxWidth
        });
        const markdownPlugin = createBlockPlugin({
          decorator,
          userProps: legacyMarkdownProps,
          BlockComponent: EditorMarkdown,
          type: types.MARKDOWN,
          maxWidth
        });
        const infoBlockPlugin = createBlockPlugin({
          decorator,
          userProps: {model, onBrowserLink, onEventLink, onStoryLink, onFilterLink, onMapLink},
          BlockComponent: EditorInfoBlock,
          type: types.INFOBLOCK,
          maxWidth
        });
        const linkPlugin = createLinkPlugin(linkClicked);
        const {Toolbar} = staticToolbarPlugin;
        const plugins = [alignmentPlugin, focusPlugin, resizeablePlugin,
          staticToolbarPlugin, textAlignmentPlugin, dividerPlugin, mapPlugin, panoramaPlugin,
          videoPlugin, quizPlugin, linkPlugin, imagePlugin, markdownPlugin, infoBlockPlugin];
        return {
          Toolbar,
          AlignmentTool,
          plugins,
          textAlignmentPlugin,
          dividerPlugin,
          mapPlugin,
          panoramaPlugin,
          videoPlugin,
          quizPlugin,
          linkPlugin,
          imagePlugin,
          markdownPlugin,
          infoBlockPlugin
        };
      }, [readOnly, JSON.stringify(legacyMarkdownProps)]);

  return <>
    <div className={readOnly ? editorStyles.readOnlyEditor : editorStyles.editor}
         style={{...style, position: 'relative'}}
         onClick={() => editorRef.current.focus()}>
      {!readOnly && <div style={{height: 65}}/>}
      <EditorWrapper
          key={readOnly}
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          readOnly={readOnly}
          innerRef={editorRef}
      />
      {!readOnly && <DraftEditorToolbar
          onOpenDialog={onOpenDialog}
          editorState={editorState}
          dense={dense}
          onChange={onChange}
          AlignmentTool={AlignmentTool}
          Toolbar={Toolbar}
          textAlignmentPlugin={textAlignmentPlugin}
          dividerPlugin={dividerPlugin}
          imagePlugin={imagePlugin}
          videoPlugin={videoPlugin}
          mapPlugin={mapPlugin}
          panoramaPlugin={panoramaPlugin}
          ChooseQuizComponent={ChooseQuizComponent}
          quizPlugin={quizPlugin}
          infoBlockPlugin={infoBlockPlugin}
      />
      }
    </div>
    <DraftEditorDialog openDialog={openDialog}
                       onCloseDialog={onCloseDialog}
                       putItem={putItem}
                       addLink={addLink}
                       model={model}
                       currentItem={EditorUtils.getCurrentItem(editorState)}
                       currentClickObject={EditorUtils.getCurrentClickObject(editorState)}
                       ChooseImageComponent={ChooseImageComponent}
                        ChooseVideoComponent={ChooseVideoComponent}
                        ChooseMapComponent={ChooseMapComponent}
                        ChoosePanoramaComponent={ChoosePanoramaComponent}
                        ChooseQuizComponent={ChooseQuizComponent}
                        ChooseInfoBlockComponent={ChooseInfoBlockComponent}
                        ChooseLinkComponent={ChooseLinkComponent}
                        ChooseEventLinkComponent={ChooseEventLinkComponent}
                        ChooseStoryLinkComponent={ChooseStoryLinkComponent}
                        ChooseFilterLinkComponent={ChooseFilterLinkComponent}
                        ChooseMapLinkComponent={ChooseMapLinkComponent}
                        ChooseMarkdownComponent={ChooseMarkdownComponent}
    />
  </>
}

export default Drafteditor;

