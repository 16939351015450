import StoryDetailsBlock from "../../display/storydetailsblock";
import React from "react";
import Paper from "@material-ui/core/Paper";
import HistoSuspense from "../../../histosuspense";
import Typography from "@material-ui/core/Typography";
import LazyImageUploadCrop from "../../../ui-components/lazyimageuploadcrop";
import DrafteditorWrapper from "../../../drafteditor/drafteditorwrapper";
import {EditorState} from "draft-js";
import i18n from "../../../i18n/i18n";

const BackgroundImageInput = ({generatedFilename, origImageFilename, imageBase64, imageLicenseEditorState, model, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, onFileChange, onImageLicenceEditorStateChange, pendingResourceIDs, mapDescriptor, innerWidth, bgColor}) => {
  return <StoryDetailsBlock name={i18n("backgroundimageinput.js816937029")} width={innerWidth} color={bgColor} help={'index.html#_bild'}>
    <Typography variant={"caption"}>{i18n("backgroundimageinput.js218259721")}</Typography>
    <Paper elevation={5} style={{padding: 10}}>
      <LazyImageUploadCrop generatedFilename={generatedFilename}
                       origImageFilename={origImageFilename}
                       imageBase64={imageBase64}
                       onFileChange={(origImageFilename, imageBase64) => onFileChange({origImageFilename, imageBase64, generatedFilename: "", newImageLicenseEditorState: origImageFilename !== "" ? imageLicenseEditorState : EditorState.createEmpty()})}/>
      <HistoSuspense>
        {((imageBase64 && imageBase64.length > 0) || (generatedFilename && generatedFilename!=="")) && <>
          <Typography variant={"h6"} style={{marginTop: 40, color: "gray"}}>{i18n("backgroundimageinput.js429573179")}</Typography>
          <Paper elevation={5} style={{border: "1px solid gray"}}>
            <DrafteditorWrapper
                editorState={imageLicenseEditorState}
                onChange={(val => onImageLicenceEditorStateChange(val))}
                readOnly={false}
                model={model}
                onEventLink={(id) => onHighlight(id)}
                onStoryLink={(id) => onToggleResourceVisibility(id)}
                onMapLink={onShowMap}
                onFilterLink={onFilterClicked}
                pendingResourceIDs={pendingResourceIDs}
                legacyMapDescriptor={mapDescriptor}
            />
          </Paper>
        </>
        }
      </HistoSuspense>
    </Paper>
  </StoryDetailsBlock>
}

export default BackgroundImageInput;
