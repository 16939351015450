import React from "react";
import createToolbarButton from "../../createToolbarButton";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

const defaultItem = () => ({
  questions: [
    {
      question: '-',
      explanation: '-',
      questionType: 'text',
      correctAnswer: [Math.random() * 2 + 1],
      answers: [
        '',''
      ],
      answerSelectionType: "single",
      point: 10
    }
  ],
  nrOfQuestions: 1
});

const Button = createToolbarButton({children: <QuestionAnswerIcon/>});

const AddQuizButton = ({onClick, theme}) => (
    <Button onClick={(evt)=>onClick(evt, defaultItem())} theme={theme}/>
)

export default AddQuizButton;
