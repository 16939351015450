import React from "react";
import WelcomeCSS from "./welcome.module.css";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const TopFactItem = ({icon, title, body}) => (
            <Grid item xs={window.innerWidth > 1000 ? 4 : 12}>
                <div style={{display: "flex"}}>
                    <div style={{color: "#E00"}}>{icon}</div>
                    <div className={WelcomeCSS.topfacttitle}>{title}</div>
                </div>
                  <Typography className={WelcomeCSS.topfacttbody}>
                      {body}
                  </Typography>
            </Grid>
        )


export default TopFactItem;
