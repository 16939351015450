import StoryDetailsBlock from "../../display/storydetailsblock";
import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import HistoSuspense from "../../../histosuspense";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BookingRightCard from "./bookingrights/bookingrightcard";
import MemberEntry from "../../../data/memberentry";
import TransferHandler from "../../../transfer/transferhandler";
import Config from '../../../config/config.js';
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import MyButton from "../../../ui-components/buttons/text/mybutton";
import {Typography} from "@material-ui/core";
import i18n from "../../../i18n/i18n";
import {histomania_licenseid} from "../../../histomaniamain";
import LockWarning from "../../../ui-components/lockwarning";

const UserRightsDialog = React.lazy(
    () => import('./bookingrights/searchuserdialog.js'));

const BookingRightsAdministration = ({isPrivate, resid, members, onPrivateChaged, onMembersChanged, onPricingClicked, innerWidth, bgColor}) => {
  const [editMember, setEditMember] = useState({});
  const [editMode, setEditMode] = useState("");

  useEffect(() => {
    if (!members || members.length === 0) {
      const transferObj = {resourceid: resid}

      //Im Erfolgsfall den members-Status aktualisieren
      let successFunc = (json) => {
        let mArr = json.members.map((val) => {
          return new MemberEntry(val.email, val.forename, val.surname,
              val.company, val.isdummy, val.userid, val.validated)
        });
        json.isPrivate !== isPrivate && onPrivateChaged(json.isPrivate);
        onMembersChanged(mArr);
      }

      let errorFunc = () => {

      }
      TransferHandler.request(Config.getBookingRightsURL(), successFunc,
          errorFunc, transferObj);
    }
  }, [members]);

  const dialogOKClicked = (email, foundUser) => {
    //Hier gleich bestimmen, ob es bereits einen User mit dieser E-Mail gibt und welchen forename, surname, company er hat
    let entry = new MemberEntry(email, foundUser ? foundUser.forename : "", foundUser ? foundUser.surname : "", foundUser ? foundUser.company : "", 0, foundUser ? foundUser.userid : -1, foundUser && foundUser.validated ? true : false);
    let newMembers = members.filter(i => i.email !== email);
    newMembers.push(entry);
    setEditMode("");
    onMembersChanged(newMembers);
  }

  const dialogDeleteClicked = () => {
    const newMembers = members.filter(i => i.email !== editMember.email);
    setEditMode("");
    onMembersChanged(newMembers);
  }

  const edit = (member) => {
    setEditMember(member);
    setEditMode("edit");
  }

  const isDummyUser = members && members.length > 0 && members[0].isdummy;
  return <HistoSuspense>
    <StoryDetailsBlock name={i18n("bookingrightsadministration.js589033383")} width={innerWidth}
                       color={bgColor} help={'index.html#_berechtigungen'}>
      {members && histomania_licenseid ? <Paper elevation={5} style={{padding: 5}}>
        <FormControlLabel
            control={
              <Checkbox
                  checked={!isPrivate}
                  onChange={() => onPrivateChaged(!isPrivate)}
                  disabled={!!isDummyUser}
              />
            }
            label={i18n("bookingrightsadministration.js546709873")}
        />
        <br/>
        <Typography>{i18n("bookingrightsadministration.js131215424")}</Typography>
        <hr/>
        {
          members.map((member, index) => <BookingRightCard key={index} member={member} onClick={()=>edit(member)}/>)
        }
        <div style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginBottom: 5
        }}>
          <MyButton disabled={!!isDummyUser} icon={<GroupAddIcon/>}
                    onClick={() => {setEditMode("new"); setEditMember({})}}>
            {i18n("bookingrightsadministration.js501993017")}
          </MyButton>
        </div>
      </Paper>
      :
      <LockWarning
          onPricingClicked={() => onPricingClicked(true)}
            text={<>{i18n('bookingRightsAdmin.notAvailable')}<br/>{i18n('bookingRightsAdmin.publiclyAccessible')}<br/>{i18n('bookingRightsAdmin.learnMore')}</>}
      />
      }
    </StoryDetailsBlock>
    {editMode !== "" && <UserRightsDialog onCancel={() => {
      setEditMode("");
    }} onOK={dialogOKClicked}
                                          onDelete={dialogDeleteClicked}
                                          member={editMember}
                                          mode={editMode}/>}
  </HistoSuspense>
}

export default BookingRightsAdministration;
