export const matchesEntityType = (type) => type === 'LINK';

const strategy = (contentBlock, callback, contentState) => {
  if (!contentState) return;
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      matchesEntityType(contentState.getEntity(entityKey).getType())
    );
  }, callback);
}

export default strategy;
