/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */

import React from 'react';
import 'react-infinite-calendar/styles.css';
import locale_de from 'date-fns/locale/de';
import deLocale from 'date-fns/locale/de';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TimePicker from './timepicker';
import DateIcon from '@material-ui/icons/DateRange';
import TimeIcon from '@material-ui/icons/AccessTime';
import format from 'date-fns/format';
import HistoSuspense from "../../../histosuspense";
import i18n from "../../../i18n/i18n";

const InfiniteCalendar = React.lazy(() => import('react-infinite-calendar'));


class DateTimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.hourChanged = this.hourChanged.bind(this);
        this.minuteChanged = this.minuteChanged.bind(this);
        this.dateChanged = this.dateChanged.bind(this);
    }

    dateChanged(date) {
        const hour = this.props.value.getHours();
        const minute = this.props.value.getMinutes();
        date.setHours(hour);
        date.setMinutes(minute);
        this.props.onDateChange(date);
    }

    hourChanged(hour) {
        let date = new Date();
        date.setTime(this.props.value.getTime());
        date.setHours(hour);
        this.props.onDateChange(date);
    }

    minuteChanged(minute) {
        let date = new Date();
        date.setTime(this.props.value.getTime());
        date.setMinutes(minute);
        this.props.onDateChange(date);
    }

    render() {
        return (
            <div>
                <Tabs value={this.props.tab} onChange={(evt, tab) => {
                    if (this.props.onTabChanged) {
                        this.props.onTabChanged(tab)
                    }
                }} fullWidth>
                    <Tab icon={<DateIcon/>} label={format(this.props.value, 'dd, dd.MM.yyyy', {locale: deLocale})}/>
                    <Tab icon={<TimeIcon/>} label={format(this.props.value, 'HH:mm') + " Uhr"}/>
                </Tabs>
                {this.props.tab === 0 && <HistoSuspense>
                    <InfiniteCalendar
                        selected={this.props.value}
                        min={new Date(1, 1, 1)}
                        minDate={new Date(1, 1, 1)}
                        max={new Date(2199, 1, 1)}
                        maxDate={new Date(2199, 1, 1)}
                        width={this.props.width ? this.props.width : 400}
                        height={this.props.height ? this.props.height - 250 : 400}
                        onSelect={this.dateChanged}
                        locale={{
                            locale: locale_de,
                            headerFormat: i18n("infiniteCalendarHeaderFormat"),
                            weekdays: i18n("weekdays")(),
                            blank: i18n("noTimeChosen"),
                            weekStartsOn: 1,
                            todayLabel: {
                                long: i18n("noTimeChosen"),
                                short: i18n("noTimeChosen")
                            }
                        }}
                    />
                </HistoSuspense>}
                {this.props.tab === 1 &&
                <TimePicker hour={this.props.value.getHours()}
                            minute={this.props.value.getMinutes()}
                            onHourChanged={this.hourChanged}
                            onMinuteChanged={this.minuteChanged}
                            width={this.props.width ? this.props.width : 400}
                            height={this.props.height ? this.props.height - 100 : 400}
                />
                }
            </div>
        )
    }
}

export default DateTimePicker
