/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */
import React, {useState} from 'react';
import {
    LCal,
    LCalFormatter,
    LCalHelper,
    LCalInterval
} from 'react-canvas-timeline';
import Typography from '@material-ui/core/Typography';
import i18n from "../../i18n/i18n";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const DurationPanel = ({interval, defaultDurationType, listStyle, listItemStyle}) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const languageCode = localStorage.getItem("histomania_language");
        let durationStr = "";
        let secondsDurationStr = "";
        let minuteDurationStr = "";
        let hourDurationStr = "";
        let dayDurationStr = "";
        if (interval.start && interval.end) {
            durationStr = LCalFormatter.formatDuration(interval, languageCode);
            secondsDurationStr = (interval.getAbsDurationMinutesConsiderPrecision() * 60).toLocaleString() + " " + i18n("seconds", languageCode);
            minuteDurationStr = interval.getAbsDurationMinutesConsiderPrecision().toLocaleString() + " " + i18n("minutes", languageCode);
            hourDurationStr = (Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 6) / 10).toLocaleString() + " " + i18n("hours", languageCode);
            dayDurationStr = (Math.round(interval.getAbsDurationMinutesConsiderPrecision() / 6 / 24) / 10).toLocaleString() + " " +  i18n("days", languageCode);
        } else if (interval.start) {
            //Ende ist nicht gegeben -> Wenn start vor heute liegt, dann ist das Ende heute, ansonsten ist die Dauer unbekannt
            let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes());
            if (!interval.start.after(now)) {
                let newInterval = new LCalInterval(interval.start, now);
                durationStr = LCalFormatter.formatDuration(newInterval, languageCode) + " " + i18n("untilNow");
                secondsDurationStr = (newInterval.getAbsDurationMinutesConsiderPrecision() * 60).toLocaleString() + " " + i18n("secondsUntilNow", languageCode);
                minuteDurationStr = newInterval.getAbsDurationMinutesConsiderPrecision().toLocaleString()+ " " + i18n("minutesUntilNow", languageCode);
                hourDurationStr = Math.round(newInterval.getAbsDurationMinutesConsiderPrecision() / 60).toLocaleString() + " " + i18n("hoursUntilNow", languageCode);
                dayDurationStr = Math.round(newInterval.getAbsDurationMinutesConsiderPrecision() / 60 / 24).toLocaleString() + " " + i18n("daysUntilNow", languageCode);
            } else {
                durationStr = i18n("unknownDuration");
            }
        } else if (interval.end) {
            //Start ist nicht gegeben -> Wenn Ende nach heute liegt, dann ist der Start heute, ansonsten ist die Dauer unbekannt
            let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes());
            if (!interval.end.before(now)) {
                let newInterval = new LCalInterval(now, interval.end);
                durationStr = LCalFormatter.formatDuration(newInterval, languageCode) + " ab jetzt";
                secondsDurationStr = (newInterval.getAbsDurationMinutesConsiderPrecision() * 60).toLocaleString() + " " + i18n("secondsFromNow", languageCode);
                minuteDurationStr = newInterval.getAbsDurationMinutesConsiderPrecision().toLocaleString() + " " + i18n("minutesFromNow", languageCode);
                hourDurationStr = Math.round(newInterval.getAbsDurationMinutesConsiderPrecision() / 60).toLocaleString() + " " + i18n("hoursFromNow", languageCode);
                dayDurationStr = Math.round(newInterval.getAbsDurationMinutesConsiderPrecision() / 60 / 24).toLocaleString()+ " " + i18n("daysFromNow", languageCode);
            } else {
                durationStr = i18n("durationpanel.js274332505");
            }

        }

           return <>
               <List style={{listStyle}}>
                    <ListItem button dense onClick={(event)=>{setAnchorEl(event.currentTarget); setOpen(true)}} style={listItemStyle}>
                        <ListItemText inset primary={defaultDurationType === "minutes" ? minuteDurationStr : durationStr} style={{whiteSpace: 'nowrap'}}/>
                    </ListItem>
                </List>
               <Popover onClose={() => setOpen(false)}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
               >
                   <div style={{margin: 10}}>
                       <Typography>= {dayDurationStr}</Typography>
                       <Typography>= {hourDurationStr}</Typography>
                       {defaultDurationType!=="minutes" && <Typography>= {minuteDurationStr}</Typography>}
                       <Typography>= {secondsDurationStr}</Typography>
                   </div>
               </Popover>
               </>


}

export default DurationPanel;
