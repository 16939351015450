import React from "react";
import MyButton from "./mybutton";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import i18n from "../../../i18n/i18n";

const PricingButton = ({onClick, style, autoFocus, disabled}) => (
    <MyButton icon={<ShoppingCartIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={true} disabled={disabled}>
      {i18n("pricingbutton.text")}
    </MyButton>
)

export default PricingButton;
