import Button from "@material-ui/core/Button";
import React from "react";
import theme from "../../../theme";

const MyButton = ({icon, children, onClick, style, primary = true, disabled, autoFocus, href}) => {
  let myStyle = {...style, ...{
      color: disabled ? "gray" : (primary ? "white" : (style && style.color) || "black"),
      background: disabled ? "lightgray" : (primary ? theme.palette.primary.main : (style && style.background) || "lightgray"),
    }};
  return <Button style={myStyle}
          autoFocus={autoFocus}
          disabled={disabled}
          onClick={
            onClick
          }
          href={href}
          startIcon={children && icon}
  >
    <span style={{whiteSpace: "nowrap"}}>{children || icon}</span>
  </Button>
}

export default MyButton;
