import React from "react";
import createToolbarButton from "../../createToolbarButton";
import HeadlinesPicker from "./HeadlinesPicker";
import TextFieldsIcon from '@material-ui/icons/TextFields';

const Button = createToolbarButton({children: <TextFieldsIcon/>});

const HeadlinesButton = ({onClick, theme, onOverrideContent}) => (
  <Button onClick={()=>onOverrideContent(HeadlinesPicker)} theme={theme}/>
)

export default HeadlinesButton;
