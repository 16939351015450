import React from "react";
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Close} from "@material-ui/icons";
import '../styling/index.scss';
import './header.scss';
import ExtendedMenu from "./extendedmenu";
import LoginInfo from "./logininfo";


const getHeadLine = (selectedResource) => (
  <div style={{
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    marginLeft: 30,
    positon: 'relative',
    marginRight: 50,
    height: 50
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "flex-start",
      alignItems: 'baseline',
      overflow: "hidden",
      marginBottom: 10
    }}>
            <Typography style={{
              borderBottom: 0,
              color: 'black',
              whiteSpace: "nowrap",
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textShadow: '2px 2px 2px #C6CDDB'
            }} variant={window.innerWidth < 1200 ? "h6" : "h5"}
                        component={"h1"}>{selectedResource.name}</Typography>
            <Typography style={{
              marginLeft: 10,
              color: 'gray',
              whiteSpace: "nowrap",
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexShrink: 10000
            }} variant={window.innerWidth < 1200 ? "caption" : "subtitle1"}
                        component={"h2"}>{selectedResource.secname}</Typography>
    </div>
  </div>
)


const getMenu = (onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, onHighlight, substitutionMenu) => {
  return <div style={{
    display: "flex",
    justifyContent: "flex-start",
    maxHeight: 70,
    marginLeft: 15
  }}>
    <ExtendedMenu onSearch={onSearch}
        onFilter={onFilter}
        onPrint={onPrint}
        onSettings={onSettings}
        onBookmark={onBookmark}
        onClose={onClose}
        onMeasure={onMeasure}
        onMore={onMore}
        onLanguage={onLanguage}
        userLoggedIn={userLoggedIn}
        model={model}
        timelinevisible={timelinevisible}
        onHighlight={onHighlight}
        substitutionMenu={substitutionMenu}
        />
  </div>
}

const getSingleHeader = (onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, selectedResource, onHighlight, onToggleResourceVisibility, onGoToResource, backgroundImage, substitutionMenu) => (
  <div>
  {getMenu(onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, onHighlight, substitutionMenu)}
  {selectedResource && getHeadLine(selectedResource)}
  </div>
)

const getTabHeader = (onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, selectedResource, onHighlight, onToggleResourceVisibility, onGoToResource, backgroundImage, substitutionMenu) => (
  <div>
    {getMenu(onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, onHighlight, substitutionMenu)}
    {selectedResource && <AppBar position="static" color="secondary"
                                             style={{
                                               background: 'transparent',
                                               boxShadow: 'none',
                                               width: window.innerWidth - 50
                                             }}>
      <Tabs
          value={selectedResource.id}
          onChange={(event, newValue) => onGoToResource(newValue)}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
      >
        {model.getResourceModel().getAll().map((res, index) => {
          let name = res.name && res.name.length > 28 ? res.name.substr(0, 27)
              + "..." : res.name;
          let secname = res.secname && res.secname.length > 35
              ? res.secname.substr(0, 34) + "..." : res.secname;

          return <Tab key={res.id}
                      value={res.id}
                      label={<div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginRight: 10,
                          color: res.id === selectedResource.id ? "white"
                              : "gray"
                        }}>
                          <Typography>{name}</Typography>
                          <Typography variant="caption">{secname}</Typography>
                        </div>
                        <Close onClick={() => onToggleResourceVisibility(
                            res.id)}/>
                      </div>}
          />
        })
        }
      </Tabs>
    </AppBar>
    }
  </div>
)

const Header = ({height, onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, selectedResource, onHighlight, onToggleResourceVisibility, onGoToResource, backgroundImage, substitutionMenu}) => {
  const showTabs = model.getResourceModel().size() > 1;
  return <div style={{
    height: height,
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  }}>
    <div style={{
      height: height,
      background: 'url("'
          + backgroundImage + '") 0% 30% / cover lightgray',
      width: "100%",
      overflow: "hidden",
      color: "white",
      position: "absolute",
      filter: "grayscale(0.5) opacity(0.2) blur(20px)"
    }}/>
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1000
    }}>
      <LoginInfo onLogin={onLogin}
                 loginUser={loginUser}
                 loginUserIsDummy={loginUserIsDummy}/>
    </div>
    <div style={{
      zIndex: 1000
    }}>
    {showTabs ? getTabHeader(onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, selectedResource, onHighlight, onToggleResourceVisibility, onGoToResource, backgroundImage, substitutionMenu)
        : getSingleHeader(onSearch, onFilter, onPrint, onSettings, onBookmark, onClose, onMeasure, onMore, onLanguage, onLogin, loginUser, loginUserIsDummy, userLoggedIn, model, timelinevisible, selectedResource, onHighlight, onToggleResourceVisibility, onGoToResource, backgroundImage, substitutionMenu)}
    </div>
  </div>
};
export default Header;
