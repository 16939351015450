import React, {useEffect, useState} from "react";
import Typography from '@material-ui/core/Typography';
import './tasklistitem.scss';
import {LCalFormatter} from "react-canvas-timeline";
import {getImageURL} from "./dataimage";
import {IconButton} from "@material-ui/core";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Media from "../media/media";
import Paper from "@material-ui/core/Paper";
import WikiboxWithPreview from "../wiki/wikiboxwithpreview";
import DrafteditorWrapper from "../drafteditor/drafteditorwrapper";
import ConverterUtils from "../drafteditor/utils/ConverterUtils";
import EditorUtils from "../drafteditor/utils/EditorUtils";

const TaskListItem = ({task, model, onClick, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, pendingResourceIDs, showDetails}) => {
  const preTitle=LCalFormatter.formatStartEnd(task);
  const title=task.name && task.name !== "" ? task.name : task.secname;
  const imgURL = getImageURL(task, model);
  const group = task.displData.getBarGroup();
  const [descriptionEditorState, setDescriptionEditorState] = useState(ConverterUtils.toEditorStateFromObject(task, 'description', false));
  const [imageLicenseEditorState, setImageLicenseEditorState] = useState(ConverterUtils.toEditorStateFromObject(task, 'imagelicense', false));

  useEffect(() => {
    setDescriptionEditorState( ConverterUtils.toEditorStateFromObject(task, 'description', false));
    setImageLicenseEditorState( ConverterUtils.toEditorStateFromObject(task, 'imagelicense', false));
  }, [task.description]);

  const isEditorStateNotEmpty = EditorUtils.isEditorStateNotEmpty(descriptionEditorState);
  const isLicenseEditorStateNotEmpty = EditorUtils.isEditorStateNotEmpty(imageLicenseEditorState);

  return <Paper elevation={5} className="TaskListMoreItem" onClick={onClick}>
      <div className="TaskListItem">
        <div style={{width: "100%"}}>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <div style={{width: "100%"}}>
              {imgURL &&
                <div className="TaskListSmallItem" style={{float: 'left'}}>
                  <div style={{backgroundImage: "url('" + imgURL + "')"}}
                       className="InnerItem">
                  </div>
                </div>
              }

              <Typography variant={'caption'} style={{color: "gray"}}>{preTitle}</Typography>{group && <Typography variant={'caption'} style={{color: "#4C68A7"}}>{" ("+group+")"}</Typography>}
              <Typography variant={'subtitle1'}>{title}</Typography>
              {showDetails && <>
                      {isEditorStateNotEmpty && <DrafteditorWrapper
                          editorState={descriptionEditorState}
                          onChange={(val => setDescriptionEditorState(val))}
                          readOnly={true}
                          model={model}
                          onEventLink={(id) => onHighlight(id)}
                          onStoryLink={(id) => onToggleResourceVisibility(id)}
                          onMapLink={onShowMap}
                          onFilterLink={onFilterClicked}
                          pendingResourceIDs={pendingResourceIDs}
                          legacyMapDescriptor={task.mapDescriptor}
                      />}

                      {isLicenseEditorStateNotEmpty && <><Typography variant={"caption"}>Bild:</Typography>
                        <DrafteditorWrapper
                            editorState={imageLicenseEditorState}
                            onChange={(val => setImageLicenseEditorState(val))}
                            readOnly={true}
                            model={model}
                            onEventLink={(id) => onHighlight(id)}
                            onStoryLink={(id) => onToggleResourceVisibility(id)}
                            onMapLink={onShowMap}
                            onFilterLink={onFilterClicked}
                            pendingResourceIDs={pendingResourceIDs}
                            legacyMapDescriptor={task.mapDescriptor}
                        />
                      </>
                      }
                      </>}
                    </div>
                    <div>
                      <IconButton aria-label="search" onClick={(e)=>{e.stopPropagation(); e.preventDefault();onHighlight()}} style={{marginRight: 10}}><ScheduleIcon/></IconButton>
                    </div>
                  </div>
                  {showDetails && <>
                      {!window.isSchoolDomain && task.mapDescriptor && task.mapDescriptor.trim().length>0 && <Media mapDescriptor={task.mapDescriptor} width={'100%'} height={300} preview/>}
                      {task.wikipage && task.wikipage.trim().length>0 && <WikiboxWithPreview wikipage={task.wikipage}
                                                                                         model={model}
                                                                                         pendingResourceIDs={pendingResourceIDs}
                                                                                         onToggleResourceVisibility={onToggleResourceVisibility}
                                                                                         onShowMap={onShowMap}
                                                                                         onHighlight={onHighlight}>Wiki</WikiboxWithPreview>}
                  </>}
        </div>
      </div>
  </Paper>
}

export default TaskListItem;
