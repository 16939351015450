import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Story from "../data/story";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StorybookIcon from '@material-ui/icons/DescriptionOutlined';
import ListItemText from "@material-ui/core/ListItemText";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExtensionIcon from "@material-ui/icons/ImportExport";
import MeasureIcon from "@material-ui/icons/Straighten";
import BookmarkIcon from "@material-ui/icons/StarBorder";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {ReactComponent as QrCode2Icon} from "../images/icons/qrcode.svg";
import Collapse from "@material-ui/core/Collapse";
import getURLForShare from "../utils/urlforshare";
import ExportIcon from "@material-ui/icons/CallMade";
import ImportIcon from "@material-ui/icons/CallReceived";
import EmbedIcon from "@material-ui/icons/PictureInPicture";
import ProfileIcon from "../images/icons/profile";
import PasswordIcon from "../images/icons/password";
import SettingsIcon from "../images/icons/settings";
import LicenseIcon from "@material-ui/icons/VerifiedUser";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Typography from "@material-ui/core/Typography";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Menu from "@material-ui/core/Menu";

import React from "react";
import {
  histomania_login_user,
  histomania_login_user_is_dummy
} from "../histomaniamain";
import UpdateIcon from "@material-ui/icons/Update";
import FilterIcon from "../images/icons/filter";
import i18n from "../i18n/i18n";

const iconSVGStyle = {
    width: "1em",
    height: "1em",
    marginRight: "0.4em",
    verticalAlign: "top",
    stroke: "none",
    fill: "#fff",
    padding: 5,
}

const hasPrivateStory = (model) => {
  for (let res of model.getResourceModel().getAll()) {
    if(res.isPrivate) {
      return true;
    }
  }
  return false;
}

const ListMenu = (state,
    setState,
    model,
    initNewResource,
    measure,
    whatIf,
    whatIfReverse,
    bookmarks,
    filterTimeline,
    printDialog,
    exportData,
    importData,
    embedStories,
    qrcode,
    editProfile,
    setLanguage,
    changePassword,
    adminLicenses,
    settings,
    help,
    dataprotection,
    agb,
    impressum,
    sourceReference,
    cookies,
    login,
    createBookingItemFromScratch,
    openShare,
    mapForTimeInterval,
    classes) => (<Menu
      id="main-menu"
      anchorEl={state.menuAnchorEl}
      open={state.menuOpen}
      onClose={() => setState({menuOpen: false})}
    >

  <List className={classes.listRoot}>
    {state.measureStart && state.measureEnd && <ListItem button onClick={() => {
      setState({menuOpen: false});
      mapForTimeInterval(state.measureStart, state.measureEnd);
    }} disabled={!state.userLoggedIn}>
      <ListItemIcon>
        <AddCircleIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.mapForTimeInterval")}/>
    </ListItem>}


    <ListItem button onClick={() => {
      setState({menuOpen: false});
      initNewResource(new Story())
    }} disabled={!state.userLoggedIn}>
      <ListItemIcon>
        <StorybookIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js844059924")}/>
    </ListItem>

    {model.getResourceModel().getAdminResources().length>0 && <ListItem button onClick={() => {
      setState({menuOpen: false});
      createBookingItemFromScratch();
    }} disabled={!state.userLoggedIn}>
      <ListItemIcon>
        <AddCircleIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js505082777")}/>
    </ListItem>}

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      filterTimeline()
    }} disabled={model.getResourceModel().size()===0}>
      <ListItemIcon>
        <FilterIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n('filter') + (model.isFiltered() ? " " + "("+i18n("menu.active")+")" : "")}/>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      measure()
    }} disabled={model.getResourceModel().size()===0}>
      <ListItemIcon>
        <MeasureIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n('measure')}/>
    </ListItem>

    {whatIf && <ListItem button onClick={() => {
      setState({menuOpen: false});
      whatIf()
    }} disabled={model.getResourceModel().size()===0}>
      <ListItemIcon>
        <UpdateIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js756551371")}/>
    </ListItem>}

    {whatIfReverse && <ListItem button onClick={() => {
      setState({menuOpen: false});
      whatIfReverse()
    }} disabled={model.getResourceModel().size()===0}>
      <ListItemIcon>
        <UpdateIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js663038438")}/>
    </ListItem>}

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      bookmarks()
    }}>
      <ListItemIcon>
        <BookmarkIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js439620799")}/>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      printDialog();
    }} disabled={model.getResourceModel().size()===0}>
      <ListItemIcon>
        <PrintIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js157014000")}/>
    </ListItem>

    <ListItem button onClick={() => {
      setState({openMainMenuItem: state.openMainMenuItem === "share" ? "" : "share"})
    }} disabled={!state.userLoggedIn || model.getResourceModel().size()===0}>
      <ListItemIcon>
        <ShareIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js893403567")}/>
      {state.openMainMenuItem === "share" ? <ExpandLess/> : <ExpandMore/>}
    </ListItem>

    <Collapse in={state.openMainMenuItem === "share"} transitionduration="500"
              unmountOnExit>
      {!window.isSchoolDomain && <>
                <ListItem button onClick={() => {
                  openShare('https://facebook.com/sharer/sharer.php?u=' + getURLForShare(model), hasPrivateStory(model), ()=>setState({menuOpen: false}));
                  //setState({menuOpen: false});
                  //window.open('https://facebook.com/sharer/sharer.php?u=' + getURLForShare(model), '_blank');
                }} className={classes.nestedList}>
                  <ListItemIcon>
                    <div aria-hidden="true" style={iconSVGStyle}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                           style={{
                             backgroundColor: "#3b5998",
                             padding: 3,
                             borderRadius: 3
                           }}>
                        <path
                            d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/>
                      </svg>
                    </div>
                  </ListItemIcon>
                  <ListItemText inset primary={i18n('menu.onFacebook')}/>
                </ListItem>

                <ListItem button onClick={() => {
                  openShare('https://twitter.com/intent/tweet/?text='+i18n('listMenu.nestedListTwitter')+' '+window.location.host+':&url=' + getURLForShare(model), hasPrivateStory(model), ()=>setState({menuOpen: false}));
                  //setState({menuOpen: false});
                  //window.open('https://twitter.com/intent/tweet/?text={i18n('listMenu.nestedListFacebook')} histomania.com:&url=' + getURLForShare(model), '_blank');
                }} className={classes.nestedList}>
                  <ListItemIcon>
                    <div aria-hidden="true" style={iconSVGStyle}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                           style={{
                             backgroundColor: "#55acee",
                             padding: 3,
                             borderRadius: 3
                           }}>
                        <path
                            d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/>
                      </svg>
                    </div>
                  </ListItemIcon>
                  <ListItemText inset primary={i18n('menu.onTwitter')}/>
                </ListItem>

                <ListItem button onClick={() => {
                  openShare('whatsapp://send?text='+i18n('listMenu.nestedListWhatsApp')+' '+window.location.host+': ' + getURLForShare(model), hasPrivateStory(model), ()=>setState({menuOpen: false}));
                  //setState({menuOpen: false});
                  //window.open('whatsapp://send?text={i18n('listMenu.nestedListFacebook')} histomania.com: ' + getURLForShare(model), '_blank');
                }} className={classes.nestedList}>
                  <ListItemIcon>
                    <div aria-hidden="true" style={iconSVGStyle}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                           style={{
                             backgroundColor: "#25D366",
                             padding: 3,
                             borderRadius: 3
                           }}>
                        <path
                            d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z"/>
                      </svg>
                    </div>
                  </ListItemIcon>
                  <ListItemText inset primary={i18n('menu.onWhatsApp')}/>
                </ListItem>
          </>
        }
      <ListItem button onClick={() => {
        openShare('mailto:?subject='+i18n('listMenu.nestedListMailSubject')+' '+window.location.host+'&body=' + getURLForShare(model), hasPrivateStory(model), ()=>setState({menuOpen: false}));
        //setState({menuOpen: false});
        //window.open('mailto:?subject={i18n('listMenu.nestedListMailSubject')} histomania.com&body=' + getURLForShare(model), '_blank');
      }} className={classes.nestedList}>
        <ListItemIcon>
          <div aria-hidden="true" style={iconSVGStyle}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                 style={{
                   backgroundColor: "#777",
                   padding: 3,
                   borderRadius: 3
                 }}>
              <path
                  d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z"/>
            </svg>
          </div>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js932778276")}/>
      </ListItem>
      <ListItem disabled={!state.userLoggedIn}
                button
                onClick={() => {
                  setState({menuOpen: false});
                  embedStories();
                }} className={classes.nestedList}>
        <ListItemIcon>
          <EmbedIcon/>
        </ListItemIcon>
        <ListItemText inset
                      primary={i18n('menu.embedStory')}/>
      </ListItem>
      <ListItem disabled={!state.userLoggedIn}
                button
                onClick={() => {
                  setState({menuOpen: false});
                  qrcode();
                }} className={classes.nestedList}>
        <ListItemIcon>
          <QrCode2Icon/>
        </ListItemIcon>
        <ListItemText inset
                      primary={i18n('menu.qrCode')}/>
      </ListItem>
    </Collapse>

    <ListItem button onClick={() => {
      setState({openMainMenuItem: state.openMainMenuItem === "extras" ? "" : "extras"})
    }}>
      <ListItemIcon>
        <ExtensionIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js555525792")}/>
      {state.openMainMenuItem === "extras" ? <ExpandLess/> : <ExpandMore/>}
    </ListItem>

    <Collapse in={state.openMainMenuItem === "extras"} transitionduration="500"
              unmountOnExit>
      <ListItem button onClick={() => {
        setState({menuOpen: false});
        exportData();
      }} className={classes.nestedList}
        disabled={!state.userLoggedIn || model.getResourceModel().size()===0}
      >
        <ListItemIcon>
          <ExportIcon/>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js556545983")}/>
      </ListItem>
      <ListItem button onClick={() => {
        setState({menuOpen: false});
        importData();
      }} className={classes.nestedList}>
        <ListItemIcon>
          <ImportIcon/>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js413767225")}/>
      </ListItem>
    </Collapse>
    <ListItem button onClick={() => {
      setState({openMainMenuItem: state.openMainMenuItem === "admin" ? "" : "admin"})
    }} disabled={!state.userLoggedIn}
              >
      <ListItemIcon disabled={!state.userLoggedIn}>
        <ProfileIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js923812869")}/>
      {state.openMainMenuItem === "admin" ? <ExpandLess/> : <ExpandMore/>}
    </ListItem>

    <Collapse in={state.openMainMenuItem === "admin"} transitionduration="500"
              unmountOnExit>
      <ListItem
          disabled={!state.userLoggedIn || histomania_login_user_is_dummy}
          button onClick={() => {
        setState({menuOpen: false});
        editProfile()
      }} className={classes.nestedList}>
        <ListItemIcon>
          <ProfileIcon/>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js450602741")}/>
      </ListItem>
      <ListItem
          disabled={!state.userLoggedIn || histomania_login_user_is_dummy}
          button onClick={() => {
        setState({menuOpen: false});
        changePassword()
      }}  className={classes.nestedList}>
        <ListItemIcon>
          <PasswordIcon/>
        </ListItemIcon>
        <ListItemText inset primary={i18n("menu.js984392851")}/>
      </ListItem>
      <ListItem
          disabled={!state.userLoggedIn || histomania_login_user_is_dummy}
          button onClick={() => {
        setState({menuOpen: false});
        adminLicenses()
      }}  className={classes.nestedList}>
        <ListItemIcon>
          <LicenseIcon/>
        </ListItemIcon>
        <ListItemText inset primary={i18n("licenses")}/>
      </ListItem>
    </Collapse>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      settings()
    }}>
      <ListItemIcon>
        <SettingsIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js961199351")}/>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      help()
    }} divider>
      <ListItemIcon>
        <HelpOutlineIcon/>
      </ListItemIcon>
      <ListItemText inset primary={i18n("menu.js990897862")}/>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      dataprotection()
    }} style={{paddingBottom: 0}}>
      <Typography
          style={{
            paddingLeft: 56
          }}>{i18n("menu.js826958696")}</Typography>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      agb()
    }} style={{paddingTop: 0, paddingBottom: 0}}>
      <Typography style={{paddingLeft: 56}}>{i18n("menu.js586813367")}</Typography>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      impressum()
    }} style={{paddingTop: 0, paddingBottom: 0}}>
      <Typography style={{paddingLeft: 56}}>{i18n("menu.js614038001")}</Typography>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      sourceReference()
    }} style={{paddingTop: 0, paddingBottom: 0}}>
      <Typography
          style={{
            paddingLeft: 56
          }}>{i18n("menu.js439856996")}</Typography>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      cookies()
    }} divider style={{paddingTop: 0, paddingBottom: 10}}>
      <Typography
          style={{
            paddingLeft: 56
          }}>{i18n("menu.js942699906")}</Typography>
    </ListItem>

    <ListItem button onClick={() => {
      setState({menuOpen: false});
      login()
    }}>
      <ListItemIcon>
        <LockOpenIcon/>
      </ListItemIcon>
      <ListItemText inset
                    primary={state.userLoggedIn && !histomania_login_user_is_dummy ? i18n("menu.js165576261") : i18n("menu.js972296370")}
                    secondary={state.userLoggedIn ? (!histomania_login_user_is_dummy ? i18n("menu.js171939370") + histomania_login_user : i18n("menu.js698756284")) : null}/>
    </ListItem>
  </List>
</Menu>);


export default ListMenu;
