import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import EditorUtils from "./EditorUtils";


const wrapMarkdown = (markdowntxt) => {
  return {
    "blocks": [
    {
      "key": "3h0eg",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "cguol",
      "text": "-",
      "type": "atomic",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [
        {
          "offset": 0,
          "length": 1,
          "key": 0
        }
      ],
      "data": {}
    },
    {
      "key": "euplo",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    },
    {
      "key": "73kn5",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
      "entityMap": {
    "0": {
      "type": "softmanufakturMarkdownType",
          "mutability": "MUTABLE",
          "data": {
        "item": {
          "type": "Markdown",
              "src": markdowntxt
        },
        "width": 100,
        "height": 0
      }
    }
  }
  }
}

const toEditorState = (description, createEmptyIfNoValue) => {
  if(!description || description.trim().length === 0) {
    if(createEmptyIfNoValue) {
      return EditorState.createEmpty();
    } else {
      return null;
    }
  }
  if(description.startsWith("{")) {
    try {
      const json = JSON.parse(description);
      const contentState = convertFromRaw(json);
      return EditorState.createWithContent(contentState);
    } catch(e) {

    }
  }
  //Den Text als Markdown interpretieren
  const contentState = convertFromRaw(wrapMarkdown(description));
  return EditorState.createWithContent(contentState);
}

const fromEditorState = (editorState) => {
  if(EditorUtils.isEditorStateNotEmpty(editorState)) {
    const raw = convertToRaw(editorState.getCurrentContent());
    return JSON.stringify(raw);
  }
  return null;
}

const ConverterUtils = {
  toEditorStateFromObject(obj, fieldname, createEmptyIfNoValue) {
    if(obj && obj[fieldname+"_EditorState"]) {
      return obj[fieldname+"_EditorState"];
    }
    obj[fieldname+"_EditorState"] = toEditorState(obj[fieldname], createEmptyIfNoValue);
    return obj[fieldname+"_EditorState"];
  },
  editorStateToObject(obj, fieldname, editorState) {
    obj[fieldname] = fromEditorState(editorState);
    obj[fieldname+"_EditorState"] = editorState;
  },
  toEditorState
}

export default ConverterUtils;
