import React from "react";
import "../styling/wiki/wiki.css";
import TransferHandler from "../transfer/transferhandler";
import Config from "../config/config";
import Typography from '@material-ui/core/Typography';
import MarkdownDisplayField from '../markdown/markdowndisplayfield.js';
import {renderers, transformLinkUri} from "../markdown/markdownhelper";
import LinearProgress from '@material-ui/core/LinearProgress';
import {replaceInternalLinks} from "./wikiparser";
import i18n from "../i18n/i18n";
import Wikiboxsubheader from "./wikiboxsubheader";

export class WikiBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            expandedPanel: "",
            isExpanded: this.props.expanded // New state variable
        }
        this.site2RawContent = new Map();

        this.renderedRawContent = "";
        this.renderedSelectedResIDs = "";
        this.mounted = false;
        this.expandedHeaderRef = null;
    }

    componentDidMount() {
        this.getSite(this.props.site, () => this.site2RawContent.get(this.props.site) && this.forceUpdate());
        this.mounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.site !== this.props.site && !this.site2RawContent.get(this.props.site)) {
            this.getSite(this.props.site, () => this.site2RawContent.get(this.props.site) && this.forceUpdate());
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getSite(site, callback) {
        let rawContent = this.site2RawContent.get(site);
        if (rawContent) {
            //replaceInternalLinks(rawContent, this.props.model);
            callback();
        } else {
            let successFunc = (json) => {
                this.site2RawContent.set(site, json);
                if(this.mounted) {
                    this.setState({isLoading: false}, () => callback());
                }
            }
            let errorFunc = (code, json) => {
                console.log("Fehler " + code + " " + json);
                if(this.mounted) {
                    this.setState({isLoading: false}, () => callback());
                }
            }
            this.setState({isLoading: true}, () => TransferHandler.request(Config.getWikiInfoURL() + "?filename=" + this.props.site, successFunc, errorFunc, {}));
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let renderedSelectedResIDs = "";
        for (let item of this.props.model.getResourceModel().getAll()) {
            renderedSelectedResIDs += (item.id + "");
        }

        // Auch die Selektionen dürfen sich nicht geändert haben
        return this.renderedRawContent !== this.site2RawContent.get(nextProps.site)
            || this.renderedSelectedResIDs !== renderedSelectedResIDs
            || nextProps.pendingResourceIDs !== this.props.pendingResourceIDs
            || this.state.expandedPanel !== nextState.expandedPanel
            || this.state.isLoading !== nextState.isLoading
            || this.state.isExpanded !== nextState.isExpanded; // Check for isExpanded state
    }

    toggleExpand = () => {
        this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
    }

    render() {
        this.renderedSelectedResIDs = "";
        for (let item of this.props.model.getResourceModel().getAll()) {
            this.renderedSelectedResIDs += (item.id + "");
        }
        let content = this.site2RawContent.get(this.props.site);
        //content = replaceInternalLinks(content, this.props.model)
        this.renderedRawContent = content;

        //content = replaceInternalLinks(content, this.props.model);
        if (this.state.isLoading) {
            return <LinearProgress/>
        } else if (content && content.topics) {
            return <>
                <div style={{overflow: "hidden"}}>
                    {this.state.isExpanded ? (
                            <>
                                {content.topics.map((kv, i) => {
                                    let val = replaceInternalLinks(kv.value, this.props.model);
                                    if (kv.key === "") {
                                        return <div key={kv.key} style={{marginBottom: 10}}>
                                            <MarkdownDisplayField description={val}
                                                                  transformLinkUri={(link) => transformLinkUri(link)}
                                                                  renderers={renderers((resid) => this.props.model.getResourceModel().getItemByID(resid), this.props.onToggleResourceVisibility, this.props.onHighlight, this.props.onShowMap, this.props.pendingResourceIDs, this.props.model, null, false, this.props.width)}/>
                                        </div>
                                    } else {
                                        return (<div style={{marginBottom: 5}}
                                                     ref={(ref) => {
                                                         if (this.state.expandedPanel === kv.key) {
                                                             this.expandedHeaderRef = ref
                                                         }
                                                     }}
                                                     key={kv.key}>
                                            <Wikiboxsubheader expanded={this.state.expandedPanel === kv.key}
                                                              onChange={(evt, isExpanded) => this.setState({expandedPanel: isExpanded ? kv.key : ""}, () => this.expandedHeaderRef.scrollIntoView())}
                                                              name={kv.key}
                                            />
                                            {this.state.expandedPanel === kv.key &&
                                                <div style={{marginTop: 10, marginBottom: 10, backgroundColor: "none"}}>
                                                    <MarkdownDisplayField description={val}
                                                                          transformLinkUri={(link) => transformLinkUri(link)}
                                                                          renderers={renderers((resid) => this.props.model.getResourceModel().getItemByID(resid), this.props.onToggleResourceVisibility, this.props.onHighlight, this.props.onShowMap, this.props.pendingResourceIDs, this.props.model, null, false, this.props.width)}/>
                                                </div>
                                            }

                                        </div>)
                                    }
                                })}
                                <div style={{margin: 5, color: 'gray'}}>
                                    <Typography variant={"caption"}>
                                        {i18n("wikiLicenseCCSA")(this.props.site)}
                                    </Typography>
                                </div>
                            </>
                        )

                        : (
                            <button onClick={this.toggleExpand}>Wikipedia-Eintrag anzeigen</button>
                        )}
                </div>
            </>
        } else {
            return null;
        }
    }
}

export default WikiBox;
