import linkStrategy from './linkStrategy';
import Link from './components/Link';

const LinkIndex = (onLinkClick) => {
  return {
    decorators: [
      {
        strategy: linkStrategy,
        component: Link(onLinkClick),
      },
    ]
  };
};

export default LinkIndex;
