import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MyButton from "../ui-components/buttons/text/mybutton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import i18n from "../i18n/i18n";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TransferHandler from "../transfer/transferhandler";
import Config from "../config/config";
import PriceLegendSchool from "./pricelegendschool";
import PriceLegendPlus from "./pricelegendplusl";
import BackButton from "../ui-components/buttons/text/backbutton";
import AddressForm from "./addressform";

const PriceCalculator = ({calculatorType, onBack, onLogin, userLoggedIn, loginUserIsDummy, onCheckout}) => {
        const [lehrer, setLehrer] = useState(1);
        const [schueler, setSchueler] = useState(0);
        const [validfrom, setValidfrom] = useState(new Date().toISOString().split("T")[0]); // Aktuelles Datum als Standardwert
        const [showErrorPage, setShowErrorPage] = useState(false);
        const [validFromError, setValidFromError] = useState(""); // State für Datum-Fehler

        const [company, setCompany] = useState("");
        const [taxnumber, setTaxnumber] = useState("");
        const [prename, setPrename] = useState("");
        const [surname, setSurname] = useState("");
        const [street, setStreet] = useState("");
        const [streetnumber, setStreetnumber] = useState("");
        const [zipCode, setZipcode] = useState("");
        const [town, setTown] = useState("");
        const [country, setCountry] = useState("DEU");
        const [email, setEmail] = useState("");

        const [addressIsValid, setAddressIsValid] = useState(false);


        useEffect(() => {
            setSchueler(calculatorType==="school" ? 10 : 0);
        }, [calculatorType]);

        const handleLehrerChange = (event) => {
            setLehrer(event.target.value);
        };

        const handleSchuelerChange = (event) => {
            setSchueler(event.target.value);
        };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setValidfrom(selectedDate);

        // Datum validieren
        if (isValidDate(selectedDate)) {
            setValidFromError("");
        } else {
            setValidFromError(i18n("priceCalculator.chooseFutureDate"));
        }
    };

    const isValidDate = (date) => {
        const today = new Date();
        // Setze heute auf den Tagesanfang (00:00:00)
        today.setHours(0, 0, 0, 0);

        const selectedDate = new Date(date);
        // Überprüfen, ob das Datum in der Zukunft liegt (einschließlich heute)
        return selectedDate >= today;
    };

        function isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        const calculatePrice = () => {
            let lehrerFactor = 0;
            let schuelerFactor = 0;

            let schuelerCnt = calculatorType==="school" ? (isNumber(schueler) && schueler >= 10 ? schueler : 10) : 0;
            let lehrerCnt = isNumber(lehrer) && lehrer >= 1 ? lehrer : 1;

            if(calculatorType==="school") {
                if(lehrerCnt >= 10) {
                    lehrerFactor = 42.5;
                } else if(lehrerCnt >= 5) {
                    lehrerFactor = 45;
                } else {
                    lehrerFactor = 50;
                }

                if(schuelerCnt >= 300) {
                    schuelerFactor = 2.4;
                } else if(schuelerCnt >= 100) {
                    schuelerFactor = 2.7;
                } else {
                    schuelerFactor = 3;
                }
            } else {
                if(lehrerCnt >= 100) {
                    lehrerFactor = 29.9;
                } else if(lehrerCnt >= 21) {
                    lehrerFactor = 38.15;
                } else if(lehrerCnt >= 5) {
                    lehrerFactor = 44.9;
                } else {
                    lehrerFactor = 49.9;
                }
            }

            let lehrerPrice = lehrerCnt * lehrerFactor;
            let schuelerPrice = schuelerCnt * schuelerFactor;

            return lehrerPrice + schuelerPrice;
        };

        const pay = () => {
        // Validierung: Wenn das Datum nicht gültig ist, brich die Funktion ab
        if (validFromError) {
            return;
        }

            let transferObj = {
                teacherCnt: lehrer,
                studentCnt: schueler,
                calculatorType,
                company,
                taxnumber,
                prename,
                surname,
                street,
                streetnumber,
                zipCode,
                town,
                country,
                email,
                validfrom,
                calculatedPrice: calculatePrice(),
            }

            let successFunc = (json) => {
                onCheckout(json.buyreference, calculatePrice(), country);
            }
            let errorFunc = (code, json) => {
                setShowErrorPage(true);
            }

            TransferHandler.request(Config.getCreateLicenseURL(), successFunc, errorFunc, transferObj);
        }

        return (<>
                {showErrorPage && <div>{i18n('priceCalculator.errorOccurred')}<br/>{i18n('priceCalculator.contactSupport')}<br/><br/><BackButton onClick={onBack}/></div>}
                {!showErrorPage && <Container>
                        <Typography variant="h4" gutterBottom>
                            {i18n('priceCalculator.licenseConfig')} {calculatorType==="school" ? i18n('priceCalculator.schools') : i18n('priceCalculator.privateUsers')}
                        </Typography>
                        <br/>
                        <Typography>{i18n('priceCalculator.howManyUsers')}</Typography>
                    <br/>
                    <br/>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <TextField
                                    label= {calculatorType==="school" ? i18n('priceCalculator.numTeachers') : i18n('priceCalculator.numUsers')}
                                    type="number"
                                    value={lehrer}
                                    onChange={handleLehrerChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    helperText={(!isNumber(lehrer) || lehrer < 1) && i18n('priceCalculator.atLeastOne') + " "+(calculatorType==="school" ? "Lehrender" : "Anwender")+" "+i18n('priceCalculator.calculated')}
                                />
                                {calculatorType==="school" && <TextField
                                    label={i18n('priceCalculator.numStudents')}
                                    type="number"
                                    value={schueler}
                                    onChange={handleSchuelerChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    helperText={(!isNumber(schueler) || schueler < 10) && i18n('priceCalculator.atLeastTenStudents')}
                                />}

                                <TextField
                                    label={i18n('priceCalculator.validFrom')}
                                    type="date"
                                    value={validfrom}
                                    onChange={handleDateChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={validFromError} // Warnmeldung
                                    error={!!validFromError} // Fehlerfarbe aktivieren, wenn ein Fehler vorliegt
                                />

                                <div style={{marginTop: 50}}>
                                    <Typography variant="h6">
                                        {i18n('priceCalculator.totalPrice')}
                                    </Typography>
                                    <Typography variant="h4" style={{display: 'inline'}}>
                                        {calculatePrice().toFixed(2)} €
                                    </Typography>
                                    <Typography  style={{display: 'inline'}}>
                                        &nbsp;&nbsp;{i18n('priceCalculator.perYear')}
                                    </Typography>
                                </div>
                            </div>
                            {calculatorType==="school" ? <PriceLegendSchool/> : <PriceLegendPlus/>}
                        </div>
                    <br/>
                    <div>
                        <Typography variant="h6" gutterBottom style={{marginTop: 50}}>
                            {i18n('priceCalculator.billingAddress')}
                        </Typography>
                        <AddressForm
                            isEditable={true}
                            isSchool={calculatorType==="school"}
                            company={company}
                            onCompanyChange={setCompany}
                            taxnumber={taxnumber}
                            onTaxnumberChange={setTaxnumber}
                            prename={prename}
                            onPrenameChange={setPrename}
                            surname={surname}
                            onSurnameChange={setSurname}
                            street={street}
                            onStreetChange={setStreet}
                            streetnumber={streetnumber}
                            onStreetnumberChange={setStreetnumber}
                            zipCode={zipCode}
                            onZipcodeChange={setZipcode}
                            town={town}
                            onTownChange={setTown}
                            country={country}
                            onCountryChange={setCountry}
                            email={email}
                            onEmailChange={setEmail}
                            onValidation={(valid)=>setAddressIsValid(valid)}
                        />
                    </div>
                    <br/>
                    <br/>
                        {!loginUserIsDummy && userLoggedIn &&  <MyButton  disabled={!addressIsValid} icon={<ShoppingCartIcon/>} onClick={()=>pay()} primary={true}>kostenpflichtig bestellen</MyButton>}
                        {(loginUserIsDummy || !userLoggedIn) && <>
                            <Typography variant={"caption"}>{i18n('priceCalculator.purchaseInfo')}</Typography>
                            <MyButton icon={<AccountCircleIcon/>} onClick={()=>onLogin()} primary={true}>{i18n("loginbutton.js370395105")}</MyButton>
                        </>}
                    <br/>
                    <br/>

                    <Typography variant="caption">{i18n('priceCalculator.profileManagement')}
                        <br/>
                        {i18n('priceCalculator.defineUsers')}
                        <br/><br/>
                        {i18n('priceCalculator.includesTax')}
                        <br/>
                        <br/>
                        {country !== "DEU" && i18n('priceCalculator.foreignOrders')}
                    </Typography>

                    <BackButton onClick={onBack}/>

                    </Container>}
            </>
        );

}


    export default PriceCalculator;
