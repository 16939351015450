import React from "react";
import WelcomeCSS from "./welcome.module.css";
import {ReactComponent as SinusTop} from "../images/icons/sinusTop.svg";
import {ReactComponent as SinusBottom} from "../images/icons/sinusBottom.svg";

const Background = () => {
        return (
                  <div className={WelcomeCSS.BackgroundVideoContainer}>
                    <SinusTop/>
                    <SinusBottom/>
                    <video autoPlay muted loop className={WelcomeCSS.backgroundVideo}>
                      <source src="video/background.mp4" type="video/mp4"/>
                    </video>

                  </div>


        );
}


export default Background;
