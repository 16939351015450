import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const path = "M 21.66371,1.3864752 H 2.532547 c -0.8790607,0 -1.3226321,1.0570656 -0.6997703,1.6744115 l 7.6261896,7.5593953 v 8.421191 c 0,0.320048 0.157555,0.619987 0.42213,0.803572 l 3.2988467,2.287787 c 0.650821,0.451518 1.557179,-0.0052 1.557179,-0.803526 V 10.620282 L 22.363521,3.0608867 C 22.985102,2.4447834 22.544601,1.3864752 21.66371,1.3864752 Z";

const Filter = (props) => (
    <SvgIcon {...props} viewBox={'-4 -4 28 28'}>
        <path d={path}/>
    </SvgIcon>
);


export default Filter;
export {path};
