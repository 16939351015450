import React from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {LCal, LCalFormatter} from "react-canvas-timeline";
import {Map, Set} from 'immutable';
import Config from '../../config/config.js';
import TransferHandler from '../../transfer/transferhandler.js';
import StoryCheckbox from "../../styling/storycheckbox";

class RelationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedBackRefs: new Set(),
            linktypeID2backRefJSON: new Map()
        }
    }

    loadBackRefs(linktypeid) {
        //Holen der Rückreferenzen
        let transferObj = {
            linktypeid,
            dataid: this.props.id
        }
        let successFunc = (json) => {
            const linktypeID2backRefJSON = this.state.linktypeID2backRefJSON.set(linktypeid, json);
            this.setState({linktypeID2backRefJSON});
        }
        let errorFunc = (json) => {
        }
        TransferHandler.request(Config.getLinksByLinktypeURL(), successFunc, errorFunc, transferObj);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.id !== this.props.data.id) {
            this.setState({
                linktypeID2backRefJSON: new Map(),
                expandedBackRefs: new Set()
            });
        }
    }

    render() {
        const getQualifiedDiv = (keyCnt, val) => {
            let qualifierArr = [];
            let keyCnt2 = 0;
            let valName = val.name || LCalFormatter.formatStartEnd(val);

            if (val.qualifiers) {
                for (const qual of val.qualifiers) {
                    let valStr = qual.value;
                    if (qual.value && qual.value.year && qual.value.month && qual.value.day && qual.value.precision) {
                        let lcal = new LCal().initYMDHM(qual.value.year, qual.value.month, qual.value.day).setPrecision(qual.value.precision);
                        if (qual.value.precision >= 11) {
                            valStr = LCalFormatter.formatDayNameL(lcal) + ", " + LCalFormatter.formatDate(lcal);
                        } else {
                            valStr = LCalFormatter.formatDate(lcal);
                        }
                    }
                    qualifierArr.push(<div
                        key={keyCnt2 + " " + qual.name + ": " + qual.value}>{qual.name + ": " + valStr}</div>);
                    keyCnt2++;
                }
            }

            //onClick={()=>(this.props.onSearch && this.props.onSearch("ID="+val.id))}
            const isPending = this.props.pendingResourceIDs && this.props.pendingResourceIDs.includes(val.id)

            return <div key={keyCnt} style={{paddingLeft: 15}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <StoryCheckbox
                        disabled={isPending}
                        checked={this.props.model.getResourceModel().getItemByID(val.id) ? true : isPending}
                        onChange={() => this.props.onToggleResourceVisibility && this.props.onToggleResourceVisibility(val.id)}
                        className={"storyCheckbox"}
                    />
                    <Typography style={{cursor: "pointer", color: "#4C68A7", marginLeft: 10}}
                                onClick={() => this.props.onToggleResourceVisibility && this.props.onToggleResourceVisibility(val.id)}>{valName}
                    </Typography>
                </div>


                {val.description && <Typography
                    variant='caption'>{val.description}
                </Typography>}
                <Typography variant='caption'>{qualifierArr}
                </Typography>
            </div>
        }

        //Zunächst die Wikidata-Referenzen aufbereiten
        let refs = [];
        let bgGray = true;
        let keyCnt = 0;
        if (this.props.data.wdreferences) {
            for (const reftype of Object.keys(this.props.data.wdreferences)) {
                let i = reftype.indexOf("_");
                let reftypename = reftype.substring(i + 1);
                let values = this.props.data.wdreferences[reftype];
                let valueDivs = [];
                for (const val of values) {
                    valueDivs.push(getQualifiedDiv(keyCnt, val));
                    keyCnt++;
                }
                let ref = <div key={keyCnt + " " + reftypename}
                               style={{background: bgGray ? "#EEE" : "white", padding: 5}}>
                    <Typography><b>{reftypename}</b></Typography>
                    {valueDivs}
                </div>
                refs.push(ref);
                keyCnt++;
                bgGray = !bgGray;
            }
        }
        //Nun die Linktypes der verweisenden Ereignisse
        let refsByTypes = [];
        if (this.props.data.refbylinktypes) {
            for (const refbyType of this.props.data.refbylinktypes) {
                const json = this.state.linktypeID2backRefJSON.get(refbyType.id);

                let valueDivs = [];
                bgGray = false;
                if (json && json.refbylinktypes) {

                    for (const val of json.refbylinktypes) {
                        valueDivs.push(getQualifiedDiv(keyCnt, val));
                        keyCnt++;
                        bgGray = !bgGray;
                    }
                }


                refsByTypes.push(<Accordion expanded={this.state.expandedBackRefs.includes(refbyType.id)}
                                                 onChange={() => {
                                                     let expandedBackRefs;
                                                     if (this.state.expandedBackRefs.includes(refbyType.id)) {
                                                         expandedBackRefs = this.state.expandedBackRefs.delete(refbyType.id);
                                                     } else {
                                                         expandedBackRefs = this.state.expandedBackRefs.add(refbyType.id);
                                                         //Nachladen der Details für diesen Linktyp
                                                         this.loadBackRefs(refbyType.id);
                                                     }
                                                     this.setState({expandedBackRefs})
                                                 }}
                                                 key={keyCnt}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="subtitle1" gutterBottom>
                                {refbyType.name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display: 'block'}}>
                            <div>
                                {
                                    valueDivs
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
                keyCnt++;
            }
        }

        return <div style={{width: "100%"}}>
            {(refs.length>0 || refsByTypes.length>0) && <>
            {
                refs
            }
                <br/>
            {
                refsByTypes
            }
                </>
            }
        </div>
    }
}

export default RelationList;
