import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import LazyImageUploadCrop from "../../ui-components/lazyimageuploadcrop";
import TransferHandler from "../../transfer/transferhandler";
import Config from "../../config/config";
import {Typography} from "@material-ui/core";
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import i18n from "../../i18n/i18n";

const ChooseImageDialog = ({open, onOk, onCancel, item}) => {
    const [generatedFilename, setGeneratedFilename] = useState("");
    const [origImageFilename, setOrigImageFilename] = useState("");
    const [imageBase64, setImageBase64] = useState("");
    const [src, setSrc] = useState((item && item.src) || "");
    const [title, setTitle] = useState((item && item.title) || "");
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const saveImage = (imageBase64, imageName, callback) => {
        setUploadInProgress(true);

        const onSuccess = function (json) {
            callback && callback(json.url);
            setUploadInProgress(false);
        };

        let onError = function (code, json) {
            if (code === 999) {
                this.sessionIsInvalid();
            }
            callback && callback();
            setUploadInProgress(false);
        }

        TransferHandler.request(Config.getSaveBase46ImageURL(), onSuccess, onError, {imageBase64, imageName});
    }

    const okClicked = () => {
        //imageBase64 vorhanden? Hochladen und URL eintragen
        if(imageBase64 && imageBase64.length>0) {
            saveImage(imageBase64, origImageFilename, (src) => {
                setSrc(src);
                onOk({...item, src, title});
            })
        } else {
            onOk({...item, src, title});
        }
    }

    return <Dialog open={open}
                   onClose={onCancel}
    >
        <DialogTitle>Bildauswahl</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            Lade ein Bild hoch oder gib die URL eines bestehenden Bildes an
            <LazyImageUploadCrop generatedFilename={generatedFilename}
                             origImageFilename={origImageFilename}
                             imageBase64={imageBase64}
                             onFileChange={(origImageFilenameP, imageBase64P) => {setOrigImageFilename(origImageFilenameP); setSrc(origImageFilenameP); setImageBase64(imageBase64P); setGeneratedFilename("")}}/>
            {uploadInProgress && <Typography>Upload...</Typography>}

            <TextField
                fullWidth
                label={i18n("chooseImageDialog.js542504966")}
                value={title}
                onChange={(evt)=>setTitle(evt.target.value)}
            />

            <TextField
                fullWidth
                label="URL"
                value={src}
                onChange={(evt)=>setSrc(evt.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onCancel}/>
            <OKButton onClick={okClicked}/>
        </DialogActions>
    </Dialog>

}

export default ChooseImageDialog;
