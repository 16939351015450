import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const WikiboxSubHeader = ({expanded, onChange, name}) => {
  return <span style={{display: 'flex', background: 'rgba(100,100,100,.1)', padding: '0 10px', justifyContent: 'space-between', alignItems: 'center',
    cursor: "pointer",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  }} onClick={(evt) => onChange(evt, !expanded)}>
    <h2>{name}</h2>
    <div style={{transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'}}>
      <ExpandMoreIcon />
    </div>
  </span>
}

export default WikiboxSubHeader;
