import i18n from "../../i18n/i18n";
import {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Tasklist from "../../ui-components/tasklist";
import types from "../types";

const getNewItem = (id) => {
  let item = {
    "type": types.LINKTYPE_EVENT,
    "eventid": id
  }

  return item;
}

const ChooseEventLinkPanel = ({onOK, model}) => {
  let [filterValue, setFilterValue] = useState("");
  return <>
    <TextField id="filterField" label={i18n('filter')}
               value={filterValue}
               onChange={(evt) => setFilterValue(evt.target.value)} fullWidth/>
    <Tasklist tasks={model.getAllBaseData()}
              model={model}
              onTaskShowDetails={t => onOK(getNewItem(t.id))}
              showDetails={false}
              filterValue={filterValue}
    />
  </>
}

export default ChooseEventLinkPanel;
