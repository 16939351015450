import React from "react";
import i18n from "./i18n/i18n";

let displayPage = (content, url, dataLoadedCallback, showReadMeCallback) => {
    if (content === "") {
        showReadMeCallback(i18n('loading')+" ...");
        fetch(url).then(function (response) {
            response.text().then(function (data) {
                    let d = <div dangerouslySetInnerHTML={{__html: "<div>" + data + "</div>"}}/>
                    dataLoadedCallback(d);
                    showReadMeCallback(d);
                }
            );
        });
    } else {
        showReadMeCallback(content);
    }
}

export {displayPage}
