import React from "react";
import StoryDetailsBlock from "../../display/storydetailsblock";
import Paper from "@material-ui/core/Paper";
import HistoSuspense from "../../../histosuspense";
import DrafteditorWrapper from "../../../drafteditor/drafteditorwrapper";
import computedense from "../../../drafteditor/computedense";
import i18n from "../../../i18n/i18n";

const SourceReferenceInput = ({sourcereferenceEditorState, onSourceReferenceEditorStateChanged, model, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, pendingResourceIDs, mapDescriptor, innerWidth, bgColor}) => {
  return  <HistoSuspense>
    <StoryDetailsBlock name={i18n("sourcereferenceinput.js783166353")} width={innerWidth} color={bgColor} help={'index.html#_quellnachweise'}>
      <Paper elevation={5}>
        <DrafteditorWrapper
            editorState={sourcereferenceEditorState}
            onChange={(val) => onSourceReferenceEditorStateChanged(val)}
            readOnly={false}
            model={model}
            onEventLink={onHighlight}
            onStoryLink={(id) => onToggleResourceVisibility(id)}
            onMapLink={onShowMap}
            onFilterLink={onFilterClicked}
            pendingResourceIDs={pendingResourceIDs}
            legacyMapDescriptor={mapDescriptor}
            dense={computedense(innerWidth)}
        />
      </Paper>
    </StoryDetailsBlock>
  </HistoSuspense>
}

export default SourceReferenceInput;
