import React, {useEffect, useState} from "react";
import StoryDetailsBlock from "./storydetailsblock";
import MyButton from "../../ui-components/buttons/text/mybutton";
import DrafteditorWrapper from "../../drafteditor/drafteditorwrapper";
import Typography from "@material-ui/core/Typography";
import ImageWithLicense from "../../ui-components/imagewithlicense";
import Paper from "@material-ui/core/Paper";
import GoogleMap from "../../media/lazygooglemap";
import WikiBox from "../../wiki/wikibox";
import RelationsMain from "../../ui-components/relations/relationsmain";
import CircularProgress from "@material-ui/core/CircularProgress";
import FurtherInfo from "../../ui-components/furtherinfo";
import TaskListDIV from "./tasklistdiv";
import ConverterUtils from "../../drafteditor/utils/ConverterUtils";
import {getImageURL} from "../../ui-components/dataimage";
import EditorUtils from "../../drafteditor/utils/EditorUtils";
import computedense from "../../drafteditor/computedense";
import i18n from "../../i18n/i18n";
import LockIcon from "@material-ui/icons/Lock";
import LockWarning from "../../ui-components/lockwarning";

const DisplayStoryDetails = ({
                                 data,
                                 model,
                                 innerWidth,
                                 onPublish,
                                 onPricing,
                                 onHighlight,
                                 onFilterClicked,
                                 onToggleResourceVisibility,
                                 onTaskShowDetails,
                                 onShowMap,
                                 onResShowNeighbourhood,
                                 onSearch,
                                 onShowSourceReferences,
                                 onInThisTime,
                                 pendingResourceIDs,
                                 showPublish
                             }) => {
    const desc = (data && data.description) || "";
    const [eventListExpanded, setEventListExpanded] = useState(false);
    //Der DraftEditor macht nach dem initialisieren mit dem EditorState ein Callback, damit die Links korrekt angezeigt werden.
    //Deshalb braucht es setDescription hier. useEffect und setKey benötigt es, damit beim Tabwechsel korrekt geupdated wird, sonst würde der Zustand auf der alten Description stehenbleiben.
    const [descriptionEditorState, setDescriptionEditorState] = useState(ConverterUtils.toEditorStateFromObject(data, 'description', false));
    const [sourcereferenceEditorState, setSourcereferenceEditorState] = useState(ConverterUtils.toEditorStateFromObject(data, 'sourcereference', false));
    const [descriptionBlockKey, setDescriptionBlockKey] = useState("desc" + Date.now());
    const [sourcereferenceBlockKey, setSourcereferenceBlockKey] = useState("sourceref" + Date.now());

    useEffect(() => {
        setDescriptionEditorState(ConverterUtils.toEditorStateFromObject(data, 'description', false));
        setSourcereferenceEditorState(ConverterUtils.toEditorStateFromObject(data, 'sourcereference', false));
        setDescriptionBlockKey("desc" + Date.now());
        setSourcereferenceBlockKey("sourceref" + Date.now());
    }, [desc, data]);

    const imagelicense = (data && data.imagelicense) || "";

    const id = data ? data.proxyID || data.id : 0;
    const res = model.getResourceModel().getItemByID(id);
    let itemsInTimeline = model.getItemsByResourceID(data.id);
    if (!eventListExpanded && itemsInTimeline.length > 5) {
        itemsInTimeline = itemsInTimeline.slice(0, 5);
    }
    const isRowLayout = innerWidth > 500;

    let blockCnt = 0;
    const currentBlockColor = () => {
        return blockCnt % 2 === 0 ? '#FFFFFF' : '#F6F6F6';
    }
    const nextBlockColor = () => {
        blockCnt++;
        return currentBlockColor();
    }

    const showTopShadow = () => {
        return blockCnt !== 0;
    }

    const isEditorStateNotEmpty = EditorUtils.isEditorStateNotEmpty(descriptionEditorState);
    const isSourcereferenceEditorStateNotEmpty = EditorUtils.isEditorStateNotEmpty(sourcereferenceEditorState);

    return <div>
        <div style={{display: "flex", flexDirection: "column", marginTop: 40}}>
            {showPublish && <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}>
                    <span style={{fontSize: 20, color: "white"}}>
                        {i18n('displayStoryDetails.publishPrompt')}&nbsp;
                        <MyButton onClick={onPublish}>{i18n('displayStoryDetails.publishNow')}</MyButton>
                    </span>
            </StoryDetailsBlock>}

            {data.locked && <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}>
                <LockWarning onPricingClicked={()=>onPricing(true)} text={<>{i18n('displayStoryDetails.storyProtected')}<br/>{i18n('displayStoryDetails.textScrambled')}<br/>{i18n('displayStoryDetails.learnMoreFullAccess')}</>}/>
            </StoryDetailsBlock>}


            <div style={{position: "relative"}}>
                <div>
                    {isEditorStateNotEmpty &&
                        <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}
                                           key={descriptionBlockKey} noTopBottomMargin>
                            <DrafteditorWrapper
                                editorState={descriptionEditorState}
                                onChange={(val => setDescriptionEditorState(val))}
                                readOnly={true}
                                model={model}
                                onEventLink={(eventid) => onHighlight(eventid)}
                                onStoryLink={(storyid) => onToggleResourceVisibility(storyid)}
                                onMapLink={onShowMap}
                                onFilterLink={onFilterClicked}
                                pendingResourceIDs={pendingResourceIDs}
                                legacyMapDescriptor={data.mapDescriptor}
                                dense={computedense(innerWidth)}
                            />
                        </StoryDetailsBlock>
                    }
                    {data.wikipage &&
                        <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}>
                            <WikiBox
                                site={data.wikipage}
                                model={model}
                                pendingResourceIDs={pendingResourceIDs}
                                onToggleResourceVisibility={(id) => onToggleResourceVisibility(id)}
                                onHighlight={(taskID) => onHighlight(taskID)}
                                onShowMap={(lat, lng) => onShowMap(lat, lng)}
                                width={innerWidth - 40}
                                expanded={!isEditorStateNotEmpty}
                            />
                        </StoryDetailsBlock>}
                    {res && itemsInTimeline.length > 0 && (window.innerWidth > 1000 ?
                            <StoryDetailsBlock name={i18n("chronology")} width={innerWidth} topShadow={showTopShadow()}
                                               color={nextBlockColor()}>
                                <>
                                    <Typography variant="caption"
                                                style={{color: "gray"}}>{model.size()} {i18n("displaystorydetails.js463453345")}</Typography>
                                    {getImageURL(data, model) ? <table
                                            style={{width: "100%", tableLayout: 'fixed'}}>
                                            <tbody>
                                            <tr>
                                                <td style={{wordBreak: 'break-word'}}>
                                                    <TaskListDIV itemsInTimeline={itemsInTimeline}
                                                                 eventListExpanded={eventListExpanded}
                                                                 model={model}
                                                                 onTaskShowDetails={onTaskShowDetails}
                                                                 onHighlight={onHighlight}
                                                                 onFilterClicked={onFilterClicked}
                                                                 onClick={() => setEventListExpanded(
                                                                     !eventListExpanded)}
                                                                 pendingResourceIDs={pendingResourceIDs}
                                                                 onShowMap={onShowMap}
                                                                 onToggleResourceVisibility={onToggleResourceVisibility}
                                                                 background={currentBlockColor()}
                                                    />
                                                </td>
                                                <td style={{verticalAlign: 'top', paddingLeft: 20}}>
                                                    <ImageWithLicense
                                                        data={data}
                                                        model={model}
                                                        onToggleResourceVisibility={onToggleResourceVisibility}
                                                        onHighlight={onHighlight}
                                                        onFilterClicked={onFilterClicked}
                                                        onShowMap={onShowMap}
                                                        pendingResourceIDs={pendingResourceIDs}
                                                        minWidth={300}
                                                        imagelicense={imagelicense}
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        :
                                        <TaskListDIV itemsInTimeline={itemsInTimeline}
                                                     eventListExpanded={eventListExpanded}
                                                     model={model}
                                                     onTaskShowDetails={onTaskShowDetails}
                                                     onHighlight={onHighlight}
                                                     onFilterClicked={onFilterClicked}
                                                     onClick={() => setEventListExpanded(
                                                         !eventListExpanded)}
                                                     pendingResourceIDs={pendingResourceIDs}
                                                     onShowMap={onShowMap}
                                                     onToggleResourceVisibility={onToggleResourceVisibility}
                                                     background={currentBlockColor()}
                                        />
                                    }
                                </>
                            </StoryDetailsBlock>
                            :
                            <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}>
                                <ImageWithLicense
                                    data={data}
                                    model={model}
                                    onToggleResourceVisibility={onToggleResourceVisibility}
                                    onHighlight={onHighlight}
                                    onFilterClicked={onFilterClicked}
                                    onShowMap={onShowMap}
                                    pendingResourceIDs={pendingResourceIDs}
                                    minWidth={300}
                                    imagelicense={imagelicense}
                                />
                                <Typography variant="h2" style={{fontSize: 28}}>{i18n("chronology")}</Typography>
                                <TaskListDIV itemsInTimeline={itemsInTimeline}
                                             eventListExpanded={eventListExpanded}
                                             model={model}
                                             onTaskShowDetails={onTaskShowDetails}
                                             onHighlight={onHighlight}
                                             isRowLayout={isRowLayout}
                                             innerWidth={innerWidth}
                                             onClick={() => setEventListExpanded(!eventListExpanded)}
                                             pendingResourceIDs={pendingResourceIDs}
                                             onShowMap={onShowMap}
                                             onToggleResourceVisibility={onToggleResourceVisibility}
                                             background={currentBlockColor()}
                                />
                            </StoryDetailsBlock>
                    )}

                    {data && data.longitude && data.latitude &&
                        <StoryDetailsBlock name={i18n('displayStoryDetails.map')} width={innerWidth} topShadow={showTopShadow()}
                                           color={nextBlockColor()}>
                            <Paper style={{overflow: "hidden", marginBottom: 20, border: "1px solid gray"}}
                                   elevation={5}
                                   className={"mediaworkaround"}>
                                <GoogleMap id="Map"
                                           initialCenter={{
                                               "lat": data.latitude,
                                               "lng": data.longitude
                                           }}
                                           center={{
                                               "lat": data.latitude,
                                               "lng": data.longitude
                                           }}
                                           zoom={5}
                                           height={300}
                                           width={innerWidth - 20}
                                >
                                    {
                                        [
                                            {
                                                type: "marker",
                                                title: i18n('displayStoryDetails.position'),
                                                lat: data.latitude,
                                                lng: data.longitude
                                            }
                                        ]
                                    }
                                </GoogleMap>
                            </Paper>
                        </StoryDetailsBlock>
                    }

                    {((data.wdreferences && Object.keys(data.wdreferences).length > 0) || (data.refbylinktypes && data.refbylinktypes.length > 0)) && (data.isFullyLoaded ?
                        <StoryDetailsBlock name={i18n('displayStoryDetails.mindmap')} width={innerWidth} topShadow={showTopShadow()}
                                           color={nextBlockColor()}>
                            <RelationsMain id={data.proxyID || data.id}
                                           width={innerWidth - 40}
                                           data={data}
                                           model={model}
                                           pendingResourceIDs={pendingResourceIDs}
                                           onToggleResourceVisibility={(id) => onToggleResourceVisibility(id)}
                                           onHighlight={(taskID) => onHighlight(taskID)}
                                           onShowMap={(lat, lng) => onShowMap(lat, lng)}
                                           onSearch={(expression) => onSearch(expression)}/><br/>
                        </StoryDetailsBlock>
                        :
                        <CircularProgress/>)
                    }
                    {isSourcereferenceEditorStateNotEmpty &&
                        <StoryDetailsBlock name={i18n("displaystorydetails.js158045992")} width={innerWidth}
                                           topShadow={showTopShadow()} color={nextBlockColor()}
                                           key={sourcereferenceBlockKey}>
                            <DrafteditorWrapper
                                editorState={sourcereferenceEditorState}
                                onChange={(val => setSourcereferenceEditorState(val))}
                                readOnly={true}
                                model={model}
                                onEventLink={(eventid) => onHighlight(eventid)}
                                onStoryLink={(storyid) => onToggleResourceVisibility(storyid)}
                                onMapLink={onShowMap}
                                onFilterLink={onFilterClicked}
                                pendingResourceIDs={pendingResourceIDs}
                                legacyMapDescriptor={data.mapDescriptor}
                                dense={computedense(innerWidth)}
                            />
                            <div onClick={() => onShowSourceReferences()} style={{
                                color: "#4C68A7",
                                cursor: "pointer"
                            }}>{i18n("displaystorydetails.js121722705")}</div>
                        </StoryDetailsBlock>
                    }

                    <StoryDetailsBlock width={innerWidth} topShadow={showTopShadow()} color={nextBlockColor()}>
                        <FurtherInfo
                            data={data}
                            onResShowNeighbourhood={onResShowNeighbourhood}
                            onInThisTime={onInThisTime}
                            isResource={true}
                        />
                    </StoryDetailsBlock>
                </div>
                {data.locked && <div style={{
                    display: "flex",
                    justifyContent: "center",
                    zIndex: 10000,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(3px)",
                    background: "linear-gradient(to bottom, rgba(200, 200, 200, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%)"
                }}>
                    <LockIcon style={{fontSize: "30vH", marginTop: 40}}/>
                </div>}
            </div>
        </div>
    </div>
}

export default DisplayStoryDetails;
