const findObjectsWithType = (object, type, resultList = []) => {
    for (const property in object) {
        if (object.hasOwnProperty(property)) {
            const value = object[property];

            if (typeof value === 'object') {
                if (value && value.type === type) {
                    resultList.push(value);
                }
                findObjectsWithType(value, type, resultList);
            }
        }
    }
    return resultList;
}

export {findObjectsWithType};