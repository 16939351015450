import React from "react";
import MyButton from "../ui-components/buttons/text/mybutton";
import BackButton from "../ui-components/buttons/text/backbutton";
import i18n from "../i18n/i18n";

const Checkout = ({onBack, onJumpToLicenseAdmin, buyReference, price, country}) => {
    if(country === "DEU") {
        return <div>
            {i18n('checkout.pleasePayAmount')}&nbsp;
            <b>{Number.isInteger(price) ? price.toLocaleString("de-DE") : Number.parseFloat(price).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })} €</b> {i18n('checkout.within14Days')}
            <br/>
            <br/>
            <div style={{background: '#EFEFEF', padding: 10, border: '1px solid lightgray'}}>
                {i18n('checkout.peterRankSoftware')}
                <br/>
                {i18n('checkout.accountNumber')}
                <br/>
                {i18n('checkout.bankCode')}
                <br/>
                {i18n('checkout.paymentReference')} <b>{buyReference}</b>
            </div>

            <br/>
            {i18n('checkout.payConveniently')}
            <br/>
            <br/>
            <MyButton style={{color: "red", background: "rgba(0,0,0,0)",  boxShadow: "inherit"}}
                      primary={false}
                      onClick={() => window.open("https://book.stripe.com/6oE9ASbKP9MPgRqfYY", '_blank')}>Weiter zum
                Zahlungsdienstleister Stripe</MyButton>
            <br/>
            <br/>
            {i18n('checkout.specifyReference')} <b>{buyReference}</b> im Betreff mit an.
            <br/>
            {i18n('checkout.findNumberProfile')}
            <br/>
            {i18n('checkout.configureLicenses')}
            <br/>
            <MyButton onClick={onJumpToLicenseAdmin} style={{color: "red", background: "rgba(0,0,0,0)", boxShadow: "inherit"}} primary={false}>{i18n('checkout.toLicenseAdmin')}</MyButton>
            <br/>
            {i18n('checkout.noNeedToWait')}
            <br/>
            {i18n('checkout.fullAccess')}
            <br/>
            <br/>
            <BackButton onClick={onBack}/>
            <br/>
            <br/>
            <br/>
        </div>
    } else {
        return <div>
            {i18n('checkout.reviewOrder')}
            <br/>
            <br/>
            {i18n('checkout.noNeedToWaitInvoice')}
            <br/>
            {i18n('checkout.fullAccess')}
            <br/>
            <MyButton onClick={onJumpToLicenseAdmin} style={{color: "red", background: "rgba(0,0,0,0)", boxShadow: "inherit"}} primary={false}>{i18n('checkout.toLicenseAdminLower')}</MyButton>
            <br/>
        </div>
    }
}


    export default Checkout;
