import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import NoButton from "../../ui-components/buttons/text/nobutton";
import YesButton from "../../ui-components/buttons/text/yesbutton";
import i18n from "../../i18n/i18n";

const DeleteDialog = ({open, onDelete, onClose}) => (
  <Dialog open={open} onClose={() => onClose()}>
    <DialogTitle>{i18n('deleteDialog.title')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {i18n('deleteDialog.confirmationText')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <NoButton onClick={() => onClose()} />
      <YesButton onClick={() => onDelete()} />
    </DialogActions>
  </Dialog>
)

export default DeleteDialog;
