import React from "react";
import i18n from "../i18n/i18n";

const Explanation = () => (
    <div style={{fontSize: '1.2rem'}}>
      {i18n("explanation")()}
    </div>
)

export default Explanation;
