import StoryDetailsBlock from "../../display/storydetailsblock";
import React from "react";
import Paper from "@material-ui/core/Paper";
import DrafteditorWrapper from "../../../drafteditor/drafteditorwrapper";
import HistoSuspense from "../../../histosuspense";
import computedense from "../../../drafteditor/computedense";
import i18n from "../../../i18n/i18n";

const DescriptionInput = ({descriptionEditorState, onDescriptionEditorStateChanged, model, onHighlight, onFilterClicked, onToggleResourceVisibility, onShowMap, pendingResourceIDs, mapDescriptor, innerWidth, bgColor}) => {
  return <HistoSuspense>
    <StoryDetailsBlock name={i18n("descriptioninput.js517543907")} width={innerWidth} color={bgColor} help={'index.html#_beschreibung'}>
      <Paper elevation={5}>
        <DrafteditorWrapper
            editorState={descriptionEditorState}
            onChange={(val) => onDescriptionEditorStateChanged(val)}
            readOnly={false}
            model={model}
            onEventLink={onHighlight}
            onStoryLink={(id) => onToggleResourceVisibility(id)}
            onMapLink={onShowMap}
            onFilterLink={onFilterClicked}
            pendingResourceIDs={pendingResourceIDs}
            legacyMapDescriptor={mapDescriptor}
            dense={computedense(innerWidth)}
        />
      </Paper>
    </StoryDetailsBlock>
  </HistoSuspense>
}

export default DescriptionInput;
