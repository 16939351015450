import React from "react";
import TopFactItem from "./topfactitem";
import SearchIcon from "@material-ui/icons/Search";
import EuroIcon from "@material-ui/icons/EuroSymbol";
import DevicesOtherIcon from "@material-ui/icons/Devices";
import CompareIcon from "@material-ui/icons/Compare";
import MeasureIcon from "@material-ui/icons/Straighten";
import PublicIcon from "@material-ui/icons/Public";
import GroupIcon from "@material-ui/icons/Group";
import ShareIcon from "@material-ui/icons/Share";
import SunIcon from "@material-ui/icons/WbSunny";
import EditIcon from "@material-ui/icons/Edit";
import IntegrateIcon from "@material-ui/icons/Web";
import FictionIcon from "@material-ui/icons/PhotoFilter";
import Grid from "@material-ui/core/Grid";
import i18n from "../i18n/i18n";

const TopFacts = () => (
            <Grid container spacing={3}>
                            <TopFactItem icon={<PublicIcon/>} title={i18n("topfacts.js415020171")} body={i18n("topfacts.js645936163")}/>
                            <TopFactItem icon={<CompareIcon/>} title={i18n("topfacts.js111683501")} body={i18n("topfacts.js751322491")}/>
                            <TopFactItem icon={<IntegrateIcon/>} title={i18n("topfacts.js56475656")} body={i18n("topfacts.js921529656")}/>
                            <TopFactItem icon={<EuroIcon/>} title={i18n("topfacts.js939157228")} body={i18n("topfacts.js895080977")}/>
                            <TopFactItem icon={<MeasureIcon/>} title={i18n("topfacts.js617723463")} body={i18n("topfacts.js948823105")}/>
                            <TopFactItem icon={<EditIcon/>} title={i18n("topfacts.js692398414")} body={i18n("topfacts.js107256625")}/>
                            <TopFactItem icon={<FictionIcon/>} title={i18n("topfacts.js899063949")} body={i18n("topfacts.js102762366")}/>
                            <TopFactItem icon={<GroupIcon/>} title={i18n("topfacts.js152860411")} body={i18n("topfacts.js373990748")}/>
                            <TopFactItem icon={<ShareIcon/>} title={i18n("topfacts.js55830277")} body={i18n("topfacts_wiki")()}/>
                            <TopFactItem icon={<SearchIcon/>} title={i18n("topfacts.js335278155")} body={i18n("topfacts.js809530473")}/>
                            <TopFactItem icon={<DevicesOtherIcon/>} title={i18n("topfacts.js150418448")} body={i18n("topfacts.js15371141")}/>
                            <TopFactItem icon={<SunIcon/>} title={i18n("topfacts.js820645511")} body={i18n("topfacts.js678201177")}/>
            </Grid>
            )


    export default TopFacts;
