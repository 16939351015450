/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 *
 * Hilfsfunktionen zum Ausführen von Sonderfunktionen je nach Anwendungsfall
 */

import React from 'react';
import HistoSuspense from "./histosuspense";

let MyComponent;

export const OnLoadComponent = ({resources, histomaniamain, specialFunction, onClose}) => {

    //TODO: Falls Admin-Rechte da sind, dann ein Admin-Modul nachladen
    //Gibt es für eine Ressource eine SpecialFunction? Dann lade diese und führe sie aus (Nur die erste, dann abbrechen)


    let res;
    if(resources && resources.length === 1) {
        res = resources[0];
    }

    if(!MyComponent) {
        if (specialFunction === "compareToDespots") {
            MyComponent = React.lazy(() => import('./specialfunctions/despot/despotbirthdaycomponent'));
        } else if (specialFunction === "pregnancy") {
            MyComponent = React.lazy(() => import('./specialfunctions/pregnancy/pregnancycomponent'));
        } else if (specialFunction === "admin") {
            MyComponent = React.lazy(() => import('./specialfunctions/admin/admincomponent'));
        }
    }

    if(MyComponent) {
        return <HistoSuspense>
            <MyComponent histomaniamain={histomaniamain} resource={res} onClose={onClose}/>
        </HistoSuspense>
    } else {
        return null;
    }
}
