import HtmlTooltip from "../styling/htmltooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import MeasureIcon from "@material-ui/icons/Straighten";
import SettingsIcon from "../images/icons/settings";
import BookmarkIcon from "@material-ui/icons/StarBorder";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import Story from "../data/story";
import StorybookIcon from '@material-ui/icons/DescriptionOutlined';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddBoxIcon from "@material-ui/icons/AddBox";
import React from "react";
import i18n from "../i18n/i18n";

const canAddEventToResourceByContext = (state) => state.contextMenuResource && state.contextMenuResource.hasAdminRights();

const circularMenu = (state, setState, searchStory, measure, settings, bookmarks, toggleCollapseGroups, initNewResource, createBookingItemFromClick) => (<menu
    className={state.contextMenuOpen ? "circularMenu circularMenuOpen" : "circularMenu"}
    style={{
      position: "absolute",
      top: Math.min(Math.max(state.contextMenuY, 100), window.innerHeight - 140),
      left: Math.min(Math.max(state.contextMenuX, 100), window.innerWidth - 140),
    }}>
  <ul>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js381995722")}</Typography>} enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        searchStory()
      }}>
        <div>
          <SearchIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js524039849")}</Typography>}
                 enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        measure()
      }}>
        <div>
          <MeasureIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js739065959")}</Typography>}
                 enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        settings()
      }}>
        <div>
          <SettingsIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js357509198")}</Typography>} enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        bookmarks()
      }}>
        <div>
          <BookmarkIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js636940232")}</Typography>} enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        toggleCollapseGroups()
      }}>
        <div>
          <UnfoldMoreIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js355370525")}</Typography>}
                 enterDelay={200}>
      <li onClick={() => {
        setState({contextMenuOpen: false});
        initNewResource(new Story())
      }}>
        <div>
          <StorybookIcon style={{fontSize: 35}}/>
        </div>
      </li>
    </HtmlTooltip>
    <HtmlTooltip title={<Typography>{i18n("circularmenu.js753068960")}</Typography>}
                 enterDelay={200}>
      {canAddEventToResourceByContext(state) ?
          <li onClick={() => {
            setState({contextMenuOpen: false});
            createBookingItemFromClick(state.contextMenuResource, state.contextMenuTime, true);
          }}>
            <div>
              <AddCircleIcon style={{fontSize: 35}}/>
            </div>
          </li>
          :
          <li>
            <div style={{color: "lightgray"}}>
              <AddCircleIcon style={{fontSize: 35}}/>
            </div>
          </li>
      }
    </HtmlTooltip>
    <HtmlTooltip
        title={<Typography>{i18n("circularmenu.js653781889")}</Typography>}
        enterDelay={200}>
      {canAddEventToResourceByContext(state) ?
          <li onClick={() => {
            setState({contextMenuOpen: false});
            createBookingItemFromClick(state.contextMenuResource, state.contextMenuTime);
          }}>
            <div>
              <AddBoxIcon style={{fontSize: 35}}/>
            </div>
          </li>
          :
          <li>
            <div style={{color: "lightgray"}}>
              <AddBoxIcon style={{fontSize: 35}}/>
            </div>
          </li>
      }
    </HtmlTooltip>
  </ul>
</menu>);

export default circularMenu;
