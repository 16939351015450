import Typography from "@material-ui/core/Typography";
import i18n from "../i18n/i18n";
import React from "react";

const LoginInfo = ({onLogin, loginUser, loginUserIsDummy}) => (
    <Typography variant="caption"
                style={{
                  color: "gray",
                  cursor: "pointer",
                  textAlign: "right",
                  marginRight: 5,
                  fontSize: '0.6rem'
                }}>
      {loginUserIsDummy ? <span style={{
        border: "1px solid red",
        borderRadius: 4,
        color: "gray",
        padding: "0 5px 0 5px"
      }}
                                onClick={() => onLogin()}>{i18n(
          'loginInfoNotLoggedIn')} {i18n('loginInfoDoLoginHere')}
      </span> : <span style={{whiteSpace: "nowrap"}}>{loginUser}</span>}
    </Typography>
)

export default LoginInfo;
