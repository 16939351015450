import React, {useState} from "react";
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/alignment/lib/plugin.css';
import {renderers, transformLinkUri} from "../markdown/markdownhelper";
import EditorUtils from "./utils/EditorUtils";
import DrafteditorWrapper from "./drafteditorwrapper";

const DraftDisplayWrapper = ({
    content,
  style,
  model,
  onEventLink,
  onStoryLink,
  onMapLink,
  onFilterLink,
  pendingResourceIDs,
  legacyMapDescriptor,
    dense
}) => {
  const [editorState, setEditorState] = useState(EditorUtils.toEditorState(content));

  return <DrafteditorWrapper
      style={style}
      editorState={editorState}
      onChange={editorState => setEditorState(editorState)}
      readOnly={true}
      model={model}
      onEventLink={onEventLink}
      onStoryLink={onStoryLink}
      onMapLink={onMapLink}
      onFilterLink={onFilterLink}
      legacyMarkdownProps={{
        mapDescriptor: legacyMapDescriptor,
        mapDescriptorFallback: true,
        transformLinkUri: (link) => transformLinkUri(link),
        renderers: renderers((resid) => model.getResourceModel().getItemByID(resid), onStoryLink, onEventLink,  onMapLink, pendingResourceIDs, model, legacyMapDescriptor)
      }}
      dense={dense}
  />
}

export default DraftDisplayWrapper;

