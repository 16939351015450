import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Wikidata = (props) => (
    <SvgIcon {...props}>
        <path
            d="M 0.02529877,14.788283 H 0.88706865 V 0.01420847 H 0.02529877 V 14.788283 z m 1.78107073,0 H 4.449027 V 0.01420847 H 1.8063695 V 14.788283 z M 5.3109733,0.01420847 V 14.787755 H 7.9536305 V 0.01420847 H 5.3109733 z"
            style={{fill: "#990000"}}
            id="path4"/>
        <path
            d="m 21.288445,14.789335 h 0.861946 V 0.0142067 H 21.288445 V 14.789335 z M 23.069691,0.0142067 V 14.789335 h 0.861772 V 0.0142067 H 23.069691 z M 8.844076,14.789337 h 0.86177 V 0.01420847 H 8.844076 V 14.789337 z M 10.625327,0.01420847 V 14.78881 h 0.861946 V 0.01420847 h -0.861946 z"
            style={{fill: "#339966"}}
            id="path6"/>
        <path
            d="m 12.377359,14.789337 h 2.643016 V 0.01420847 H 12.377359 V 14.789337 z m 3.504606,0 h 0.919121 V 0.01420847 H 15.881965 V 14.789337 z M 17.663034,0.01420847 V 14.78881 h 2.642657 V 0.01420847 h -2.642657 z"
            style={{fill: "#006699"}}
            id="path8"/>
        <path
            d="m 22.61848,16.415655 1.359771,3.063878 -0.820247,0 -0.252216,-0.605318 -1.370738,0 -0.228095,0.605318 -0.809282,0 1.23476,-3.063878 0.886047,0 m 0.06141,1.923421 -0.493468,-1.190897 -0.449602,1.190897 0.94307,0 m -1.780868,-1.923421 0,0.561455 -1.109751,0 0,2.502423 -0.763226,0 0,-2.502423 -1.109751,0 0,-0.561455 2.982728,0 m -3.947731,0 1.359775,3.063878 -0.820251,0 -0.252218,-0.605318 -1.370737,0 -0.228093,0.605318 -0.809283,0 1.234761,-3.063878 0.886046,0 m 0.06141,1.923421 -0.493464,-1.190897 -0.449602,1.190897 0.943066,0 m -5.217574,-1.923421 1.351,0 c 0.521977,3e-6 0.934296,0.143297 1.236957,0.429864 0.30412,0.285116 0.45618,0.654302 0.456184,1.107557 -4e-6,0.475192 -0.15426,0.848764 -0.462766,1.120718 -0.307045,0.270492 -0.745681,0.405739 -1.315909,0.405739 l -1.265466,0 0,-3.063878 m 0.763229,0.561455 0,1.940967 0.497853,0 c 0.323125,0 0.570223,-0.08846 0.741293,-0.265374 0.171067,-0.178378 0.2566,-0.413049 0.256603,-0.704014 -3e-6,-0.301194 -0.087,-0.538058 -0.260988,-0.710591 -0.172529,-0.173989 -0.421088,-0.260987 -0.745678,-0.260988 l -0.489078,0 m -1.469436,-0.561455 0,3.063878 -0.763226,0 0,-3.063878 0.763226,0 m -1.4453073,0 -0.9978972,1.438729 1.3531931,1.625149 -0.9562288,0 -1.2128284,-1.436536 0,1.436536 -0.7632295,0 0,-3.063878 0.7632295,0 0,1.379512 0.9737721,-1.379512 0.8399892,0 m -3.2831934,0 0,3.063878 -0.7632293,0 0,-3.063878 0.7632293,0 m -3.7591167,1.105364 -0.9474548,1.997991 -0.3289783,0 -1.34661493,-3.103355 0.80928313,0 0.7215572,1.693136 0.7873544,-1.693136 0.6140917,0 0.7873544,1.693136 0.7171686,-1.693136 0.8092867,0 -1.34223,3.103355 -0.3289781,0 -0.95184,-1.997991"
            style={{fill: "#484848", fillOpacity: "1", stroke: "none", fontFamily: "Gill Sans MT"}}
            id="path10"/>
    </SvgIcon>
);


export default Wikidata;
