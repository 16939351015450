import React from 'react';
import ReactDOM from 'react-dom';
import TransferHandler from '../transfer/transferhandler.js';
import Config from '../config/config.js';
import SimpleResourcesTable from './simpleresourcestsable';
import 'whatwg-fetch';
import Details from '../ui-components/details';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {LCal, LCalFormatter} from 'react-canvas-timeline';
import LCalDateField from '../ui-components/lib/datetimepicker/lcaldatefield';
import smoothScroll from '../ui-components/lib/smoothscroll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import SearchIcon from '@material-ui/icons/Search';
import CurrentViewIcon from '@material-ui/icons/Visibility';
import ShortlyViewedIcon from '@material-ui/icons/Restore';
import MyStoriesIcon from '@material-ui/icons/Person';

import GoogleMap from '../media/lazygooglemap';
import MyButton from "../ui-components/buttons/text/mybutton";
import i18n from "../i18n/i18n";
import theme from "../theme";

const redCol = theme.palette.primary.main;
const blueCol = theme.palette.secondary.main;
const minSearchWordCharCnt = 4;
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.activeTab === 'recentlySeen' ? 2: (this.props.activeTab === 'myStories' ? 3 : 0),
            recentResources: [],
            myResources: [],
            searchPhrase: this.props.searchPhrase ? this.props.searchPhrase : "",
            searchFrom: this.props.searchFrom ? this.props.searchFrom : "__.__.",
            searchTo: this.props.searchTo ? this.props.searchTo : "__.__.",
            searchGeoType: this.props.searchGeoType ? this.props.searchGeoType : "everywhere",
            searchGeoLon: this.props.searchGeoLon ? this.props.searchGeoLon : "",
            searchGeoLat: this.props.searchGeoLat ? this.props.searchGeoLat : "",
            hasMoreSearchResults: false,
            resHitCnt: -1,
            searchResultResources: [],
            isInInitialSearch: false,
            warning: '',
            expandedSearchDiv: this.props.searchGeoType === 'choosenNeighbourhood' || this.props.searchGeoType === 'myNeighbourhood' ? 'place' : (this.props.searchFrom || this.props.searchTo ? "date" : (window.innerWidth > 1000 ? "text" : "")),
            centerLat: "51.19712053778916",
            centerLon: "9.59924024999998"
        };
        this.nextResourceSearchStartIndex = 0;
        this.lastSearchRequestTime = 0;
        this.scrollContainer = null;
    }

    componentDidMount() {
        //Holen der (gefilterten) Ressourcen
        let successFunc = (json) => {
            this.setState({
                recentResources: json.recent,
                myResources: json.myResources
            });
        }
        let errorFunc = (code, json) => {

        }
        TransferHandler.request(Config.getRecommendedRessourcesURL(), successFunc, errorFunc, {});

        if(this.props.searchGeoType === 'myNeighbourhood') {
            this.changeGeoSelection('myNeighbourhood', ()=> {
                this.search(true);
                this.scrollToSearchResult();
            });
        } else if (this.state.searchPhrase.length > 0 || (this.props.searchGeoLon && this.props.searchGeoLat)) {
            this.searchInputChanged(() => {
                this.search(true);
                this.scrollToSearchResult();
            });
        } else if (this.props.searchFrom || this.props.searchTo) {
            this.search(true);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //Vorher keine Resultate und jetzt schon -> Scrolle zu den Ergebnissen
        if (prevState.searchResultResources.length === 0 && this.state.searchResultResources.length > 0) {
            this.scrollToSearchResult();
        }

        //Von aussen gesetzte geänderte Suchphrase?
        let state = {};
        if (prevProps.searchPhrase && prevProps.searchPhrase !== this.props.searchPhrase) {
            state.searchPhrase = this.props.searchPhrase;
        }
        if (prevProps.searchGeoType && prevProps.searchGeoType !== this.props.searchGeoType) {
            state.searchGeoType = this.props.searchGeoType;
        }
        if (prevProps.searchGeoLon && prevProps.searchGeoLon !== this.props.searchGeoLon) {
            state.searchGeoLon = this.props.searchGeoLon;
        }
        if (prevProps.searchGeoLat && prevProps.searchGeoLat !== this.props.searchGeoLat) {
            state.searchGeoLat = this.props.searchGeoLat;
        }

        if (Object.getOwnPropertyNames(state).length > 0) {
            this.setState(state, () => {
                    this.searchInputChanged(() => {
                        this.search(true);
                        this.scrollToSearchResult();
                    });
                }
            );
        }
    }

    scrollToSearchResult() {
        //Finden des Suchen-Buttons und scrollen bis an dessen Ende
        setTimeout(() => {
            const searchResultNode = ReactDOM.findDOMNode(this.refs.searchResult);
            if (searchResultNode && this.scrollContainer) {
                smoothScroll(searchResultNode.offsetTop , 500, this.scrollContainer);
            }
        }, 0);
    }

    searchInitial() {
        this.search(true);
    }

    searchNeighbourhood() {
        this.changeGeoSelection("myNeighbourhood", () => this.search(true));
    }

    searchNeighbourhoodByCoords(searchGeoLon, searchGeoLat) {
        this.setState({
            searchGeoLon: ("" + searchGeoLon).replace(".", ","),
            searchGeoLat: ("" + searchGeoLat).replace(".", ","),
            searchPhrase: "",
            searchFrom: null,
            searchTo: null,
            searchGeoType: "choosenNeighbourhood"
        }, () => this.search(true));
    }

    search(isInitial) {
        if (isInitial) {
            //this.setState({searchResultResources: []});
            this.nextResourceSearchStartIndex = 0;
            this.setState({
                hasMoreSearchResults: false,
                isInInitialSearch: true,
                searchResultResources: []
            }, () => this._search(isInitial));
        } else {
            if (this.state.hasMoreSearchResults) {
                this._search(false);
            }
        }
    }

    _search(isInitial) {
        this.lastSearchRequestTime = Date.now();
        let requestTime = this.lastSearchRequestTime;

        //Einen Tag zufügen, da inklusive des letzten Tages gesucht werden soll
        let adaptedSearchTo = this.state.searchTo instanceof LCal ? this.state.searchTo.clone().addDay(1) : null;

        let lon = parseFloat(this.state.searchGeoLon.replace(",", "."));
        if (Number.isNaN(lon)) {
            lon = null;
        }
        let lat = parseFloat(this.state.searchGeoLat.replace(",", "."));
        if (Number.isNaN(lat)) {
            lat = null;
        }

        let searchPhraseArray = [];
        for (let word of this.state.searchPhrase.split(" ")) {
            if (word.trim().length >= minSearchWordCharCnt) {
                searchPhraseArray.push(word);
            }
        }

        //Suchen von Ressourcen
        let transferObj = {
            searchPhrase: searchPhraseArray.join(' '),
            searchFrom: this.state.searchFrom instanceof LCal ? this.state.searchFrom.getJulianMinutes() : null,
            searchTo: adaptedSearchTo instanceof LCal ? adaptedSearchTo.getJulianMinutes() : null,
            searchLon: lon,
            searchLat: lat,
            startIndex: this.nextResourceSearchStartIndex,
            offset: 10
        }

        let successFunc = (json) => {
            if (requestTime === this.lastSearchRequestTime) {
                if (json.warning === "TOO_MANY_DATA") {
                    this.setState({
                        resHitCnt: json.totalcnt,
                        hasMoreSearchResults: false,
                        isInInitialSearch: false,
                        warning: i18n("restrictYourSearch")(json.totalcnt)
                    });
                } else {
                    if (isInitial) {
                        this.setState({searchResultResources: json.resources});
                    } else {
                        this.setState({searchResultResources: this.state.searchResultResources.concat(json.resources)});
                    }
                    this.nextResourceSearchStartIndex += 10;
                    this.setState({
                        resHitCnt: json.totalcnt,
                        hasMoreSearchResults: json.resources.length >= 10,
                        isInInitialSearch: false,
                        warning: ""
                    });
                }
            } else {
                console.log("Verwerfe den letzten Response der Suchanfrage, da dieser bereits zu alt ist: requestTime=" + requestTime + " lastSearchRequestTime=" + this.lastSearchRequestTime + " isInitial: " + isInitial);
            }
        }
        let errorFunc = (code) => {
            if (code === 301) {

            }
            this.setState({isInInitialSearch: false});
        }
        TransferHandler.request(Config.getSearchURL(), successFunc, errorFunc, transferObj);
    }

    hideAllResources() {
        for (let res of this.props.model.getResourceModel().getAll().slice()) {
            this.props.onToggleResourceVisibility(res.id)
        }
    }

    searchInputChanged(callback) {
        this.setState({
            searchResultResources: [],
            hasMoreSearchResults: false,
            resHitCnt: -1
        }, () => callback && callback());
    }

    changeGeoSelection(val, locationChangedCallback) {
        switch (val) {
            case "myNeighbourhood":
                navigator.geolocation.getCurrentPosition(function (position) {
                    this.setState({
                        searchGeoType: val,
                        searchGeoLon: ("" + position.coords.longitude).replace(".", ","),
                        searchGeoLat: ("" + position.coords.latitude).replace(".", ","),
                    }, () => {
                        this.searchInputChanged(locationChangedCallback);
                    });
                }.bind(this), function () {
                    alert(i18n("allowGeoLocation"));
                    this.setState({
                        searchGeoType: "everywhere",
                        searchGeoLon: "",
                        searchGeoLat: ""
                    }, () => this.searchInputChanged(locationChangedCallback));
                }.bind(this));
                break;
            case "choosenNeighbourhood":
                this.setState({
                    searchGeoType: val
                }, () => {
                    this.searchInputChanged(locationChangedCallback);
                });
                break;
            default:
                this.setState({
                    searchGeoType: val,
                    searchGeoLon: "",
                    searchGeoLat: ""
                }, () => {
                    this.searchInputChanged(locationChangedCallback);
                });
        }
    }


    render() {
        //Der angezeigte Zeitintervallstring
        let timeIntervalStr = "";
        if (this.state.searchFrom instanceof LCal) {
            timeIntervalStr += i18n("search.from")+" " + LCalFormatter.formatDate(this.state.searchFrom);
        }
        if (timeIntervalStr.length > 0) {
            timeIntervalStr += " ";
        }
        if (this.state.searchTo instanceof LCal) {
            timeIntervalStr += i18n("search.to")+" " + LCalFormatter.formatDate(this.state.searchTo);
        }
        if (timeIntervalStr.length === 0) {
            timeIntervalStr = i18n("everytime");
        }

        let geoStr = this.state.searchGeoType === "everywhere" ? i18n("everywhere") : (this.state.searchGeoType === "myNeighbourhood" ? i18n("myNeighbourhood") : i18n("inNeighbourhood"));

        const bottomBar = <div style={{display: "flex", justifyContent: "center", height: 87, boxShadow: "inset  0px 0px 5px #888888"}}><Tabs
            value={this.state.activeTab}
            onChange={(evt, activeTab) => this.setState({activeTab})}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            style={{overflow: "hidden", marginTop: 15}}
        >
            <Tab label={<Typography variant="caption" style={{fontSize: '0.7rem'}}>{i18n("search2")}<br/>&nbsp;</Typography>}
                 icon={<SearchIcon/>} style={{minWidth: 60}}/>
            <Tab label={<Typography variant="caption" style={{fontSize: '0.7rem'}}>{i18n("currentView")()}</Typography>}
                 icon={<CurrentViewIcon/>} style={{minWidth: 60}}/>
            <Tab label={<Typography variant="caption" style={{fontSize: '0.7rem'}}>{i18n("recentlySeen2")()}</Typography>}
                 icon={<ShortlyViewedIcon/>} style={{minWidth: 60}}/>
            <Tab label={<Typography variant="caption" style={{fontSize: '0.7rem'}}>{i18n("myStories2")()}</Typography>}
                 icon={<MyStoriesIcon/>} style={{minWidth: 60}}/>
        </Tabs></div>

        let hasWordWithTooLessCharacters = false;
        for (let word of this.state.searchPhrase.split(" ")) {
            if (word.trim().length > 0 && word.trim().length < minSearchWordCharCnt) {
                hasWordWithTooLessCharacters = true;
                break;
            }
        }

        const width = Math.min(this.props.width ? this.props.width : 600, window.innerWidth);
        return (
            <Details scrollContainerRef={el => this.scrollContainer = el}
                     onClose={() => this.props.onClose()}
                     title={i18n("search.js726564732")}
                     hasMore={this.state.hasMoreSearchResults && this.state.activeTab === 0}
                     loadMore={() => this.search(false)}
                     showWaitOverlay={this.props.showWaitOverlay}
                     background="#F5F5F5"
                     statusBar={bottomBar}
                     naked={this.props.naked}
                     width={width}
            >
                <div style={{width: "100%"}}>
                    {this.state.activeTab === 0 && <div>
                        <Paper elevation={5} style={{padding: 10, margin: 20}}>
                            <Accordion expanded={this.state.expandedSearchDiv === "text"}
                                            onChange={() => this.setState({expandedSearchDiv: this.state.expandedSearchDiv === "text" ? "" : "text"})}
                                            style={{background: this.state.expandedSearchDiv === "text" ? "#ffffe6" : "white", borderRadius: 5, marginBottom: 10}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <Typography variant="caption">
                                            {i18n("searchPhrase")}
                                        </Typography>
                                        <div style={{display: "flex"}}>
                                            {this.state.searchPhrase.trim().length > 0 ?
                                                this.state.searchPhrase.split(" ").map((word, index) => {
                                                    return word.trim().length > 0 ? <div key={index} style={{
                                                            background: word.length > 3 ? "#888800" : redCol,
                                                            textDecoration: word.length >= minSearchWordCharCnt ? "none" : "line-through",
                                                            marginRight: 5,
                                                            padding: "0px 7px",
                                                            borderRadius: 3
                                                        }}>

                                                            <Typography variant="body2"
                                                                        style={{color: "white"}}>{word}</Typography>

                                                        </div>
                                                        :
                                                        null
                                                })
                                                :
                                                <Typography variant="body2" style={{color: blueCol}}><b>{i18n("inputSearchPhrase")}</b></Typography>
                                            }
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.expandedSearchDiv === "text" &&
                                    <TextField inputRef={el => this.searchField = el} id="searchField"
                                               label={this.state.searchPhrase.length === 0 ? i18n("searchPhraseSample") : (hasWordWithTooLessCharacters ? i18n("minimumCharacters")(minSearchWordCharCnt) : "")}
                                               value={this.state.searchPhrase}
                                               onChange={(evt) => {
                                                   this.setState({searchPhrase: evt.target.value}
                                                       , () => this.searchInputChanged()
                                                   )
                                               }}
                                               fullWidth
                                               onKeyPress={(ev) => {
                                                   if (ev.key === 'Enter') {
                                                       this.searchField.blur();
                                                       this.searchInitial();
                                                       ev.preventDefault();
                                                   }
                                               }}
                                    />}
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={this.state.expandedSearchDiv === "date"}
                                            onChange={() => this.setState({expandedSearchDiv: this.state.expandedSearchDiv === "date" ? "" : "date"})}
                                            style={{background: this.state.expandedSearchDiv === "date" ? "#ffffe6" : "white", borderRadius: 5, marginBottom: 10}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <Typography variant="caption">
                                            {i18n("when")}
                                        </Typography>
                                        <Typography variant="body2"
                                                    style={{color: timeIntervalStr === i18n("search.js671154469") ? blueCol : redCol}}>
                                            <b>
                                                {timeIntervalStr}
                                            </b>
                                        </Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.state.expandedSearchDiv === "date" &&
                                    <LCalDateField label="von" value={this.state.searchFrom} onChange={(d) => {
                                        this.setState({searchFrom: d}
                                            , () => this.searchInputChanged()
                                        )
                                    }} onInputComplete={() => {
                                    }} yearPositions={12}
                                                   autoFocus
                                    />}
                                    &nbsp;
                                    <LCalDateField label="bis" value={this.state.searchTo} onChange={(d) => {
                                        this.setState({searchTo: d}
                                            , () => this.searchInputChanged()
                                        )
                                    }} onInputComplete={() => {
                                    }} yearPositions={12}/>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion expanded={this.state.expandedSearchDiv === "place"}
                                            onChange={() => this.setState({expandedSearchDiv: this.state.expandedSearchDiv === "place" ? "" : "place"})}
                                            style={{background: this.state.expandedSearchDiv === "place" ? "#ffffe6" : "white", borderRadius: 5, marginBottom: 10}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <Typography variant="caption">
                                            {i18n("where")}
                                        </Typography>
                                        <Typography variant="body2"
                                                    style={{color: geoStr === "überall" ? blueCol : redCol}}>
                                            <b>{geoStr}</b>
                                        </Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <FormControl required>
                                            <RadioGroup
                                                name="geoSelection"
                                                value={this.state.searchGeoType}
                                                onChange={(event) => this.changeGeoSelection(event.target.value)}

                                            >
                                                <FormControlLabel value="everywhere" control={<Radio/>}
                                                                  label={i18n("everywhere")}/>
                                                <FormControlLabel value="myNeighbourhood" control={<Radio/>}
                                                                  label={i18n("myNeighbourhood")}/>
                                                <FormControlLabel value="choosenNeighbourhood" control={<Radio/>}
                                                                  label={i18n("inNeighbourhoodOf")}/>
                                            </RadioGroup>
                                        </FormControl>

                                        {this.state.searchGeoType === "choosenNeighbourhood" &&
                                        <div>
                                            <GoogleMap id="Map"
                                                       initialCenter={{
                                                           "lat": this.state.centerLat,
                                                           "lng": this.state.centerLon
                                                       }}
                                                       center={{
                                                           "lat": this.state.centerLat,
                                                           "lng": this.state.centerLon
                                                       }}
                                                       zoom={5}
                                                       height={300}
                                                       width={width - 90}
                                                       onClick={(mapProps, map, clickevent) => this.setState({
                                                           searchGeoLat: "" + clickevent.latLng.lat(),
                                                           searchGeoLon: "" + clickevent.latLng.lng()
                                                       })}
                                                       onDragend={(mapProps, map, coord) => this.setState({
                                                           centerLat: "" + map.center.lat(),
                                                           centerLon: "" + map.center.lng()
                                                       })}

                                            >
                                                {
                                                    [
                                                        {
                                                            type: "marker",
                                                            title: i18n("chosenPosition"),
                                                            lat: this.state.searchGeoLat,
                                                            lng: this.state.searchGeoLon
                                                        }
                                                    ]
                                                }

                                            </GoogleMap>
                                            < TextField id="tfLat" label={i18n("latitude")} value={this.state.searchGeoLat}
                                                        onChange={(evt) => {
                                                            this.setState({searchGeoLat: evt.target.value}
                                                                , () => this.searchInputChanged()
                                                            )
                                                        }}
                                            />
                                            <TextField id="tfLon" label={i18n("longitude")} value={this.state.searchGeoLon}
                                                       onChange={(evt) => {
                                                           this.setState({searchGeoLon: evt.target.value}
                                                               , () => this.searchInputChanged()
                                                           )
                                                       }}
                                            />
                                        </div>
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                                <MyButton icon={<SearchIcon/>} onClick={() => this.searchInitial()} disabled={this.state.searchResultResources.length > 0 || this.state.isInInitialSearch}>
                                    {(this.state.resHitCnt >= 0 && this.state.warning === "" ? (this.state.resHitCnt + " ") : '') + i18n("searchTimelines")}
                                </MyButton>
                            </div>
                        </Paper>

                        <br/>
                        {this.state.isInInitialSearch ?
                            <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                                <CircularProgress/>
                            </div>
                            :
                            <div style={{marginLeft: 10, marginRight: 10}} ref="searchResult">
                                {this.state.warning !== "" &&
                                <div style={{display: "flex", justifyContent: "center"}}><Typography variant="body1"
                                                                                                     color="error">{this.state.warning}</Typography>
                                </div>}
                                <SimpleResourcesTable
                                    model={this.props.model}
                                    resources={this.state.searchResultResources}
                                    pendingResourceIDs={this.props.pendingResourceIDs}
                                    onToggleResourceVisibility={(resid) => this.props.onToggleResourceVisibility(resid)}
                                    onSearch={(expression) => this.props.onSearch(expression)}
                                    onResShowDetails={(id) => this.props.onResShowDetails(id)}
                                    onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhoodByCoords(lon, lat)}
                                    checkboxIcon={this.props.checkboxIcon}
                                />
                            </div>
                        }
                    </div>
                    }


                    {this.state.activeTab === 1 &&
                    <div style={{margin: 10}}>
                        <Typography variant="h6">{i18n("inCurrentView")}</Typography>
                        {this.props.model.getResourceModel().getAll().length > 0 &&
                        <div>
                            <br/>
                            <Typography variant="button" onClick={() => this.hideAllResources()}
                                        style={{cursor: "pointer", margin: 10}}>{i18n("hideAll")}</Typography>
                            <SimpleResourcesTable
                                model={this.props.model}
                                resources={this.props.model.getResourceModel().getAll()}
                                pendingResourceIDs={this.props.pendingResourceIDs}
                                onToggleResourceVisibility={(resid) => this.props.onToggleResourceVisibility(resid)}
                                onSearch={(expression) => this.props.onSearch(expression)}
                                onResShowDetails={(id) => this.props.onResShowDetails(id)}
                                onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhoodByCoords(lon, lat)}
                                checkboxIcon={this.props.checkboxIcon}
                            />
                            <br/>
                        </div>
                        }
                    </div>}

                    {this.state.activeTab === 2 &&
                    <div style={{margin: 10}}>
                        <Typography variant="h6">{i18n("recentlySeen")}</Typography>
                        {this.state.recentResources.length > 0 &&
                        <div>
                            <br/>
                            <SimpleResourcesTable
                                model={this.props.model}
                                resources={this.state.recentResources}
                                pendingResourceIDs={this.props.pendingResourceIDs}
                                onToggleResourceVisibility={(resid) => this.props.onToggleResourceVisibility(resid)}
                                onSearch={(expression) => this.props.onSearch(expression)}
                                onResShowDetails={(id) => this.props.onResShowDetails(id)}
                                onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhoodByCoords(lon, lat)}
                                checkboxIcon={this.props.checkboxIcon}
                            />
                            <br/>
                        </div>
                        }
                    </div>
                    }

                    {this.state.activeTab === 3 &&
                    <div style={{margin: 10}}>
                        <Typography variant="h6">{i18n("myStories")}</Typography>
                        {this.state.myResources.length > 0 &&
                        <div>
                            <br/>
                            <SimpleResourcesTable
                                model={this.props.model}
                                resources={this.state.myResources}
                                pendingResourceIDs={this.props.pendingResourceIDs}
                                onToggleResourceVisibility={(resid) => this.props.onToggleResourceVisibility(resid)}
                                onSearch={(expression) => this.props.onSearch(expression)}
                                onResShowDetails={(id) => this.props.onResShowDetails(id)}
                                onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhoodByCoords(lon, lat)}
                                checkboxIcon={this.props.checkboxIcon}
                            />
                        </div>
                        }
                    </div>
                    }
                </div>


            </Details>
        )
    }
}

export default Search;
