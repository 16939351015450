import React from "react";
import OldMediaItem from "./oldmediaitem";
import {MediaItem} from "./mediaitem";
import i18n from "../i18n/i18n";

const MediaContainer = ({width, height = 315, displayDummies, mapDescriptor, title, onClick, children, preview = false, onToggleResourceVisibility, onHighlight, onFilterClicked, onShowMap, pendingResourceIDs, model}) => {
        // Define a symbol using a predefined path (an arrow)
        // supplied by the Google Maps JavaScript API.
        if (mapDescriptor && mapDescriptor.trim().length !== 0) {
            //Kann der Mapdescriptor in ein Objekt umgewandelt werden?
            let mapDesc;
            try {
                mapDesc = JSON.parse(mapDescriptor);
                if (mapDesc.items) {
                    return <div style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        alignItems: "flex-start",
                        width: width
                    }}>
                        {mapDesc.items.map((item, index) => {
                          return <MediaItem key={index} preview={preview} mapDescriptor={mapDesc} displayDummies={displayDummies} onClick={onClick} title={title} item={item} width={width} height={height} onToggleResourceVisibility={onToggleResourceVisibility} onHighlight={onHighlight} onFilterClicked={onFilterClicked} onShowMap={onShowMap} pendingResourceIDs={pendingResourceIDs} model={model}>
                            {children}
                          </MediaItem>
                        })}
                    </div>
                } else if (mapDesc.objects && mapDesc.objects.length > 0) {
                  return <OldMediaItem mapDescriptor={mapDesc} preview={preview} displayDummies={displayDummies} onClick={onClick} width={width} height={height}>
                    {children}
                  </OldMediaItem>

                } else {
                    return <div>{i18n("media.js334165907")}</div>
                }
                //Neue Version -> Es kann belibig viele Karten, sowie YouTube-Videos geben

            } catch (ex) {
                console.log(ex);
                return <div>{i18n("media.js745432641")}</div>
            }
        } else {
            return <div></div>
        }
}

export default MediaContainer;
