import React, {useEffect, useState} from 'react';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import TransferHandler from "../transfer/transferhandler";
import Config from "../config/config";
import SimpleResourcesTable from "../search/simpleresourcestsable";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import i18n from "../i18n/i18n";
import TitleList from "../search/lists/titlelist";
import {Typography} from "@material-ui/core";
import Logo from "../logo";
import WelcomeBlock from "./welcomeblock";
import TopFacts from "./topfacts";
import Explanation from "./explanation";
import Footer from "./footer";
import WelcomeBlockWithBackground from "./welcomeblockwithbackground";
import LoginButton from "../ui-components/buttons/text/loginbutton";
import PricingButton from "../ui-components/buttons/text/pricingbutton";
import Curriculum from "./curriculum";
import Pricing from "./pricing";
import Checkout from "./checkout";


const WelcomeButton = ({title, onClick, imgURL, ico, content, languageCode}) => (
    <Paper elevation={5} style={{height: 200, cursor: "pointer", backgroundImage: 'url("'+imgURL+'")', backgroundSize: 'cover', position: 'relative', borderRadius: 5, overflow: 'hidden', backgroundColor: 'rgba(255,255,255,0.8)'}}
           onClick={onClick}>
      {ico && <div style={{position: 'absolute', width: '100%', height: '100%', color: 'white', display: "flex", alignItems: "center", justifyContent:"center"}}>
        {ico}
      </div>}
      <div style={{height: 200, overflow: 'auto'}}>
        {content}
      </div>
      <div style={{position: 'absolute', bottom: 0, width: '100%', height: 50, color: 'white', display: "flex", alignItems: "center", justifyContent:"center", background: 'rgba(50,50,50,0.5)'}}>
        <b style={{margin: 10}}>{title}</b>
      </div>
    </Paper>
);

const Welcome = ({onSearch, onNew, onHelp, onToggleResourceVisibility, onSearchMyNeighbourhood, height, model, pendingResourceIDs, onResShowDetails, onJumpToLicenseAdmin, open, onLogin, onShowPricing, loginUser, loginUserIsDummy, userLoggedIn, onMyStories, onRecentlySeen, showPricing, languageCode}) => {
  const [recentResources, setRecentResources] = useState([]);
  const [myResources, setMyResources] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);
  const [buyReference, setBuyReference] = useState("");
  const [price, setPrice] = useState("");
  const [country, setCountry] = useState("");


  useEffect(() => {
    const successFunc = (json) => {
      setRecentResources(json.recent);
      setMyResources(json.myResources);
    }
    const errorFunc = (code, json) => {

    }
    TransferHandler.request(Config.getRecommendedRessourcesURL(), successFunc,
        errorFunc, {});
  }, [open, loginUser]);

  const browse = () => {
    document.getElementById("recommended").scrollIntoView();
  }

  return <div style={{
    overflow: "auto",
    boxShadow: "inset 0px 20px 13px -13px rgba(0,0,0,0.47)",
    height: height
  }}>
    <WelcomeBlock>
      <div style={{padding: 20, marginBottom: -50, display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Logo scale={window.innerWidth > 1000 ? 0.7 : 0.5}/>
        {!showPricing && <>
          <br/>
          <br/>
            <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.5rem', fontWeight: 500}}>{i18n("landingpage_title")}</Typography>
              <br/>
              <br/>
          <div style={{display: "flex"}}>
            <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.2rem'}}>
              {!window.isSchoolDomain ? i18n("welcome.js735054413") : i18n("welcome.js695746560")}
              <br/>
              <br/>
              {window.isSchoolDomain ? i18n("welcome.js142706916") : i18n("welcome.js152384233")}
            </Typography>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", marginTop: 60, width: '100%'}}>
            <div style={{display: "flex", flexDirection: window.innerWidth < 600 ? "column" : "row"}}>
              <PricingButton onClick={()=>onShowPricing(true)}/>
              {(!userLoggedIn || loginUserIsDummy) && <LoginButton onClick={onLogin}/>}
            </div>
          </div>
        </>}

        {showPricing && !showCheckout && <>
            <div style={{padding: 20, marginBottom: -50, display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.5rem', fontWeight: 500}}>Preise</Typography>
            </div>
            <br/>
            <br/>
            <br/>
            <Pricing onLogin={onLogin} onCheckout={(buyreference, price, country)=>{setShowCheckout(true); setBuyReference(buyreference); setPrice(price); setCountry(country)}} onBack={()=>onShowPricing(false)} userLoggedIn={userLoggedIn} loginUserIsDummy={loginUserIsDummy}/>
          </>
        }
        {showPricing && showCheckout && <>
          <div style={{padding: 20, marginBottom: -50, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.5rem', fontWeight: 500}}>Vielen Dank für deine Bestellung!</Typography>
          </div>
          <br/>
          <br/>
          <br/>
          <Checkout onBack={()=>{setShowCheckout(false); onShowPricing(false);}} buyReference={buyReference} price={price} country={country} onJumpToLicenseAdmin={onJumpToLicenseAdmin}/>
        </>
        }
      </div>
    </WelcomeBlock>

    {!showPricing && <>
      <WelcomeBlockWithBackground>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <WelcomeButton
                title={i18n("welcome.js737110502")}
                onClick={() => onSearchMyNeighbourhood()}
                imgURL={"map_germany.svg"}
                ico={<NotListedLocationIcon fontSize={'large'}/>}
            >
            </WelcomeButton>
          </Grid>
          <Grid item xs={6}>
            <WelcomeButton
                title={i18n("welcome.js455445377")}
                onClick={() => browse()}
                imgURL={"browse.svg"}
            >
            </WelcomeButton>
          </Grid>
          <Grid item xs={6}>
            <WelcomeButton
                title={i18n("newStory")}
                onClick={() => onNew()}
                imgURL={"newhistory.svg"}
            >
            </WelcomeButton>
          </Grid>

          <Grid item xs={6}>
            <WelcomeButton
                title={i18n("help")}
                onClick={() => onHelp()}
                imgURL={"help_book.svg"}
            >
            </WelcomeButton>
          </Grid>
          {myResources.length > 0 && <Grid item xs={window.innerWidth > 1000 && recentResources.length > 0 ? 6 : 12}>
            <WelcomeButton
                title={i18n("myStories")}
                content={<SimpleResourcesTable
                    model={model}
                    resources={myResources}
                    pendingResourceIDs={pendingResourceIDs}
                    onToggleResourceVisibility={(resid) => onToggleResourceVisibility(
                        resid)}
                    onSearch={(expression) => onSearch(expression)}
                    onResShowDetails={(id) => onResShowDetails(id)}
                />}
                onClick={() => onMyStories()}
            >
            </WelcomeButton>
          </Grid>}
          {recentResources.length > 0 && <Grid item xs={window.innerWidth > 1000 && myResources.length > 0 ? 6 : 12}>
            <WelcomeButton
                title={i18n("recentlySeen")}
                content={<SimpleResourcesTable
                    model={model}
                    resources={recentResources}
                    pendingResourceIDs={pendingResourceIDs}
                    onToggleResourceVisibility={(resid) => onToggleResourceVisibility(
                        resid)}
                    onSearch={(expression) => onSearch(expression)}
                    onResShowDetails={(id) => onResShowDetails(id)}
                />}
                onClick={() => onRecentlySeen()}
            >
            </WelcomeButton>
          </Grid>}
        </Grid>
      </WelcomeBlockWithBackground>
      <WelcomeBlock>
        <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.5rem', fontWeight: 500}}>
          {i18n("curriculum.title")}
        </Typography>
        <br/>
        {i18n("curriculum.text")}
        <Curriculum onToggleResourceVisibility={onToggleResourceVisibility} key={'curriculum'+loginUser} languageCode={languageCode}/>
      </WelcomeBlock>
      <WelcomeBlock>
        <Typography variant={"h5"} component={"h2"} style={{fontSize: '1.5rem', fontWeight: 500}}>
          {i18n("welcome.about")}
        </Typography>
        <br/>
        <Explanation/>
      </WelcomeBlock>
      <WelcomeBlock backgroundColor={'#253B4D'}>
        <TopFacts/>
      </WelcomeBlock>
      <WelcomeBlock>
        <div style={{marginTop: 40}}>
          <TitleList id="recommended" title={i18n("recommended")} url='topstories.php'
                     onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false} key={'recommended'+loginUser}/>
          <TitleList id="todaysbirthdays" title={i18n("todaysBirthdays")} url='birthdaystories.php'
                     onClick={(id) => onToggleResourceVisibility(id)} isBirthday={true}/>
          <TitleList id="todaysdeathdays" title={i18n("todaysDeathdays")} url='deathstories.php'
                     onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
          <TitleList id="todaysevents" title={i18n("anniversaries")}
                     url='todayseventstories.php' titleMini
                     onClick={(id) => onToggleResourceVisibility(id)}
                     isBirthday={false}/>
        </div>
      </WelcomeBlock>
    </>}
    <WelcomeBlock backgroundColor={'#253B4D'}>
      <Footer/>
    </WelcomeBlock>
  </div>
}

export default Welcome;
