import PostAddIcon from "@material-ui/icons/PostAdd";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import HtmlTooltip from "./htmltooltip";
import i18n from "../i18n/i18n";

const StoryCheckbox = ({disabled, checked, onChange, children, style, className, iconClassName}) => (
    <HtmlTooltip arrow title={checked ? i18n("hideStory") : i18n("showStory")()}><span onClick={(e) => onChange && onChange(e)} style={style} className={className}>
      {checked?<CloseIcon color={disabled ? "disabled" : "inherit"} className={iconClassName}/>:<PostAddIcon color={disabled ? "disabled" : "inherit"} className={iconClassName}/>}
            {children}
        </span>
    </HtmlTooltip>);

export default StoryCheckbox;
