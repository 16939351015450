import {createTheme} from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: 3
    },
    h2: {
      fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      fontSize: 22,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 2
    }
  },
  palette: {
    secondary: {
      main: '#505050'
    },
    primary: {
      main: '#E00'
    }
  },
});

export default theme;
