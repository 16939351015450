import React from 'react';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const Hint = ({type, header, text}) => {
  const headerBackground = type === 'info' ? "green" : "#cc7a00";
  const background = type === 'info' ? "#e6ffe6" : "#ffd699";

  return <Paper elevation={5} style={{marginBottom: 20, overflow: "hidden"}}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      background: background,
    }}>
      <div style={{
        background: headerBackground,
        padding: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: "white"
      }}>
        {type === 'info' && <InfoIcon style={{marginRight: 10}}/>}
        {type === 'warning' && <WarningIcon style={{marginRight: 10}}/>}
        <Typography variant={"h6"}>{header}</Typography>
      </div>
      <div style={{padding: 10}}>
        <Typography variant={"body1"}>{text}</Typography>
      </div>
    </div>
  </Paper>
}

export default Hint;
