import React, {useState} from "react";
import WikipediaIcon from "../images/icons/wikipedia.js";
import WikiBox from "./wikibox";
import PreviewButton from "../ui-components/buttons/text/previewbutton";
import i18n from "../i18n/i18n";

const getWikiPreview = (title, onClick) => {
  return <PreviewButton title={title} tooltip={i18n("showWikipediaTooltip")} Icon={WikipediaIcon} onClick={onClick}/>
}


const WikiboxWithPreview = ({wikipage, model, pendingResourceIDs, onToggleResourceVisibility, onHighlight, onShowMap}) => {
  const [lPreview, setLPreview] = useState(false);

  let previewButton = getWikiPreview(wikipage, (e)=> {e.stopPropagation(); e.preventDefault(); setLPreview(!lPreview)});

  //Alte Version, bei der es nur Kartendaten gibt
  return <>
    {previewButton}
    {lPreview && <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 25
    }}>
      <div style={{width: '100%'}}>
        <WikiBox site={wikipage}
                 model={model}
                 pendingResourceIDs={pendingResourceIDs}
                 onToggleResourceVisibility={(id) => onToggleResourceVisibility(id)}
                 onHighlight={(taskid) => onHighlight(taskid)}
                 onShowMap={(lat, lng) => onShowMap(lat, lng)}
        />
      </div>
    </div>}
  </>
}


export default WikiboxWithPreview
