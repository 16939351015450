import React from "react";
import Markdown from "./Markdown";

const EditorMarkdown = ({blockProps, block, elementProps, style}) => {
  const markdown = blockProps.item;
  return <div {...elementProps} style={{position: "relative", pointerEvents: blockProps.getReadOnly() ? "inherit" : "none", marginLeft: window.innerWidth >= 800 ? (blockProps.item.marginLeft || 0) : 0, marginRight: window.innerWidth >= 800 ? (blockProps.item.marginRight || 0) : 0}}>
    <div style={{overflow: "hidden", width: 'calc(100% - 5px)', lineHeight: 0}} >
      <Markdown markdown={markdown.src}
                style={{
                  position: 'relative',
                  width: '100%'
                }}
                userProps={blockProps.userProps}/>
    </div>
  </div>
}

export default EditorMarkdown;
