/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2016
 */

import React from 'react';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

class TimePicker extends React.Component {
    render() {
        const style = {
            numberBlock: {
                width: 70,
                height: 70,
                backgroundColor: "#1385e5",
                padding: 10,
                margin: "auto",
                color: "white",
                display: "block",
                textAlign: "center"
            }
        }


        return (
            <div style={{width: this.props.width, height: this.props.height}}>
                <div style={{padding: 10}}>
                    <br/>
                    <br/>
                    <Typography variant="h2" style={{display: "flex", justifyContent: "center"}}>
                        <span
                            style={style.numberBlock}>{this.props.hour < 10 ? "0" + this.props.hour : this.props.hour}</span>
                        <span style={{height: 65}}>:</span>
                        <span
                            style={style.numberBlock}>{this.props.minute < 10 ? "0" + this.props.minute : this.props.minute}</span>
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h6">
                        Stunde:
                    </Typography>
                    <Slider min={0} max={23} step={1} value={this.props.hour} onChange={(e, val) => this.props.onHourChanged(val)}/>
                    <br/>
                    <Typography variant="h6">
                        Minute:
                    </Typography>
                    <Slider min={0} max={45} step={15} value={this.props.minute} onChange={(e, val) => this.props.onMinuteChanged(val)}/>
                </div>
            </div>
        )
    }
}

export default TimePicker
