import React from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import i18n from "../i18n/i18n";

const ZoomableImage = ({src, alt, title}) => {
  const [open, setOpen] = React.useState(false);

  return <span style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%"
      }}>
        <span style={{display: "flex", margin: 0, width: "100%", justifyContent: "center"}} >
            <img src={src} alt={alt} style={{maxWidth: "calc(100% - 50px)"}}/>
            <span>
              <IconButton aria-label="zoom" onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen(true);
              }}>
                <ZoomInIcon fontSize="large"/>
              </IconButton>
            </span>
        </span>
        {title && title.length > 0 &&
        <span style={{display: "flex", justifyContent: "center", marginBottom: 20}}>
            <Typography variant={"caption"}><b>{i18n('zoomableImage.bild')}</b>{title}</Typography>
        </span>
        }

        <Popover onClose={() => setOpen(false)}
                 open={open}
                 anchorEl={document.documentElement}
                 anchorOrigin={{
                   vertical: 'top',
                   horizontal: 'center',
                 }}
                 transformOrigin={{
                   vertical: 'center',
                   horizontal: 'center',
                 }}
        >
          <IconButton style={{position: 'absolute', top: 0, right: 0}}><div style={{borderRadius: 30, background: "gray", height: 35, width: 35}}><CancelIcon fontSize="large" style={{color: "white"}} onClick={()=>setOpen(false)}/></div></IconButton >
          <img src={src} alt={alt} style={{width: window.innerWidth, display: "block"}}/>
        </Popover>
      </span>
}

export default ZoomableImage;
