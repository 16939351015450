const shortenWikiURL = (wikipage) => {
  if(wikipage && wikipage !== "") {
    try {
      wikipage = decodeURI(this.state.wikipage);
    } catch (ex) {

    }
    const i = wikipage.lastIndexOf("/");
    if(i>=0 && i<wikipage.length) {
      wikipage = wikipage.substring(i+1);
    }
    return wikipage;
  }
}

export default shortenWikiURL;
