import React from "react";
import Panorama from "./Panorama";
import EditorBlock from "../EditorBlock";

const EditorPanorama = ({blockProps, block, elementProps, style}) => (
  <EditorBlock blockProps={blockProps} block={block} elementProps={elementProps}
               child={<Panorama streetviewId={blockProps.item.id} style={{
                 position: 'relative',
                 width: '100%',
                 height: style.width.replace('%', '') * 0.007 * Math.min(blockProps.maxWidth, window.innerWidth) + 'px'
               }}/>}
  />
)

export default EditorPanorama;
