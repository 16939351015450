import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {getEndLCalFromJSON, getStartLCalFromJSON} from "../../parser";
import {LCalInterval} from "react-canvas-timeline";
import i18n from "../../i18n/i18n";


export const innerEvents2json = (innerEvents) => {
  let retVal = null;
  if(innerEvents) {
    let objArr = [];
    for (let innerEvent of innerEvents) {
      const start = innerEvent.getStart();
      const end = innerEvent.getEnd();

      const obj = {
        startDay: start && start.getDay(),
        startMonth: start && start.getMonth(),
        startYear: start && start.getYear(),
        startHour: start && start.getHour(),
        startMinute: start && start.getMinute(),
        startType: start && start.getType(),
        startPrecision: start && start.getPrecision(),
        endDay: end && end.getDay(),
        endMonth: end && end.getMonth(),
        endYear: end && end.getYear(),
        endHour: end && end.getHour(),
        endMinute: end && end.getMinute(),
        endType: end && end.getType(),
        endPrecision: end && end.getPrecision(),
        color: innerEvent.color
      }
      objArr.push(obj);
    }
    retVal = JSON.stringify(objArr);
  }
  return retVal;
}

export const json2InnerEvents = (innerEventsJSON) => {
  let innerEvents = [];
  try {
    let parsedInnerEvents = JSON.parse(innerEventsJSON);

    //Das geparste Objekt muss noch in das für den Client passende Objekt umgewandelt werden
    //Hier wird mit mit einem LCalInterval gearbeitet

    let minInnerStart;
    let maxInnerEnd;
    if (parsedInnerEvents) {
      for (let evt of parsedInnerEvents) {
        let start = getStartLCalFromJSON(evt);
        let end;
        if (evt.startType === 585) { //Handelt es sich um einen Zeitpunkt?
          end = start;
        } else {
          end = getEndLCalFromJSON(evt);
        }

        if (start || end) {
          if (!minInnerStart || (start && start.getJulianMinutes()
              < minInnerStart.getJulianMinutes())) {
            minInnerStart = start;
          }
          if (!maxInnerEnd || (end && end.getJulianMinutes()
              > maxInnerEnd.getJulianMinutes())) {
            maxInnerEnd = end;
          }

          let interval = new LCalInterval(start, end);
          interval.color = evt.color;
          innerEvents.push(interval);
        }
      }
    }
  } catch(ex) {

  }
  return innerEvents;
}

const InnerEvents = ({innerEventsJSON, innerEventsChanged}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  return <>
            <Paper style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
                   elevation={5}>
              <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => setSelectedTab(newValue)}
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
              >
                <Tab value={0}
                     label={i18n("innerevents.js238036015")}/>
                <Tab value={1}
                     label="JSON"/>
              </Tabs>
            <div style={{padding: 20}}>
              {selectedTab === 0 && <div>
                {i18n("innerevents.34353463")}
              </div>}
              {selectedTab === 1 &&
              <TextField multiline
                         fullWidth
                         value={innerEventsJSON || ""}
                         style={{flexGrow: 10}}
                         onChange={(evt) => innerEventsChanged(
                             evt.target.value)}/>}
            </div>
          </Paper>
    </>
};


export default InnerEvents;
