import React, {memo} from "react";
import HistoSuspense from "../histosuspense";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import i18n from "../i18n/i18n";

const GoogleMap = React.lazy(() => import('./googlemap'));

export class LazyGoogleMap extends React.Component {
    render() {
        if(window.isSchoolDomain) {
          return <div style={{padding: 20, lineHeight: 1.4}}>{i18n("lazygooglemap.js821091857")}</div>
        }
        const histomania_load_googlemaps = localStorage.getItem("histomania_load_googlemaps");

        return histomania_load_googlemaps ? <HistoSuspense>
            <GoogleMap {...this.props}/>
        </HistoSuspense>
            :
            <div style={{padding: 20}}>
                  <FormControlLabel
                      control={
                        <Checkbox checked={false} onChange={() => {
                          localStorage.setItem("histomania_load_googlemaps", true);
                          this.forceUpdate();
                        }}
                                  value="checkedA"/>
                      }
                      label={i18n("lazygooglemap.js947359452")}
                  />
            </div>
    }
}

export default memo(LazyGoogleMap);
