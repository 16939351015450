import React from 'react';
import './detailbuttonbar.scss';
import WikipediaIcon from "../images/icons/wikipedia";
import WikidataIcon from "../images/icons/wikidata";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MapIcon from "@material-ui/icons/LocationOn";
import ShowNeighbourhoodIcon from "@material-ui/icons/MyLocation";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import i18n from "../i18n/i18n";

const FurtherInfo = (props) => {
    const id = props.data.proxyID || props.data.id;
   
    return <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap-reverse", gap: 10, width: '100%', boxShadow: "inset  0px 0px 5px #888888"}}>
        {props.data.wikipage &&
        <div className={"detailButton"}>
            <a rel="nofollow noopener noreferrer" style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10}}
               target="_blank"
               href={i18n("wikiURL") + props.data.wikipage}>
                <WikipediaIcon/>
                &nbsp;&nbsp;{i18n("articleOnWikipedia")}
            </a>
        </div>
        }
        {id < 0 && id >= -1000000000 &&
        <a rel="nofollow noopener noreferrer" target="_blank" style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10}}
           href={"https://www.wikidata.org/wiki/Q" + (-id)}>
            <WikidataIcon/>
            &nbsp;&nbsp;{i18n("wikidataRecord")}
        </a>
        }
        {props.data.name && props.data.name.trim().length > 0 && !window.isSchoolDomain && <a rel="nofollow noopener noreferrer" target="_blank" style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10}}
                                                                              href={"https://www.youtube.com/results?search_query=" + props.data.name}>
            <YouTubeIcon style={{color: "#E00"}}/>
            &nbsp;&nbsp;{i18n("showVideosOnYoutube")}
        </a>
        }
        {props.data.latitude && props.data.longitude && !window.isSchoolDomain &&
        <a rel="nofollow noopener noreferrer" target="_blank" style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10}}
           href={"https://www.google.com/maps/search/?api=1&query=" + props.data.latitude + "," + props.data.longitude}>
            <MapIcon/>
            &nbsp;&nbsp;{i18n("showLocationOnGoogleMaps")}
        </a>
        }
        {props.data.latitude && props.data.longitude && props.onResShowNeighbourhood &&
        <span style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10, cursor: "pointer"}}
           onClick={() => props.onResShowNeighbourhood(props.data.longitude, props.data.latitude)}>
            <ShowNeighbourhoodIcon/>
            &nbsp;&nbsp;{i18n("showStoriesNearby")}
        </span>
        }
        {props.onInThisTime &&
        <span style={{display: 'flex', alignItems: "center", color: 'black', marginTop: 10, marginBottom: 10, cursor: "pointer"}}
           onClick={() => props.onInThisTime()}>
            <YoutubeSearchedForIcon/>
            &nbsp;&nbsp;{i18n("showStoriesAtThatTime")}
        </span>
        }
        {props.children}
    </div>
}

export default FurtherInfo;
