const parseMapDescriptor = (str) => {
  try {
    let parsedMapDescriptor = JSON.parse(str);
    if (!parsedMapDescriptor || !parsedMapDescriptor.items) {
      parsedMapDescriptor = {items: []};
    }
    return parsedMapDescriptor;
  } catch(e) {
    return {items: []};
  }
}

export default parseMapDescriptor;
