import CircularProgress from "@material-ui/core/CircularProgress";
import React, {Suspense} from "react";

const HistoSuspense = ({children}) => (
  <Suspense fallback={<div style={{display: "flex"}}><CircularProgress/></div>}>
    {children}
  </Suspense>
)

export default HistoSuspense;
