import React from "react";
import MyButton from "./mybutton";
import i18n from "../../../i18n/i18n";
import CheckIcon from '@material-ui/icons/Check';

const YesButton = ({onClick, style, autoFocus, disabled}) => (
    <MyButton icon={<CheckIcon/>} style={style} onClick={onClick} autoFocus={autoFocus} primary={true} disabled={disabled}>
      {i18n('yes')}
    </MyButton>
)

export default YesButton;
