import {
  RichUtils,
  EditorState,
  SelectionState,
  convertFromRaw,
  convertToRaw
} from 'draft-js';

const EditorUtils =  {
  createLinkAtSelection(editorState, clickObject, linkType) {
    const contentState = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { clickObject, linkType });
    const entityKey = contentState.getLastCreatedEntityKey();
    const withLink = RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    );
    return EditorState.forceSelection(withLink, editorState.getSelection());
  },

  removeLinkAtSelection(editorState) {
    const selection = editorState.getSelection();
    return RichUtils.toggleLink(editorState, selection, null);
  },

  getCurrentEntityKey(editorState) {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const anchorBlock = contentState.getBlockForKey(anchorKey);
    const offset = selection.getAnchorOffset();
    const index = selection.getIsBackward() ? offset - 1 : offset;
    return anchorBlock.getEntityAt(index);
  },

  getCurrentEntity(editorState) {
    const contentState = editorState.getCurrentContent();
    const entityKey = this.getCurrentEntityKey(editorState);
    return entityKey ? contentState.getEntity(entityKey) : null;
  },

  hasEntity(editorState, entityType) {
    const entity = this.getCurrentEntity(editorState);
    return Boolean(entity && entity.getType() === entityType);
  },

  addBlockAndSelect(modifier, editorState, item) {
    let newEditorState = modifier(editorState, { item });

    const newSelection = newEditorState.getSelection();
    const newSelectionKey = newSelection.getStartKey();
    const blockBeforeKey = newEditorState.getCurrentContent().getKeyBefore(newSelectionKey);

    const selection = new SelectionState({
      anchorKey: blockBeforeKey,
      anchorOffset: 0,
      focusKey: blockBeforeKey,
      focusOffset: 0,
    });

    newEditorState = EditorState.forceSelection(newEditorState, selection);

    return newEditorState;
  },

  getCurrentBlock(editorState) {
    const currentSelection = editorState.getSelection();
    const currentKey = currentSelection.getStartKey();
    const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
    return currentBlock;
  },

  getCurrentItem(editorState) {
    const currentBlock = this.getCurrentBlock(editorState);
    if(currentBlock) {
      const entityKey = currentBlock.getEntityAt(0);
      if(entityKey) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(entityKey);
        const item = entity.getData().item;
        return item;
      }
    }
    return null;
  },

  getCurrentClickObject(editorState) {
    const currentBlock = this.getCurrentBlock(editorState);
    if(currentBlock) {
      const entityKey = currentBlock.getEntityAt(0);
      if(entityKey) {
        const contentState = editorState.getCurrentContent();
        const entity = contentState.getEntity(entityKey);
        const clickObject = entity.getData().clickObject;
        return clickObject;
      }
    }
    return null;
  },

  isEditorStateNotEmpty(editorState) {
    return editorState && editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getPlainText().trim().length > 0;
  },

  toEditorState(value) {
    let content;
    try {
      content = convertFromRaw(JSON.parse(value));
      return EditorState.createWithContent(content)
    } catch(ex) {
      return EditorState.createEmpty();
    }
  },

  fromEditorState(editorState) {
    if(editorState) {
      return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    }
    return null;
  }
};

export default EditorUtils;
