/* Copyright (C) Peter Rank Software - All Rights Reserved
 * Written by Peter Rank <peter@softmanufaktur.de>, 2017
 */
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from "../../../i18n/i18n";

const LCalTypeField = ({isEndType, type, onTypeChange}) =>  (
            <FormControl style={{margin: 1, padding: 0}}>
                <InputLabel htmlFor="type"><span style={{whiteSpace: "nowrap"}}>{isEndType ? i18n("endType") : i18n("startType") }</span></InputLabel>
                <Select
                    value={type}
                    onChange={(evt) => onTypeChange(evt.target.value)}
                    inputProps={{
                        name: 'type',
                        id: 'type',
                    }}
                >
                    {!isEndType && <MenuItem value={585} style={{border: "3px solid yellow"}}><b>{i18n("pointInTime")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={569}><b>{i18n("born")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={1319}><b>{i18n("earliest")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={580}><b>{i18n("start")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={571}>{i18n("origin")()}</MenuItem>}
                    {!isEndType && <MenuItem value={575}><b>{i18n("discovery")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={577}><b>{i18n("publication")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={574}><b>{i18n("firstDescription")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={1636}><b>{i18n("baptized")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={1191}><b>{i18n("premiere")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={1619}><b>{i18n("opened")}</b></MenuItem>}
                    {!isEndType && <MenuItem value={2031}><b>{i18n("activeSince")}</b></MenuItem>}


                    {isEndType && <MenuItem value={570}><b>{i18n("died")}</b></MenuItem>}
                    {isEndType && <MenuItem value={1326}><b>{i18n("latest")}</b></MenuItem>}
                    {isEndType && <MenuItem value={582}><b>{i18n("end")}</b></MenuItem>}
                    {isEndType && <MenuItem value={730}><b>{i18n("decommissioning")}</b></MenuItem>}
                    {isEndType && <MenuItem value={576}><b>{i18n("dissolved")}</b></MenuItem>}
                    {isEndType && <MenuItem value={746}><b>{i18n("lost")}</b></MenuItem>}
                    {isEndType && <MenuItem value={2032}><b>{i18n("activeUntil")}</b></MenuItem>}
                    {isEndType && <MenuItem value={2669}><b>{i18n("discontinued")}</b></MenuItem>}
                    {isEndType && <MenuItem value={3999}><b>{i18n("closed")}</b></MenuItem>}
                </Select>
            </FormControl>
);

export default LCalTypeField;
