import React, { useCallback } from 'react';
import {transformLinkUri} from './markdownhelper';
import CircularProgress from "@material-ui/core/CircularProgress";
import HistoSuspense from "../histosuspense";
import remarkGfm from "remark-gfm";
import parseMapDescriptor from "./parseMapDescriptor";
const ReactMarkdown = React.lazy(() => import('react-markdown'));

const MarkdownDisplayField = ({description, renderers, mapDescriptor, mapDescriptorFallback}) => {
    let myDescription = description;
    if(mapDescriptorFallback) {
        const mapDescObj = mapDescriptor && parseMapDescriptor(mapDescriptor);
        if (mapDescObj && !description) {
            description = " ";
        }

        if (description) {
            //Gibt es Items im Mapdescriptor, die nicht in der Description vorkommen?
            //Dies wird an der itemid erkannt, falls die nicht im Text vorkommt.
            if (mapDescObj && mapDescObj.items && mapDescObj.items.length > 0) {
                for (let item of mapDescObj.items) {
                    if (!item.uuid || (item.uuid && !description.includes(item.uuid))) {
                        myDescription = myDescription + "\n[](mediaid="
                            + item.uuid + ")";
                    }
                }
            }
        }
    }

    const memoizedCallback  = useCallback(() => (<div style={{fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"}}>
        <HistoSuspense fallback={<div style={{display: "flex"}}><CircularProgress/></div>}>
            <ReactMarkdown transformLinkUri={(link) => transformLinkUri(link)}
                           components={renderers}
                           remarkPlugins={[remarkGfm]}>
                {myDescription}
            </ReactMarkdown>
        </HistoSuspense>
    </div>),[myDescription, mapDescriptor]);

    return memoizedCallback();
}

export default MarkdownDisplayField;
