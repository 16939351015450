import types from "./types";
import React from "react";

const DraftEditorDialog = ({openDialog,
  onCloseDialog,
  putItem,
    addLink,
    model,
  currentItem,
  currentClickObject,
  ChooseImageComponent,
  ChooseVideoComponent,
  ChooseMapComponent,
  ChoosePanoramaComponent,
  ChooseQuizComponent,
  ChooseInfoBlockComponent,
  ChooseLinkComponent,
  ChooseEventLinkComponent,
  ChooseStoryLinkComponent,
  ChooseFilterLinkComponent,
  ChooseMapLinkComponent,
  ChooseMarkdownComponent,}) => (
      <>
  {openDialog === types.IMAGETYPE && <ChooseImageComponent open={true}
                                                           onCancel={onCloseDialog}
                                                           onOk={(item) => {
                                                             putItem(item);
                                                           }}
                                                           item={currentItem}
                                                           clickObject={currentClickObject}
  />}
  {openDialog === types.VIDEOTYPE && <ChooseVideoComponent open={true}
                                                           onCancel={onCloseDialog}
                                                           onOK={(item) => {
                                                             putItem(item);
                                                           }}
                                                           item={currentItem}
                                                           clickObject={currentClickObject}
  />}
  {openDialog === types.MAPTYPE && <ChooseMapComponent open={true}
                                                       onCancel={onCloseDialog}
                                                       onOK={(item) => {
                                                         putItem(item);
                                                       }}
                                                       item={currentItem}
                                                       clickObject={currentClickObject}
  />
  }
  {openDialog === types.PANORAMATYPE && <ChoosePanoramaComponent open={true}
                                                                 onCancel={onCloseDialog}
                                                                 onOK={(item) => {
                                                                   putItem(
                                                                       item);
                                                                 }}
                                                                 item={currentItem}
                                                                 clickObject={currentClickObject}
  />}
  {openDialog === types.QUIZTYPE && ChooseQuizComponent && <ChooseQuizComponent open={true}
                                                                                onCancel={onCloseDialog}
                                                                                model={model}
                                                                                onOK={(item) => {
                                                                                  putItem(item);
                                                                                }}
                                                                                item={currentItem}
                                                                                clickObject={currentClickObject}
  />}
  {openDialog === types.INFOBLOCK && ChooseInfoBlockComponent && <ChooseInfoBlockComponent open={true}
                                                                                           onCancel={onCloseDialog}
                                                                                           model={model}
                                                                                           onOK={(item) => {
                                                                                             putItem(item);
                                                                                           }}
                                                                                           item={currentItem}
                                                                                           clickObject={currentClickObject}
  />}
  {openDialog === types.MARKDOWN && <ChooseMarkdownComponent open={true}
                                                             onCancel={onCloseDialog}
                                                             model={model}
                                                             onOK={(item) => {
                                                               putItem(item);
                                                             }}
                                                             item={currentItem}
                                                             clickObject={currentClickObject}
  />}
  {openDialog === types.LINKTYPE_BROWSER && <ChooseLinkComponent open={true}
                                                                 onCancel={onCloseDialog}
                                                                 onOK={(item) => {
                                                                   addLink(
                                                                       item);
                                                                 }}
                                                                 item={currentItem}
                                                                 clickObject={currentClickObject}
  />}
  {openDialog === types.LINKTYPE_EVENT && <ChooseEventLinkComponent
      open={true}
      onCancel={onCloseDialog}
      onOK={(item) => {
        addLink(item);
      }}
      item={currentItem}
      clickObject={currentClickObject}
  />}
  {openDialog === types.LINKTYPE_STORY && <ChooseStoryLinkComponent
      open={true}
      onCancel={onCloseDialog}
      onOK={(item) => {
        addLink(item);
      }}
      item={currentItem}
      clickObject={currentClickObject}
  />}
  {openDialog === types.LINKTYPE_FILTER && <ChooseFilterLinkComponent
      open={true}
      onCancel={onCloseDialog}
      onOK={(item) => {
        addLink(item);
      }}
      item={currentItem}
      clickObject={currentClickObject}
  />}
  {openDialog === types.LINKTYPE_MAP && <ChooseMapLinkComponent open={true}
                                                                onCancel={onCloseDialog}
                                                                onOK={(item) => {
                                                                  addLink(
                                                                      item);
                                                                }}
                                                                item={currentItem}
                                                                clickObject={currentClickObject}
  />}
    </>
  )

export default DraftEditorDialog;
