import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from "../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../ui-components/buttons/text/okbutton";
import i18n from "../../i18n/i18n";

const extractStreetViewCode = (streetViewURL) => {
    let url = streetViewURL;
    if(url) {
        const i = url.lastIndexOf("?pb=")+1;
        if(i>=1 && i<url.length) {
            url = url.substring(i+3);
            const i2 = url.indexOf("\"");
            if(i2 >=0) {
                url = url.substring(0, i2);
            }
        }
    }
    return url;
}

const getNewItem = (title, embedCode) => {
    let extractedUrl = extractStreetViewCode(embedCode);

    let item = {
        "type": "StreetView",
        "id": extractedUrl,
        "title": title
    }

    return item;
}



const ChooseStreetViewDialog = ({open, onOK, onCancel, item}) => {
    const [title, setTitle] = useState(item ? item.title : "");
    const [embedCode, setEmbedCode] = useState(item ? item.id : "");

    const itemUuid = item ? item.uuid : null;

    const cancelClicked = () => {
        setEmbedCode("");
        setTitle("");
        onCancel();
    }

    const okClicked = () => {
        onOK(getNewItem(title, embedCode, itemUuid));
        setEmbedCode("");
        setTitle("");
    }

    return <Dialog open={open}
                   onClose={onCancel}
    >
        <DialogTitle>StreetView</DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label={i18n("chooseStreetViewDialog.js854616579")}
                           value={title}
                           onChange={(evt) => setTitle(evt.target.value)}
                />
                <TextField label={i18n("chooseStreetViewDialog.js119725327")}
                           value={embedCode}
                           onChange={(evt) => setEmbedCode(evt.target.value)}
                />
                <br/>
                <br/>
                <a href="https://maps.google.de" target="_blank" rel="nofollow noopener noreferrer">Google Maps öffnen</a>
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={()=>cancelClicked()}/>
            <OKButton onClick={()=>okClicked()}/>
        </DialogActions>
    </Dialog>

}

export {extractStreetViewCode};
export default ChooseStreetViewDialog;
