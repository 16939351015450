import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import CancelButton from "../../../../ui-components/buttons/text/cancelbutton";
import OKButton from "../../../../ui-components/buttons/text/okbutton";
import i18n from "../../../../i18n/i18n";

const AddStoryDescriptorEditDialog = ({onClose, onOK, item}) => {
    const [barGroup, setBarGroup] = useState(item ? item.bargroup : "");
    const [storyid, setStoryID] = useState(item ? item.storyid : "");
    const [filter, setFilter] = useState(item ? item.filter : "");
    const [position, setPosition] = useState(item ? item.position : "");

    const buildItem = () => {
        let item = {
            "bargroup": barGroup,
            "storyid": storyid,
            "filter": filter,
            "position": position
        };
        return item;
    }

    return <Dialog
        maxWidth={false}
        open={true}
        onClose={onClose}
    >
        <DialogContent>
            <div style={{display: "flex", flexDirection: "column"}}>
                <TextField label={i18n("addstorydescriptoreditdialog.js502574362")}
                           value={barGroup}
                           onChange={(evt) => setBarGroup(evt.target.value)}
                />
                <TextField label={i18n("addstorydescriptoreditdialog.js124818429")}
                           value={storyid}
                           onChange={(evt) => setStoryID(evt.target.value)}
                />
                <TextField label={i18n("addstorydescriptoreditdialog.js117860561")}
                           value={filter}
                           onChange={(evt) => setFilter(evt.target.value)}
                />
                <TextField label={i18n("addstorydescriptoreditdialog.js777122119")}
                           value={position}
                           onChange={(evt) => setPosition(evt.target.value)}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <CancelButton onClick={onClose} />
            <OKButton onClick={() => onOK(buildItem())}/>
        </DialogActions>
    </Dialog>
}

export default AddStoryDescriptorEditDialog;
